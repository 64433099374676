(function () {
  const _COMPONENT = {
      name : 'ButtonSelectors',
      selectors : "[class='-oneX-btn-control']"
  };

  oneX.ButtonSelectors = {
    init : function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        if (_element.children('.-oneX-icon--controls--view, .-oneX-icon--controls--hide').length > 0) {
          _element.on('click', _self.togglePassword);
          
          _self.addInitialAriaLabels(_element);
          
          _element.data(_COMPONENT.name, _COMPONENT.name);
        }     
      }
    },

    unbindEvents : function(_element) {
      const _self = this;
      
      _element.off('click', _self.togglePassword);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    togglePassword: function (event) {
      event.preventDefault(); 
      
      const _self = oneX.ButtonSelectors,
        $button = oneX.$(this),
        $buttonIcon = $button.children('.-oneX-icon--controls--view, .-oneX-icon--controls--hide'),
        $textField = $button.siblings('.-oneX-textfield--control'),
        classView = '-oneX-icon--controls--view',
        classHide = '-oneX-icon--controls--hide';
      
      if ($buttonIcon.hasClass(classView)) {
        $buttonIcon.removeClass(classView).addClass(classHide);
        $textField.attr('type', 'text');
        
        _self.addHideAriaLabels($button, true);
      }
      else {
        $buttonIcon.removeClass(classHide).addClass(classView);
        $textField.attr('type','password');
        
        _self.addHideAriaLabels($button, false);
      }
    },
    
    addInitialAriaLabels: function (element) {
      const _self = oneX.ButtonSelectors,
        $buttonIconView = element.children('.-oneX-icon--controls--view'),    
        $buttonIconHide = element.children('.-oneX-icon--controls--hide');
      
     if (oneX.Util.locale.isSpanish()) {
       if ($buttonIconView.length > 0) {
         element.attr('aria-label', _self.esAriaLabelShow);
       }            
       if ($buttonIconHide.length > 0) {
         element.attr('aria-label', _self.esAriaLabelHide);
       }            
     }
     else {
       if ($buttonIconView.length > 0) {
         element.attr('aria-label', _self.enAriaLabelShow);
       }            
       if ($buttonIconHide.length > 0) {
         element.attr('aria-label', _self.enAriaLabelHide);
       }            
     }
    },
    
    addHideAriaLabels: function (button, isHide) {
      
      const _self = oneX.ButtonSelectors,
        lang = oneX.Util.locale.isSpanish();
      
      if (isHide) {
        if (lang === "es") {
          button.attr('aria-label', _self.esAriaLabelHide);
        }
        else {
          button.attr('aria-label', _self.enAriaLabelHide);
        }
      }
      else {
        if (lang === "es") {
          button.attr('aria-label', _self.esAriaLabelShow);
        }
        else {
          button.attr('aria-label', _self.enAriaLabelShow);
        }
      }
    },
    
    enAriaLabelHide: "hide password",
    esAriaLabelHide: "Ocultar contraseña",
    enAriaLabelShow: "Show password as plain text. Note: this will visually expose your password on the screen.",
    esAriaLabelShow: "Mostrar contraseña como texto sin formato. Nota: esto hará que su contraseña quede visualmente expuesta en la pantalla."
  };

  oneX.Config.queues(_COMPONENT);

})();