(function() {
  const _COMPONENT = {
    name : 'Stepper',
    selectors: '.-oneX-stepper--sm, .-oneX-stepper, .-oneX-stepper--lg'
  };

  oneX.Stepper = {
    init : function() {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
    
        _element.find('.-oneX-stepper-incr').on('click', _self.stepOnUp);
        _element.find('.-oneX-stepper-decr').on('click', _self.stepOnDown);
        _element.find("input").on('blur', _self.validateStepper);

        oneX.Stepper.setupStepper(_element.find("input"));

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;

      _element.find('.-oneX-stepper-incr').off('click', _self.stepOnUp);
      _element.find('.-oneX-stepper-decr').off('click', _self.stepOnDown);
      _element.find("input").off('blur', _self.validateStepper);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    setupStepper: function($stepperInput) {
      let stepperMin = $stepperInput.attr('min'),
        stepperMax = $stepperInput.attr('max'),
        $stepDown = $stepperInput.siblings(".-oneX-stepper-decr"),
        $stepUp = $stepperInput.siblings(".-oneX-stepper-incr"),
        $stepperInputId = $stepperInput.attr('id'),
        stepperLabel = $stepperInput.attr('aria-labelledby'),
        initialLabel = stepperLabel ? stepperLabel.substring(stepperLabel.indexOf(' ')): '';

      if (stepperMin === undefined) {
        stepperMin = 0;
      }
      else {
        stepperMin = parseInt(stepperMin);
        stepperMin = stepperMin < 0 ? 0 : stepperMin;
      }

      $stepperInput.attr('min', stepperMin);

      if (stepperMax === undefined) {
        stepperMax = 99;
      }
      else {
        stepperMax = parseInt(stepperMax);
        stepperMax = stepperMax > 99 ? 99 : stepperMax;
      }
      $stepperInput.attr('max', stepperMax);

      $stepperInput.attr({'type': "tel","aria-live":"polite","aria-labelledby": initialLabel +' '+ $stepperInputId,"pattern": "[0-9]*"});

      $stepUp.attr({'aria-controls': $stepperInputId, 'aria-label': 'Increase by 1'});
      $stepDown.attr({'aria-controls': $stepperInputId, 'aria-label': 'Decrease by 1'});

      $stepperInput.val(oneX.Stepper.checkVal($stepperInput, $stepperInput.val(), stepperMin, stepperMax));
      oneX.Stepper.setButtonsState($stepperInput);
    },

    setButtonsState: function($stepperInput) {
      const stepperMin = $stepperInput.attr('min'),
      stepperMax = $stepperInput.attr('max'),
      $stepDown = $stepperInput.siblings(".-oneX-stepper-decr"),
      $stepUp = $stepperInput.siblings(".-oneX-stepper-incr"),
      $stepperInputValue =  parseInt($stepperInput.val());

			if (stepperMin && $stepperInputValue <= stepperMin) {
				$stepDown.attr( 'disabled', true );
      }
      else {
        $stepDown.attr( 'disabled', false);
      }

			if (stepperMax && $stepperInputValue >= stepperMax) {
				$stepUp.attr( 'disabled', true );
      }
      else {
        $stepUp.attr( 'disabled', false);
      }
    },

    validateStepper: function(event) {
      const $stepperInput = oneX.$(event.currentTarget),
      stepperMax = parseInt($stepperInput.attr('max')),
      stepperMin = parseInt($stepperInput.attr('min'));
      
      oneX.Util.changeValue($stepperInput[0], oneX.Stepper.checkVal($stepperInput, $stepperInput.val(), stepperMin, stepperMax));
      
      oneX.Stepper.setButtonsState($stepperInput);

      oneX.Util.triggerEvent($stepperInput[0], 'change');
    },
    
    checkVal: function($stepperInput, stepperInputValue, stepperMin, stepperMax) {
      // if a value is entered that is not a number, reset counter back to the minimum value
      if (isNaN(stepperInputValue) || stepperInputValue === "") {
        stepperInputValue = 0;
        oneX.Util.changeValue($stepperInput[0], stepperInputValue);

      }
      else {
        stepperInputValue = parseInt(stepperInputValue);
      }
      // if a value is entered is less than the min value, then reset it to the min value
      if (stepperInputValue < stepperMin) {
        stepperInputValue = stepperMin;
        oneX.Util.changeValue($stepperInput[0], stepperInputValue);
      }
      // if a value is entered is more than the max value, then reset it to the max value
      if (stepperInputValue >  stepperMax) {
        stepperInputValue = stepperMax;
        oneX.Util.changeValue($stepperInput[0], stepperInputValue);
      }

      return stepperInputValue; 
    },

    stepOnUp: function(event) {
      const $stepUp = oneX.$(event.currentTarget),
            $stepperInput = $stepUp.siblings("input"),
            stepperMax = $stepperInput.attr('max');

      let stepperInputValue = parseInt($stepperInput.val());

      stepperInputValue += 1;
      stepperInputValue = Math.min(stepperInputValue, stepperMax);

      //if the user is tabbing, keep tabbing class
      if(event.pointerType === 'mouse' &&  event.buttons === 1) {
        oneX.$('body').addClass('-oneX-user-tabbing');
      } else if (event.pointerType === 'click') {
        oneX.$('body').removeClass('-oneX-user-tabbing');
      }

      oneX.$($stepperInput).attr({'aria-label': ' set to '+stepperInputValue});
      oneX.Util.changeValue($stepperInput[0], stepperInputValue);
      oneX.Stepper.setButtonsState($stepperInput);
      event.preventDefault();
      oneX.Util.triggerEvent($stepperInput[0], 'change');
    },
    
    stepOnDown: function(event) {
      const  $stepDown = oneX.$(event.currentTarget),
        $stepperInput = $stepDown.siblings("input"),
        stepperMin = $stepperInput.attr('min');

      let stepperInputValue = parseInt($stepperInput.val());

      stepperInputValue -= 1;
      stepperInputValue = Math.max(stepperInputValue, stepperMin);

      //if the user is tabbing, keep tabbing class
      if(event.pointerType === 'mouse' &&  event.buttons === 1) {
        oneX.$('body').addClass('-oneX-user-tabbing');
      } else if (event.pointerType === 'click') {
        oneX.$('body').removeClass('-oneX-user-tabbing');
      }

      oneX.$($stepperInput).attr({'aria-label': ' set to '+stepperInputValue});
      oneX.Util.changeValue($stepperInput[0], stepperInputValue);
      oneX.Stepper.setButtonsState($stepperInput);
      event.preventDefault();
      oneX.Util.triggerEvent($stepperInput[0], 'change');
    }
  };
  oneX.Config.queues(_COMPONENT);
})();
