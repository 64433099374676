(function () {
  const _COMPONENT = {
    name: 'Pagination',
    selectors: '[data-pagination]'
  };

  oneX.Pagination = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        
        const paginationData = {
          totalItems: parseInt(_element.data("number-of-items")),
          currentPage: _element.data("page-start") ? parseInt(_element.data("page-start")) : 1,
          itemsPerPage: parseInt(_element.data("current-items-per-page"))
        };

        _self.setupPagination(_element, paginationData);
        
        const $paginationAnchor = _element.find('a');
        
        _element.data('pagination') === 'incrementor' && $paginationAnchor.on('keydown click', _self.changeDisplayCount);
        _element.data('pagination') === 'indexer' && $paginationAnchor.on('keydown click', _self.incrementPage);
       
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;
    
      _element.find('a').off('click', _self.changeDisplayCount);
      _element.find('a').off('click', _self.incrementPage);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    setLangAndAriaLabels: function ($paginator) {
      const lang = oneX.Util.locale.isSpanish(),
      labels = {
        showLabel: lang ? 'Mostrando' : 'Showing',
        show10: lang ? 'Showing 10 result' : 'Showing 10 results',
        show25: lang ? 'Showing 25 result' : 'Showing 25 results',
        show50: lang ? 'Showing 50 result' : 'Showing 50 results',
        showAll: lang ? 'Mostrar todos' : 'Show All'
      }

      $paginator.find('[data-show="label"]').attr('aria-label', labels.showLabel).text(labels.showLabel);
      $paginator.find('[data-number-of-items-onpage=10]').attr('aria-label', labels.show10).text(10);
      $paginator.find('[data-number-of-items-onpage=25]').attr('aria-label', labels.show25).text(25);
      $paginator.find('[data-number-of-items-onpage=50]').attr('aria-label', labels.show50).text(50);
      $paginator.find('[data-show="all"]').attr('aria-label', labels.showAll).text(labels.showAll);
    },

    setupPagination: function ($paginator, paginationData) {
      const _self = this;
      _self.setLangAndAriaLabels($paginator);
      const $paginationAnchor = $paginator.find('a');
        
        $paginationAnchor.attr('role','link').addClass('-oneX-link--inline--variant');
        $paginator.find('a:not(.-oneX-pagination--active)').attr('tabindex', '0');
        $paginator.find('[data-chevron="next"]').attr('tabindex', '0');

      $paginator.data("current-page", paginationData.currentPage);
      $paginator.find('[data-page="start"]').text(1);
      $paginator.find('[data-page="end"]').text(Math.ceil(paginationData.totalItems / paginationData.itemsPerPage));
      $paginator.find('[data-page="end"]').removeClass('-oneX-pagination--active');

      _self.createOnexPagination($paginator, paginationData);
    },

    updatePagination: function(paginator) {
      const _self = this,
        $paginator = oneX.$(paginator),
        paginationData = {
          totalItems: parseInt(document.querySelector(paginator).dataset.numberOfItems),
          currentPage: 1,
          itemsPerPage: parseInt(document.querySelector(paginator).dataset.currentItemsPerPage)
        }; 

        $paginator.data("number-of-items", paginationData.totalItems);
        $paginator.data("current-page", paginationData.currentPage);
        $paginator.data("current-items-per-page", paginationData.itemsPerPage);

        _self.setupPagination($paginator, paginationData);
    },

    showStartEndEllipsis: function ($paginator, data, totalPages) {
      const $ellipsisLeft = $paginator.find('[data-ellipsis="left"]'),
        $ellipsisRight = $paginator.find('[data-ellipsis="right"]');

      if (totalPages > 7) {

        if (data.currentPage > 3) {
          $ellipsisLeft.removeClass('-oneX-hidden');
        } else {
          $ellipsisLeft.addClass('-oneX-hidden');
        }

        if (data.currentPage + 2 < totalPages) {
          $ellipsisRight.removeClass('-oneX-hidden');
        } else {
          $ellipsisRight.addClass('-oneX-hidden');
        }
      } else {
        $ellipsisLeft.addClass('-oneX-hidden');
        $ellipsisRight.addClass('-oneX-hidden');
      }
    },

    enableDisableChevrons: function ($paginator, data, totalPages) {
        const $chevronLeft = $paginator.find('[data-chevron="prev"]'),
        $chevronRight = $paginator.find('[data-chevron="next"]');

      if (totalPages > 1) {
        if (data.currentPage === 1) {
          $chevronLeft.removeClass('-oneX-pagination--prev--enabled').addClass('-oneX-pagination--prev--disabled').attr('aria-disabled', true).removeAttr('tabindex');
        } else {
          $chevronLeft.addClass('-oneX-pagination--prev--enabled').removeClass('-oneX-pagination--prev--disabled').removeAttr('aria-disabled').attr('tabindex',0);
        }
        if (data.currentPage === totalPages) {
          $chevronRight.removeClass('-oneX-pagination--next--enabled').addClass('-oneX-pagination--next--disabled').attr('aria-disabled', true).removeAttr('tabindex');
        } else {
          $chevronRight.addClass('-oneX-pagination--next--enabled').removeClass('-oneX-pagination--next--disabled').removeAttr('aria-disabled').attr('tabindex',0);
        }
      } else {
        $chevronLeft.addClass('-oneX-pagination--prev--disabled').attr('aria-disabled', true);
        $chevronRight.addClass('-oneX-pagination--next--disabled').attr('aria-disabled', true);
      }
    },

    showHideStartEndIndexis: function ($paginator, data, totalPages) {
      const $start = $paginator.find('[data-page="start"]'),
        $end = $paginator.find('[data-page="end"]');

      if (totalPages > 7) {
        if (data.currentPage > 2) {
          $start.parent().removeClass('-oneX-hidden');
        } else {
          $start.parent().addClass('-oneX-hidden');
        }

        if (data.currentPage < totalPages - 1) {
          $end.parent().removeClass('-oneX-hidden');
        } else {
          $end.parent().addClass('-oneX-hidden');
        }
      }
      if (totalPages == 1) {
        $end.parent().addClass('-oneX-hidden');
      }
    },

    setPaginationActive: function ($paginator, data, totalPages) {
      const $start = $paginator.find('[data-page="start"]'),
        $pageIndex = $paginator.find('[data-page^="index"]'),
        $index1 = $paginator.find('[data-page="index1"]'),
        $index2 = $paginator.find('[data-page="index2"]'),
        $index3 = $paginator.find('[data-page="index3"]'),
        $end = $paginator.find('[data-page="end"]');

      $paginator.find('[data-page^="small_list"]').parent().addClass('-oneX-hidden');
      $paginator.find('.-oneX-pagination-pages [aria-current="true"]').removeAttr('aria-current aria-live aria-disabled aria-label').attr('tabindex',0);

      if (totalPages > 7) {
        $pageIndex.parent().removeClass('-oneX-hidden');
        $pageIndex.removeClass('-oneX-pagination--active');

        if (data.currentPage === 1) {
          $index1.text(data.currentPage).addClass('-oneX-pagination--active');
          $index2.text(data.currentPage + 1);
          $index3.text(data.currentPage + 2);
        } else if (data.currentPage > 1 && data.currentPage < totalPages) {
          $index1.text(data.currentPage - 1);
          $index2.text(data.currentPage).addClass('-oneX-pagination--active');
          $index3.text(data.currentPage + 1);
        } else {
          $index1.text(data.currentPage - 2);
          $index2.text(data.currentPage - 1);
          $index3.text(data.currentPage).addClass('-oneX-pagination--active');
        }
      } else {
        const $smallList = $paginator.find('[data-page^="small_list"]');

        $start.parent().removeClass('-oneX-hidden -oneX-pagination--active');
        $pageIndex.parent().addClass('-oneX-hidden');
        $end.parent().removeClass('-oneX-hidden -oneX-pagination--active');

        for (let i = 0; i < $smallList.length; i++) {
          $smallList.removeClass('-oneX-pagination--active');
          if (i < totalPages - 2) {
            oneX.$($smallList[i]).text(i + 2).parent().removeClass('-oneX-hidden');
          }
        }

        if (data.currentPage === 1) {
          $start.addClass('-oneX-pagination--active');
        } else if (data.currentPage > 1 && data.currentPage < totalPages) {
          oneX.$($smallList[data.currentPage - 2]).addClass('-oneX-pagination--active');
        } else {
          $end.addClass('-oneX-pagination--active');
        }
      }
      const $current = $paginator.find('.-oneX-pagination-pages .-oneX-pagination--active')
      
      $current.attr({'aria-current': true, 'aria-live': 'polite', 'aria-disabled': true, 'aria-label': 'current page ' + $current.html() + ' unavailable'}).removeAttr('tabindex');
    },

    createOnexPagination: function ($paginator, data) {
      const rangeLangText = oneX.Util.locale.isSpanish() ? 'de' : 'of',
        rangeContextText = oneX.Util.locale.isSpanish() ? 'Mostrando' : 'Showing',
        totalPages = Math.ceil(data.totalItems / data.itemsPerPage);

      this.showStartEndEllipsis($paginator, data, totalPages);
      this.setPaginationActive($paginator, data, totalPages);
      this.enableDisableChevrons($paginator, data, totalPages);
      this.showHideStartEndIndexis($paginator, data, totalPages);

      const $range = $paginator.find('[data-page="range"]'),
        itemsIncrementor = data.itemsPerPage * (data.currentPage - 1),
        displayedItems = (data.itemsPerPage + itemsIncrementor > data.totalItems) ? data.totalItems : data.itemsPerPage + itemsIncrementor;



      if (data.prevCurrentPage) {
        $range.text(`${rangeContextText} ${1 + itemsIncrementor}-${displayedItems} ${rangeLangText} ${data.totalItems}`);

        $paginator.attr('data-displayed-from', 1 + itemsIncrementor);
      } else {
        const start = (displayedItems - data.itemsPerPage < 1) ? '1' : displayedItems - data.itemsPerPage + 1;

        $range.text(`${rangeContextText} ${start}-${displayedItems} ${rangeLangText} ${data.totalItems}`);

        $paginator.attr('data-displayed-from',start);
      }     

      $paginator.attr('data-displayed-to', displayedItems);
    },

    incrementPage: function (event) { //page numbers 
      const _self = oneX.Pagination,
        $pageNavigator = oneX.$(event.target),
        $paginator = $pageNavigator.closest('[data-pagination]'),
        paginationData = {
          totalItems: parseInt($paginator.data("number-of-items")),
          currentPage: parseInt($paginator.data("current-page")),
          itemsPerPage: parseInt($paginator.data("current-items-per-page"))
        }

        if (event.type === 'click' || (event.which === 32 || event.which === 13)) {
          if (!$pageNavigator.hasClass('-oneX-pagination--prev--disabled') && !$pageNavigator.hasClass('-oneX-pagination--next--disabled') ) {
            paginationData.prevCurrentPage = paginationData.currentPage;
            paginationData.currentPage = _self.returnCurrentPage($pageNavigator, paginationData);
            $paginator.data("current-page", paginationData.currentPage).find('a').removeClass('-oneX-pagination--active');
            _self.createOnexPagination($paginator, paginationData);
          }
        }
    },

    returnCurrentPage: function ($navigator, data) {
      const totalPages = Math.ceil(data.totalItems / data.itemsPerPage);

      let currentPage;
      if ($navigator.hasClass('-oneX-pagination--prev--enabled')) {
        if (data.currentPage != 1) {
          currentPage = data.currentPage - 1;
        }
      } else if ($navigator.hasClass('-oneX-pagination--next--enabled')) {
        if (data.currentPage < totalPages) {
          currentPage = data.currentPage + 1;
        }
      } else {
        currentPage = parseInt($navigator.text());
      }

      return currentPage;
    },
    
    changeDisplayCount: function (event) { //filter 
      const _self = oneX.Pagination;
      const $pageIncrementor = oneX.$(event.target),
        $perPageContainer = $pageIncrementor.closest('.-oneX-pagination-page-increments'),
        paginationId = $perPageContainer.attr('pagination-id'),
        $paginator = oneX.$('#' + paginationId),
        paginationData = {
          totalItems: parseInt($paginator.data("number-of-items")),
          currentPage: 1,
          itemsPerPage: parseInt($pageIncrementor.data('number-of-items-onpage'))
        }
      
        if (event.type === 'click' || (event.which === 32 || event.which === 13)) {

          $perPageContainer.find('a').removeClass('-oneX-pagination--active').removeAttr('aria-current').removeAttr('aria-live').removeAttr('aria-disabled').attr('tabindex','0');
          $pageIncrementor.addClass('-oneX-pagination--active').attr('aria-current', 'true').attr('aria-live','polite').attr('aria-disabled','true').removeAttr('tabindex');
          $perPageContainer.data('current-items-per-page', paginationData.itemsPerPage);
          $perPageContainer.data("current-page", paginationData.currentPage);
          $paginator.data('current-items-per-page', paginationData.itemsPerPage);

          _self.setupPagination($paginator, paginationData);
        }
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
