(function () {
  const _COMPONENT = {
    name: 'InpageLogin',
    selectors: '[data-type^="1x-inpage-login"]'
  };

  oneX.InpageLogin = {
    init: function (callback) {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors), callback);
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element, callback) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        _self.createCookie();

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element, callback);
        }
        else {
          
          oneX.$('.-oneX-inpage-login-form').on('submit', _self.loginClick);

          if (oneX.Util.locale.isSpanish()) {
            oneX.$('.-oneX-inpage-login-container').addClass('-oneX-inpage-login-es');
          }
        }
        oneX.Textfield.init();

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      oneX.$('.-oneX-inpage-login-form').off('submit', _self.loginClick);
    
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    loginClick: function (event) {
      $(event.target).find('#oneX-inpage-loginBtn').attr('disabled', 'disabled');
    },

    renderHTML: function (_element, callback) {
      const loginDestinationUrl = _element.data("login-destination-url"),
        cancelUrl = _element.data("cancel-url"),
        mobileOnly = _element.data("mobile-only"),
        locale = (oneX.Util.locale.isSpanish()) ? "/es_US" : "/en_US",
        staticLocale = oneX.Util.environment.getStaticHostName().replace(/(:?)([\/\\]+)/g, function (_, d, s) { return d ? d + s : '/' }) + locale,
        port = oneX.Util.location.port(),
        isLocalTestHarness = port === "4200" && /onex-testharness/ig.test(oneX.Util.location.pathname()), 
        segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src');


      let localPath = "http://localhost:3000";

      if (segmentSection !== undefined && segmentSection !== null) {
        const colon3000 = segmentSection.indexOf(':3000');
        if (colon3000 > -1) {
          localPath = segmentSection.substr(0, colon3000 + 5);
        }
      }

      const inpagelogin = (isLocalTestHarness) ? localPath + "/html/component" + locale : staticLocale + "/dxl-1x/" + oneX.Util.location.getVersion() + "/renders/component";

      _element.load(inpagelogin + "/inpage-login.html", function (response, status) {
        if (status === "success") {
          if (isLocalTestHarness) {
            _element.html(
              _element.html().replace(/{{domain_root}}/g, staticLocale).replace(/{{www_root}}/g, oneX.Util.location.hostname())
            );
          }

          let action = _element.find('.-oneX-inpage-login-form').attr('action').concat('?');

          if(loginDestinationUrl) {
            action += `goto=${loginDestinationUrl}&`;
          }

          if(cancelUrl) {
            action += `cancel=${cancelUrl}`;
          }

          _element.find('.-oneX-inpage-login-form').attr('action', action);

          if (mobileOnly) {
            _element.find('.-oneX-inpage-login-container').removeClass('-oneX-inpage-login-responsive');
          }

          _element.children(':first').unwrap();
          
          oneX.InpageLogin.init();
          oneX.ButtonSelectors.init(); // Password control icon will not function if I don't call this
          oneX.FloatingLabel.init();
          if (callback && typeof (callback) === "function") {
            callback();
          }

          // wait a couple seconds for everthing to settle before attempting to set the device print
          setTimeout(function () {
            if (typeof(add_deviceprint) === "function") {
              oneX.$('[name="IDToken3"]').val(add_deviceprint());
            }
          }, 2000);

        } else {
          console.debug("Error with loading inpage-login " + response);
        }
      });
    },

    createCookieIfChecked: function () {
      const userid = oneX.$('#oneX-inpage-username').val();

      if (userid.length > 0 && oneX.$('#oneX-inpage-RememberMe').is(':checked')) {
        oneX.Util.cookie.set("userid", userid, (365 * 24 * 60 * 60));
      }
      else {
        oneX.Util.cookie.destroy("userid");
      }
    },

    createCookie: function () {
      const cookieUserId = oneX.Util.cookie.get("userid");

      if (cookieUserId !== null && cookieUserId.length > 0) {
        oneX.$('#oneX-inpage-username').val(cookieUserId).change();
        oneX.$('#oneX-inpage-RememberMe').prop('checked', true);
      }
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
