(function () {
  const _COMPONENT = {
    name: 'Search',
    selectors: '#util-sf-search'
  };

  oneX.Search = {

    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _element.on('keypress', _self.keypressHandler);
        oneX.$('.-oneX-util-search-button').on('click', _self.clickHandler);
        oneX.$('.-oneX-util-search-fields').on('submit', _self.submitHandler);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _element.off('keypress', _self.keypressHandler);
      oneX.$('.-oneX-util-search-button').off('click', _self.clickHandler);
      oneX.$('.-oneX-util-search-fields').off('submit', _self.submitHandler);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    keypressHandler: function (event) {
      if (event.which === 13) {
        oneX.Search.performSearch(oneX.$('#util-sf-search'));
      }
    },

    clickHandler: function (event) {
      oneX.Search.performSearch(oneX.$('#util-sf-search'));
    },

    submitHandler: function (event) {
      event.preventDefault();
      event.stopPropagation();
      oneX.Search.performSearch(oneX.$('#util-sf-search'));
    },

    performSearch: function (searchBox) {
      const searchValue = searchBox.val();

      if (searchBox.val().length > 0) {
        oneX.Search.submit(encodeURIComponent(searchValue));
      }

      else {
        if (oneX.Util.locale.isSpanish()) {
          oneX.TextfieldErrors.addError(searchBox, "Casilla vacía. Contesta para continuar.");
        }
        else {
          oneX.TextfieldErrors.addError(searchBox,"Empty field. Answer to continue.");
        }
        searchBox.focus();
      }
    },

    submit: function (value) {
      window.location = oneX.Search.generateSearchURL(value);
    },

    getfilter: function (host) {
      let queryCode = '';

      if (oneX.Util.location.pathname().indexOf('/banking') > -1) {
        queryCode = 'statefarmbank';
      }
      else if (oneX.Search.getLanguage() === 'es' || location.search.match('/?es[_-]US') !== null) {
        queryCode = 'statefarmspanish';
      }
      else if (oneX.Util.location.pathname().indexOf('/retirees') > -1) {
        queryCode = 'retirees';
      }
      else {
        queryCode = 'statefarmcom';
      }

      return queryCode + 'sppublished';
    },

    generateSearchURL: function (value) {
      const location = oneX.Util.environment.location();
      const href = oneX.Util.location.href();
      let prodUrl = `https://search.c1.statefarm/search/?k=${value}`;
      let testUrl = `https://search.test.c1.statefarm/search/?k=${value}`;

      if (href.includes("b2b.statefarm.com")) {
        prodUrl = `https://search.c1.statefarm/search/?b=${value}`;
        testUrl = `https://search.test.c1.statefarm/search/?b=${value}`;
      }

      if (oneX.Util.locale.isSpanish()) {
        prodUrl = `https://search.c1.statefarm/search/?e=${value}&locale=es_US`;
        testUrl = `https://search.test.c1.statefarm/search/?e=${value}&locale=es_US`;
      }
      
      if (oneX.$('nav.-oneX-header-auth').length > 0) {
        // add auth parameter
        prodUrl += "&option=1";
        testUrl += "&option=1";
      }

      return (location === 'production') ? prodUrl : testUrl;
       
    },

    getLanguage: function () {
      return (oneX.Util.location.hostname().match('.*(es[.-]).*') !== null) ? 'es' : 'en';
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
