(function () {
    const _COMPONENT = {
      name: 'LoadMore',
      selectors: ".-oneX-load-more"
    };
  
    oneX.LoadMore = {
      init: function () {
        const _self = this;

        oneX.$(_COMPONENT.selectors).each(function () {
          _self.bindEvents(oneX.$(this));
        });
  
        return this;
      },
  
      addElement: function (_element) {
        this.bindEvents(_element);
      },
  
      removeElement: function (_element) {
        this.unbindEvents(_element);
      },
  
      bindEvents: function (_element) {
        const _self = this;
  
        if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
          _self.loadMoreText(_element);
          _element.data(_COMPONENT.name, _COMPONENT.name);
        }
      },
  
      unbindEvents: function (_element) {
  
        _element.removeData(_COMPONENT.name, _COMPONENT.name);
      },
  
      loadMoreText: function (_element) {
        const lang = oneX.Util.locale.isSpanish(),
        langText = lang ? 'Cargar Más...' : 'Load More...';
       
        _element.text(langText);
      }
    };
  
    oneX.Config.queues(_COMPONENT);
  })();
  