// file needs to load first

// eslint-disable-next-line no-redeclare
let oneX = { 
  addElement: function(_element) {
    this.handleElement(_element, 'addElement');
  },

  removeElement: function(_element) {
    this.handleElement(_element, 'removeElement');
  },

  handleElement: function(_element, action) {
    let oneXObject = {};
    let $element = oneX.$(_element);

    for(oneXObject of oneX.ELEMENT_QUEUE) {
      $element.find(oneXObject.selectors).each(function() {
        oneX[oneXObject.name][action](oneX.$(this));
      });
    }
  }
};

if(oneX.INIT_QUEUE === undefined) {
  oneX.INIT_QUEUE = [];
}

if(oneX.ELEMENT_QUEUE === undefined) {
  oneX.ELEMENT_QUEUE = [];
}

(function () {
  oneX.Config = {
    queues: function(component) {
      oneX.ELEMENT_QUEUE.push(component);
      oneX.INIT_QUEUE.push(oneX[component.name]);      
    }
  };
})();

(function() {
  oneX.Util = {}
})();
