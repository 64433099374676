(function () {
  const _COMPONENT = {
    name: "Rating",
    selectors: ".-oneX-rating-interactive-flex , .-oneX-rating-interactive",
  };

  oneX.Rating = {
    init: function () {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      _self._updateHTML(_element);

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $stars = oneX.$(_element).find("input");
        const $ratings = oneX.$(_element);

        $stars.on("click", _self._updateActiveStarRef);
        $ratings.on("focusin", _self._enableReadingAria);
        $ratings.on("focusout", _self._disableReadingAria);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;
      const $stars = oneX.$(_element).find("input");
      const $ratings = oneX.$(_element);

      $stars.off("click", _self._updateActiveStarRef);
      $ratings.off("focusin", _self._enableReadingAria);
      $ratings.off("focusout", _self._disableReadingAria);
    },

    _updateActiveStarRef: function (e) {
      const $ratingGroup = oneX
        .$(e.target)
        .closest(
          ".-oneX-rating-interactive-flex , .-oneX-rating-interactive"
        )[0];
      const $selectedStars = e.currentTarget;
      const starID = $selectedStars.id;

      $selectedStars.setAttribute("aria-checked", "true");
      $selectedStars.setAttribute("aria-hidden", "false");
      $ratingGroup.setAttribute("aria-activedescendant", starID);
    },

    _enableReadingAria: function (e) {
      const $ratingGroup = oneX.$(e.currentTarget);
      const $stars = $ratingGroup.find("input, label");

      $stars.map((index, star) => {
        star.removeAttribute("aria-hidden");
      });
    },

    _disableReadingAria: function (e) {
      const $ratingGroup = oneX.$(e.currentTarget);
      const activeStar = $ratingGroup.attr("aria-activedescendant");
      const $stars = $ratingGroup.find("input, label");
      $stars.map((index, star) => {
        const label = oneX.$(star).attr("for");

        if (star.id !== activeStar && label !== activeStar) {
          star.setAttribute("aria-hidden", "true");
        }
      });
    },

    _updateHTML: function (_element) {
      const firstStar = _element.find("input[value='1']");
      const starID = firstStar.attr("id");
      const $stars = _element.find("input, label");
      $stars.map((index, star) => {
        const label = oneX.$(star).attr("for");

        if (star.id !== starID && label !== starID) {
          star.setAttribute("aria-hidden", "true");
        }
      });
      _element.attr("aria-activedescendant", starID);
      _element.attr("role", "radio-group");
    },
  };

  oneX.Config.queues(_COMPONENT);
})();
