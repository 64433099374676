(function() {
  const _COMPONENT = {
    name : 'Textfield',
    selectors : 'input.-oneX-textfield__input, input.-oneX-textfield--floating-input, input.-oneX-textfield--conversational-input'
  };

  oneX.Textfield = {
    init : function() {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.debouncedCalculateFormatWrapperOnResize = oneX.Util.throttle.throttle(_self.calculateFormatWrapperOnResize, 250, true, _element);

        oneX.$(window).on("resize", _self.debouncedCalculateFormatWrapperOnResize); 

        _element.on('focusin', _self.focusIn);
        _element.on('focusout', _self.focusOut);  

        _self.calculateFormatWrapperOnResize(_element);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;

      oneX.$(window).off("resize", _self.debouncedCalculateFormatWrapperOnResize); 
      
      _element.off('focusin', _self.focusIn);
      _element.off('focusout', _self.focusOut);   

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    calculateFormatWrapperOnResize: function($input) {

      const inputWidth = $input.css('width'),
        $formatWrapper = $input.prev('.-oneX-formatWrapper');

      ($formatWrapper.length) && $formatWrapper.css('width', inputWidth);
    },

    focusIn : function(event) {
      const $input = oneX.$(event.target),
        $formatWrapper = $input.prev('.-oneX-formatWrapper');

      if (!$input.attr('readonly')) {
        ($formatWrapper.length) ? $formatWrapper.addClass('-oneX-textfield__active-state') : $input.addClass('-oneX-textfield__active-state');
      }
    },

    focusOut : function(event) {
      const $input = oneX.$(event.target),
        $formatWrapper = $input.prev('.-oneX-formatWrapper');

      if (!$input.attr('readonly')) {
        ($formatWrapper.length) ? $formatWrapper.removeClass('-oneX-textfield__active-state') : $input.removeClass('-oneX-textfield__active-state');
      }
    },

    debouncedCalculateFormatWrapperOnResize: null
  };

  oneX.Config.queues(_COMPONENT);
})();
