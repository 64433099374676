(function() {
  const _COMPONENT = {
    name : 'Help',
    selectors : '.-oneX-contextual-help-container'
  };

  oneX.Help = {
    init : function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.debouncedCalculateMarginTopOnResize = oneX.Util.debounce.debounce(_self.calculateMarginTopOnResize, 250);

        if (!oneX.$("body").hasClass("-oneX-contextual-resize-bound")){
          oneX.$(window).on("resize", _self.debouncedCalculateMarginTopOnResize);
          oneX.$("body").addClass("-oneX-contextual-resize-bound");
        }

        // _self.marginTop(_element.find("[class*='-oneX-contextual-help--text']"));

        _element.find('.-oneX-contextual-help').on('click', _self.closeOtherOpenHelpContainers);
        const $closeButton = _element.find('.-oneX-help-container__close button, [class^="-oneX-contextual-help--text"] button.-oneX-close-X-button');
        $closeButton.on('click', _self.hideContainerFocusHelpButton);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;

      _element.find('.-oneX-contextual-help').off('click', _self.closeOtherOpenHelpContainers);
      oneX.$(window).off('resize', _self.debouncedCalculateMarginTopOnResize);
      const $closeButton =  _element.find('.-oneX-help-container__close button, [class^="-oneX-contextual-help--text"] button.-oneX-close-X-button');
      $closeButton.off('click', _self.hideContainerFocusHelpButton);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    closeOtherOpenHelpContainers : function() {
      const $helpButton = oneX.$(this);

      if($helpButton.is("[data-oneX-opensDrawer]")) {
        oneX.Drawer.showDrawer($helpButton.attr('id'));
      }
      else {
        const $helpContainerClicked = $helpButton.closest(".-oneX-contextual-help-container");

        if (!$helpButton.attr("aria-expanded") || $helpButton.attr("aria-expanded") === "false") {
          oneX.Help.showHelp($helpContainerClicked);
        }
        else {
          oneX.Help.hideHelp($helpContainerClicked);
        }
      }
    },

    hideContainerFocusHelpButton : function() {
      const $parent = oneX.$(this).closest('.-oneX-contextual-help-container');

      oneX.Help.hideHelp($parent);
      $parent.find(".-oneX-contextual-help").focus();
    },

    calculateMarginTopOnResize: function() {
      oneX.$(".-oneX-contextual-help-container").find("[class*='-oneX-contextual-help--text']").parent().each(function() {
        oneX.Help.marginTop(oneX.$(this).find("[class*='-oneX-contextual-help--text']"));
        if(oneX.$(this).is(":visible")){
          oneX.Help.adjustPositionForViewport(oneX.$(this));
        }
      });
    },

    showHelp: function(helpContainer) {
      oneX.Help.hideAllOpenedHelpContainers();

      const $helpText = helpContainer.find("[class*='-oneX-contextual-help--text'] ");

      helpContainer.find("button.-oneX-contextual-help").attr("aria-expanded", true).focus();
      $helpText.show();
      oneX.Help.marginTop($helpText);

      oneX.Help.adjustPositionForViewport(helpContainer);

      oneX.$('body').on('click touchend', oneX.Help.clickToCloseHelp);
      oneX.$('body').on('touchstart touchcancel', oneX.Help.setDraggingOff);
      oneX.$('body').on('touchmove', oneX.Help.setDraggingOn);
      oneX.Help.bodyKibo = new Kibo(document.body);
      oneX.Help.bodyKibo.down('tab', oneX.Help.clickToCloseHelp);

    },

    hideHelp: function(helpContainer) {
      const $helpText = helpContainer.find("[class*='-oneX-contextual-help--text']")
      $helpText.hide();
      helpContainer.find("button.-oneX-contextual-help").attr("aria-expanded", false);
      helpContainer.attr("class", helpContainer.data("oneX-oldClassList"));
      oneX.$('body').off('click touchend', oneX.Help.clickToCloseHelp);
      oneX.$('body').off('touchstart touchcancel', oneX.Help.setDraggingOff);
      oneX.$('body').off('touchmove', oneX.Help.setDraggingOn);
      oneX.Help.bodyKibo && oneX.Help.bodyKibo.unregisterKeys('down', oneX.Help.clickToCloseHelp);
      $helpText.attr("class", $helpText.data("oneX-oldClassList"));
    },

    hideAllOpenedHelpContainers: function () {
      oneX.$(".-oneX-contextual-help-container button.-oneX-contextual-help[aria-expanded='true']").each(function() {
        const $openedHelp = oneX.$(this).closest(".-oneX-contextual-help-container");
        oneX.Help.hideHelp($openedHelp);
      });
    },

    clickToCloseHelp: function(event) {
      // This gets called too many times, so we need to find the few cases where it should fire
      if (oneX.Help.dragging) {
        // oneX.Help.verboseLogging && console.log('no-action-moving:');
        return;
      }

      if (event.originalEvent && event.originalEvent.key === "Shift") {
        //oneX.Help.verboseLogging && console.log('no-action-shift-key-only:');
        return;
      }

      const $openedHelp = oneX.$(".-oneX-contextual-help-container button.-oneX-contextual-help[aria-expanded='true']");
      const $floatLab = $openedHelp.closest('.-oneX-fieldset').find('.-oneX-textfield__floatingLabel');
      const shiftTab = event.originalEvent && event.originalEvent.code === 'Tab' && event.originalEvent.shiftKey === true ? true : false;
      const tab = event.originalEvent && event.originalEvent.code === 'Tab' && event.originalEvent.shiftKey === true ? false : true;
      const $target = oneX.$(event.target);
      const $targetNext = $target.next();
      const $targetPrevHelp = $target.siblings().find('.-oneX-contextual-help');
      const $targetHelp = $target.closest('.-oneX-contextual-help-container').find('button.-oneX-contextual-help');

      // oneX.Help.verboseLogging && console.log("event:", event);
      // oneX.Help.verboseLogging && console.log("shiftTab:", shiftTab);
      // oneX.Help.verboseLogging && console.log("event.target:", $target);
      // oneX.Help.verboseLogging && console.log("$target next:", $targetNext);
      // oneX.Help.verboseLogging && console.log("$target prev help:", $targetPrevHelp, $targetPrevHelp.length);

      // don't close if we just opened it
      if ($target.hasClass('-oneX-icon--controls--question') || $target.hasClass('-oneX-contextual-help')) {
        if (!$target.parent().hasClass('-oneX-standalone-help')) {
          //oneX.Help.verboseLogging && console.log("nc-just opened-and not standalone");
          return;
        }
        else {
          if (tab) {
            // let it tab into help
            // oneX.Help.verboseLogging && console.log("nc--tabbing into standalone help");
            return;
          }
         // else {
            // oneX.Help.verboseLogging && console.log("standalone--let it continue");
         // }
        }
      }

      // don't close if they clicked the floating label
      if ($floatLab && $target.is($floatLab)) {
        //oneX.Help.verboseLogging && console.log("nc-float label clicked");
        return;
      }

      // don't close if they clicked the contextual help
      if ($targetNext && $targetNext.hasClass('-oneX-close-X-button')) {
        //oneX.Help.verboseLogging && console.log("nc-contextual help clicked");
        return;
      }

      // if any element inside the message is the target of any event, then don't close
      if ($targetHelp && $targetHelp.is($openedHelp)) {
        if ($target.hasClass('-oneX-close-X-button')) {
          // close button
          if (shiftTab) {
            // don't close if shiftTab is being pressed on close button
            //oneX.Help.verboseLogging && console.log("nc-close btn in opened help shiftTab'd");
            return;
          }
        }
        else {
          if (!$target.parent().hasClass('-oneX-standalone-help')) {
            // anything besides the close button or a standalone help, don't close
            // oneX.Help.verboseLogging && console.log("nc-non-close-btn in opened help clicked");
            return;
          }
          // this code is supposedly added for defect 1395, cannot figure out how to hit it for a test, so removing
          // if (tab) {
          //   // let it tab into help
          //   oneX.Help.verboseLogging && console.log("nc--cb-tabbing into standalone help");
          //   return;
          // }
          // else {
            // oneX.Help.verboseLogging && console.log("standalone-cb--let it continue");
          // }
        }
      }

      // don't close if they clicked the input of a standalone help
      // !! This is firing if I click the top of the page after displaying SSN contextual help
      if ($targetPrevHelp && $targetPrevHelp.length === 1 && $targetPrevHelp.is($openedHelp) && !shiftTab) {
        //oneX.Help.verboseLogging && console.log("nc-input of std aln help clicked");
        return;
      }

      // don't close if there aren't any open help containers
      if ($openedHelp.length === 0) {
        //oneX.Help.verboseLogging && console.log("nc-no help cont opened");
        return;
      }

      // don't close if they tab'd while on body
      if (event.type === 'keydown' && tab && oneX.$(event.target).is(oneX.$('body'))) {
        //oneX.Help.verboseLogging && console.log("nc-tab key", event.target, event.type, tab, oneX.$(document.activeElement));
        return;
      }

      // fall through will close it
      setTimeout(() => {
        //oneX.Help.verboseLogging &&  console.log('closing ...', $openedHelp);
        oneX.Help.hideAllOpenedHelpContainers();
      }, 100);
    },

    marginTop: function(helpMsg) {
      if (!helpMsg.parent().hasClass("-oneX-help-bottom")){
        helpMsg.css("margin-top",  helpMsg.innerHeight() * -1 - 4 + 'px');
      }
    },

    adjustPositionForViewport: function($helpContainer) {
      const $helpText = $helpContainer.find("[class*='-oneX-contextual-help--text']"),
        $header = oneX.$('#oneX-header');

      $helpContainer.data("oneX-oldClassList", $helpContainer.attr("class"));
      $helpText.data("oneX-oldClassList", $helpText.attr("class"));
      const rect = $helpText.get(0).getBoundingClientRect();
      if (rect.top < 0 ){
        if (rect.top > -($helpText.outerHeight())) {
          // fix defect 1336 - if the contextual help is in a table, the rect.top can be like -2000
          // so skip the scroll adjustment if it is more than the contextual help height and the height of the header
          oneX.$(window).scrollTop(window.pageYOffset + rect.top - 3);
        }

      }
      if (rect.bottom > (window.innerHeight || document.documentElement.clientHeight)){
        // bottom of the rect is off the viewport, so scroll down by the difference + 5px padding
        oneX.$(window).scrollTop(window.pageYOffset + rect.bottom - (window.innerHeight || document.documentElement.clientHeight) + 5);
      }
      if (rect.right > (window.innerWidth || document.documentElement.clientWidth)){
        $helpText.addClass("-oneX-contextual-help--text-right").removeClass("-oneX-contextual-help--text-left").removeClass("-oneX-contextual-help--text-center")
      }
      if (rect.left < 0){
        $helpText.addClass("-oneX-contextual-help--text-left").removeClass("-oneX-contextual-help--text-right").removeClass("-oneX-contextual-help--text-center")
      }

    },

    setDraggingOn: function() {

      oneX.Help.dragging = true;
    },

    setDraggingOff: function() {

      oneX.Help.dragging = false;
    },

    // verboseLogging: true,
    debouncedCalculateMarginTopOnResize: null,
    bodyKibo: undefined,
    dragging: false
  };


  oneX.Config.queues(_COMPONENT);
})();
