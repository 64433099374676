(function () {
  const _COMPONENT = {
    name: 'Modal',
    selectors: '.-oneX-modal__container'
  };

  oneX.Modal = {
    init: function () {
      const _self = this;
      this.defineLanguageModals();

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _element.find('.-oneX-modal-content > button.-oneX-close-X-button').on("click", _self.closeButtonClicked);
        _element.find('.-oneX-modal-content > button.-oneX-close-X-button').on("keydown", _self.closeButtonSpacebar);
        _element.find(oneX.Util.componentWithScrim.tabbable).on("keydown", _self.tabController);
        _element.find('.-oneX-modal-heading').on("keydown", _self.tabController);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _element.find('.-oneX-modal-content > button.-oneX-close-X-button').off("click", _self.closeButtonClicked);
      _element.find('.-oneX-modal-content > button.-oneX-close-X-button').off("keydown", _self.closeButtonSpacebar);
      _element.find(oneX.Util.componentWithScrim.tabbable).off("keydown", _self.tabController);
      _element.find('.-oneX-modal-heading').off("keydown", _self.tabController);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);

    },

    setFocus: function (event, $first, $second, $third) {
      if ($first.length > 0) {
        event.preventDefault();
        $first.focus();
      } else if ($second.length > 0) {
        event.preventDefault();
        $second.focus();
      } else if ($third.length > 0) {
        event.preventDefault();
        $third.focus();
      }
    },

    tabController: function (event) {
      // handle all tab events

      if (event.which === 9) {
        const $item = oneX.$(event.currentTarget),
          $modalPrimaryButton = $item.closest('.-oneX-modal__container').find(".-oneX-modal-footer .-oneX-row .-oneX-modal-primary__div [class*='-oneX-btn-primary']"),
          $modalSecondaryButton = $item.closest('.-oneX-modal__container').find(".-oneX-modal-footer .-oneX-row .-oneX-modal-secondary__div [class*='-oneX-btn-secondary']"),
          $closeBtn = $item.closest('.-oneX-modal__container').find('.-oneX-close-X-button'),
          $tabbable = $item.closest('.-oneX-modal__container').find(oneX.Util.componentWithScrim.tabbable).filter(":visible:not('.-oneX-btn-primary'):not('.-oneX-btn-secondary'):not('.-oneX-close-X-button')"),
          $tabbableFirst = oneX.$($tabbable[0]),
          $tabbableLast = oneX.$($tabbable[$tabbable.length - 1]);

        // ---- No tabbable elements ----
        if($modalPrimaryButton.length === 0) {
          oneX.Modal.setFocus(event, $closeBtn);
          return;
        // ---- No shift (forward tabbing) ----
        } else if ($item.hasClass('-oneX-btn-secondary') && !event.shiftKey) {
          // tabbing off secondary button, focus to close button
          oneX.Modal.setFocus(event, $closeBtn, $modalPrimaryButton);
        } else if ($item.hasClass('-oneX-close-X-button') && !event.shiftKey) {
          // focus off close button to First Tabbable or primary button
          oneX.Modal.setFocus(event, $tabbableFirst, $modalPrimaryButton);

        // ---- Shift (back tabbing) ----
        } else if ($item.hasClass('-oneX-btn-primary') && event.shiftKey) {
          // shift tab off primary to Last Tabbable or close button
          oneX.Modal.setFocus(event, $tabbableLast, $closeBtn, $modalSecondaryButton);
        } else if ($item.hasClass('-oneX-modal-heading') && event.shiftKey) {
          // shift-tab off of heading to close button or secondary button
          oneX.Modal.setFocus(event, $closeBtn, $modalSecondaryButton);
        }  else if ($item.is($tabbableFirst) && event.shiftKey) {
          // shift-tab off of first tabbable to close button or secondary button
          oneX.Modal.setFocus(event, $closeBtn, $modalSecondaryButton);
        }
      }
    },

    defineLanguageModals: function () {
      oneX.$("a.display-language-warning")
        .attr("data-1x-language-show", "oneX-lang-modal-es");
      oneX.$(".-oneX-lang-link > a[href='#']").attr("data-1x-language-show", "oneX-lang-modal-en");
      
      oneX.$("[data-1x-language-show]:not(.-oneX-bound)").each(function () {
        oneX.$(this).on('click', oneX.Modal.showLanguageModal);
        oneX.$(this).addClass('-oneX-bound');
      });
    },

    showLanguageModal: function (event) {
      let modalToShow = oneX.$(event.target).attr("data-1x-language-show");
      if (modalToShow === undefined) {
        const $link = oneX.$(event.target).closest("[data-1x-language-show]");
        modalToShow = $link.attr("data-1x-language-show");
        oneX.Modal.showModal($link[0], modalToShow);
      }

      else {
        oneX.Modal.showModal(event.target, modalToShow);
      }

      return false;
    },

    closeButtonClicked: function (event) {
      if (oneX.$('[data-session-timeout]:visible').length === 0) {
        oneX.Modal.hideModal(event);
      }
      else {
        oneX.Modal.hideSessionModal(event);
      }
    },

    showModal: function (returnFocusObject, modalId) {
      const _self = this,
        $modal = oneX.Util.selectById(modalId),
        $dialog = $modal.find('.-oneX-modal-content'),
        scrimTypeString = ($modal.data('session-timeout') !== undefined) ? "sessionTimeOut" : "modal";
      $dialog.addClass('-oneX-modal-hidden');
      if ($modal.length > 0) {

        _self.kiboEsc = new Kibo();
        _self.kiboEsc.down('esc', _self.preventEscapeKeyAction);

        $modal.data('returnFocusObject', returnFocusObject);

        oneX.Modal.callModal($modal, scrimTypeString);



        $modal.find(".-oneX-modal-heading").on("keydown", { scrimType: scrimTypeString }, oneX.Util.componentWithScrim.headerKeyDown);
        $modal.find(".-oneX-modal-heading").focus();
        oneX.$(".-oneX--scrim__" + scrimTypeString).off("click").on("click", _self.scrimClick);

        if (returnFocusObject && returnFocusObject.href && oneX.$(returnFocusObject).hasClass("display-language-warning")) {
          _self.continueButtonUrl = returnFocusObject.href;
          _self.continueButtonTarget = oneX.$(returnFocusObject).attr('target');
          _self.eventIsHref = true;

        } else {
          _self.submittedForm = oneX.$(returnFocusObject.currentTarget).closest('form');
          _self.eventIsHref = false;
        }
        _self.modalEscKeyPressed = false;

      }
      $dialog.removeClass('-oneX-modal-hidden');
      oneX.$('html').addClass('-oneX-remove-second-scroll-bar'); 

      if (window.matchMedia('(max-width: 767px)').matches) {
        // IOS fix
        oneX.$('body').attr('data-oneX-top', `-${window.scrollY}px`);
        oneX.$('body').addClass('-oneX-prevent-scrim-scroll');

      }

    },

    callModal: function(modalId , scrimTypeString) {
     
      oneX.Util.componentWithScrim.showScrim(modalId, scrimTypeString);
      const $tabbable = modalId.find(oneX.Util.componentWithScrim.tabbable);
      oneX.$($tabbable[0]).on("keydown", { scrimType: scrimTypeString }, oneX.Util.componentWithScrim.firstTabbableKeyDown);

      oneX.$(".-oneX-content-behind-scrim__" + scrimTypeString).on("focusin", { scrimType: scrimTypeString },
        oneX.Util.componentWithScrim.scrimFocusIn);
      oneX.$(".-oneX-scrim-top__" + scrimTypeString).on("focusin", { scrimType: scrimTypeString },
        oneX.Util.componentWithScrim.topComponentFocusIn);
    },

    preventEscapeKeyAction: function (event) {
      if (oneX.$(".-oneX-modal__container:visible").is(":not(.-oneX-modal-action-required)")) {
        oneX.Modal.modalEscKeyPressed = true;
        oneX.Modal.closeButtonClicked();
        event.preventDefault();
      }
    },

    hideSessionModal: function (event) {
      if (oneX.$('[data-session-timeout]:visible').length > 0) {
        oneX.Modal.hideModal(event);
      }
    },

    hideModalAndCallback: function (callback, skipFocusAction = true, skipAnimation = false) {

      const $returnToFocus = skipFocusAction ? undefined : oneX.$(".-oneX-scrim-top__modal").data('returnFocusObject');

      oneX.Modal.cleanUpModal(skipAnimation, $returnToFocus, callback);
    },

    cleanUpModal: function (skipAnimation, $returnToFocus, callback) {
      const _self = this;
      let delay = 0;

      if (!skipAnimation && window.matchMedia('(max-width: 767px)').matches) {
        oneX.$('.-oneX-scrim-top__modal').find('.-oneX-modal-content').addClass('-oneX-modal-hidden');
        delay = 600;
      }

      this.kiboEsc.unregisterKeys('down', 'esc', _self.preventEscapeKeyAction);
      
      setTimeout(function () {
        if (oneX.$('[data-session-timeout]:visible').length > 0) {
          oneX.Util.componentWithScrim.removeScrim("sessionTimeOut");
        }
        else {
          oneX.Util.componentWithScrim.removeScrim("modal", function () {
            $returnToFocus && $returnToFocus.focus();
          });
        }

        oneX.$('.-oneX-modal-hidden').removeClass('-oneX-modal-hidden');
        oneX.$('html').removeClass('-oneX-remove-second-scroll-bar');
        // IOS Fix
        if (oneX.$('body').attr('data-oneX-top') !== 'auto' && oneX.$('body').attr('data-oneX-top') !== undefined) {
          const top = oneX.$('body').attr('data-oneX-top');
  
          oneX.$('body').removeClass('-oneX-prevent-scrim-scroll');
          oneX.$('body').removeAttr('data-oneX-top');
          window.scrollTo(0, parseInt(top) * -1);
        }
        else {
          oneX.$('body').removeAttr('data-oneX-top');
          oneX.$('body').removeClass('-oneX-prevent-scrim-scroll');
        }
  
        if (callback && typeof(callback) === "function"){
          callback();
        }
      }, delay);

    },

    hideModal: function () {
      const _self = this;
      
      const $returnToFocus = oneX.$(".-oneX-scrim-top__modal").data('returnFocusObject');

      oneX.Modal.cleanUpModal(false, $returnToFocus, undefined);

    },


    closeButtonSpacebar: function (event) {
      if (event.which === 32 && oneX.$(".-oneX-modal__container:visible").is(":not(.-oneX-modal-action-required)")) {
        //event.preventDefault();
        oneX.Modal.closeButtonClicked();
      }
    },

    scrimClick: function (event) {
      if (oneX.$(".-oneX-modal__container:visible").is(":not(.-oneX-modal-action-required)") && oneX.$('.-oneX-modal__container:visible[data-disabled-scrim]').length === 0) {
        oneX.Modal.closeButtonClicked();
      }
    },

    continueBtnClicked: function () {
      
      if (oneX.Modal.eventIsHref) {
        if (oneX.Modal.continueButtonTarget && oneX.Modal.continueButtonTarget !== "") {
          window.open(oneX.Modal.continueButtonUrl, oneX.Modal.continueButtonTarget);
          oneX.Modal.hideModal();
        }
        else {
        location.href = oneX.Modal.continueButtonUrl;
        }
      } else {
        oneX.$(oneX.Modal.submittedForm).submit();
      }
    },

    continueButtonUrl: '#',
    continueButtonTarget: '',
    modalEscKeyPressed: false,
    submittedForm: null,
    eventIsHref: false,
    kiboEsc: null
  };

  oneX.Config.queues(_COMPONENT);

})();