(function () {
  oneX.Util.throttle = {

    throttle: function (func, wait, lastCall, ...args) {
      let inThrottle;

      return function () {
        const context = this;

        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => {
            inThrottle = false;
            if (lastCall) {  // spit out one more at the end of the wait
              func.apply(context, args);
            }
          }, wait);
        }
      };
    }
  };
})();