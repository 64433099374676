/* eslint-disable complexity */
(function () {
  const _COMPONENT = {
    name: 'Timepicker',
    selectors: '[data-formatter=time]+button[class~=-oneX-btn-control]'
  };

  oneX.Timepicker = {
    init: function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function(_element) {
      this.bindEvents(_element);
    },

    removeElement: function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function(_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.renderTimepicker(_element);

        const $timepicker = _element.next('.-oneX-timepicker');

        _self.kiboTimeBtn = new Kibo(_element[0]);
        _self.kiboTimeBtn.down(['enter', 'space'], _self.toggleClock);
        
        $timepicker.find('.-oneX-time, .-oneX-time--selected').on('keydown', _self.navigateTimepicker);
        $timepicker.prev('.-oneX-btn-control').on('click', _self.toggleClock);

        $timepicker.find('.-oneX-hour--down').on('click', _self.decrementTime);
        $timepicker.find('.-oneX-minute--down').on('click', _self.decrementTime);
        $timepicker.find('.-oneX-hour--up').on('click', _self.incrementTime);
        $timepicker.find('.-oneX-minute--up').on('click', _self.incrementTime);
        $timepicker.find('.-oneX-time .-oneX-meridiem').on('click', _self.changeMeridiem);
        $timepicker.find('.-oneX-time .-oneX-meridiem').on('keydown', _self.changeMeridiem);

        _self.kiboTimeSubmit = new Kibo( $timepicker.find('button')[0]);
        _self.kiboTimeSubmit.down('enter', _self.submitTime);

        $timepicker.find('.-oneX-time .-oneX-button-link').on('click', _self.submitTime);
        $timepicker.on('click', _self.removeFocusState);

        $timepicker.siblings('input').on('blur', _self.validateTime);


        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function(_element) {
      const _self = this,
        $timepicker =  _element.next('.-oneX-timepicker');

      _element.prev('.-oneX-btn-control').off('click', _self.toggleClock);

      $timepicker.find('.-oneX-time, .-oneX-time--selected').off('keydown', _self.navigateTimepicker);
      $timepicker.find('.-oneX-hour--down').off('click', _self.decrementTime);
      $timepicker.find('.-oneX-minute--down').off('click',  _self.decrementTime);
      $timepicker.find('.-oneX-hour--up').off('click', _self.incrementTime);
      $timepicker.find('.-oneX-minute--up').off('click', _self.incrementTime);
      $timepicker.find('.-oneX-time .-oneX-meridiem').off('click', _self.changeMeridiem);
      $timepicker.find('.-oneX-time .-oneX-meridiem').off('keydown', _self.changeMeridiem);
      $timepicker.find('.-oneX-time a').off('click', _self.chosenTime);
      $timepicker.off('click', _self.removeFocusState);

      $timepicker.siblings('input').off('blur', _self.validateTime);
      $timepicker.remove();

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    /* Consider using the same initialization process as datepicker */
    renderTimepicker: function($timepickerControl) {
      $timepickerControl.after(`
        <div class="-oneX-timepicker" role="toolbar">
          <div class="-oneX-time" >
            <button class="-oneX-hour--down" aria-description="Use to decrease hour value"></button>
            <button class="-oneX-minute--down" aria-description="Use to decrease minute value"></button>
          </div>
          <div class="-oneX-time">
            <button class="-oneX-meridiem" aria-describedby="meridianRuleSet ruleSet"></button>
          </div>
          <div class="-oneX-time--selected">
            <button class="-oneX-hour" aria-live="polite" aria-describedby="ruleSet"></button>
            <span class="-oneX-colon" aria-hidden="true" tabindex="-1">:</span>
            <button class="-oneX-minute" aria-live="polite" aria-describedby="ruleSet"></button>
            <button class="-oneX-meridiem" aria-describedby="ruleSet"></button>
          </div>
          <div class="-oneX-time">
            <button class="-oneX-meridiem" aria-describedby="meridianRuleSet ruleSet"></button>
          </div>
          <div class="-oneX-time">
            <button class="-oneX-hour--up" aria-description="Use to increase hour value"></button>
            <button class="-oneX-minute--up" aria-description="Use to increase minute value"></button>
          </div>
          <div class="-oneX-time">
            <button role="link" class="-oneX-button-link">Submit</button>
          </div>
          <span aria-hidden="true" class="-oneX-clipped" id="ruleSet">Use up and down arrow keys to change values.</span>
          <span aria-hidden="true" class="-oneX-clipped" id="meridianRuleSet">Change the meridian value with a keyboard with the spacebar or tapping from a mobile device.</span>
        </div>
      `);
    },

    removeFocusState: function() {
      oneX.$('.-oneX-timepicker button').removeClass('-oneX-time-focus-state');
    },

    validateTime: function(event) {
      const input = event.target;
      if (!/^(10|11|12|0[1-9]):[0-5][0-9] [AaPp][Mm]/.test(input.value)) {
        oneX.Util.changeValue(input, "");
        oneX.Util.triggerEvent(input, "input");
      }
    },

    navigateTimepicker: function (event) {
      oneX.$('.log').text(`navigateTimepicker ${event.type}, ${event.keyCode}`)
      
      if (!oneX.$('body').hasClass('-oneX-user-tabbing')) {
        return
      }
      const _self = oneX.Timepicker;
      event.preventDefault();

      _self.removeFocusState();

      const $timePickerSelection = oneX.$(event.target);

      if (event.shiftKey && event.keyCode === 9) { // shift tab
        if ($timePickerSelection.prev().attr('tabindex') !== '-1') {
          $timePickerSelection.prev().focus();
        } else {
          $timePickerSelection.prev().prev().focus();
        }

        if ($timePickerSelection.hasClass('-oneX-hour') && $timePickerSelection.parent().hasClass('-oneX-time--selected')) {
          $timePickerSelection.parent().parent().find('.-oneX-button-link').focus();
        }

        if ($timePickerSelection.hasClass('-oneX-button-link')) {
          $timePickerSelection.parent().parent().find('.-oneX-time--selected .-oneX-meridiem').focus();
        }

      } else {
        switch (event.keyCode) {
        case 9: // tab
          if ($timePickerSelection.next().attr('tabindex') !== '-1') {
            $timePickerSelection.next().focus();
          } else {
            $timePickerSelection.next().next().focus();
          }

          if ($timePickerSelection.hasClass('-oneX-meridiem') && $timePickerSelection.parent().hasClass('-oneX-time--selected')) {
            $timePickerSelection.parent().parent().find('.-oneX-button-link').focus();
          }

          if ($timePickerSelection.hasClass('-oneX-button-link')) {
            $timePickerSelection.parent().parent().find('.-oneX-hour').focus();
          }

          break;

        case 37: // left arrow
          if ($timePickerSelection.prev().attr('tabindex') !== '-1') {
            $timePickerSelection.prev().focus();
          } else {
            $timePickerSelection.prev().prev().focus();
          }

          break;

        case 38: // up arrow
          let prevMeridiem = $timePickerSelection.parent().prev('.-oneX-time, .-oneX-time--selected').find(`.${$timePickerSelection.attr('class')}`);

          _self.decrementTime(event);

          if (prevMeridiem.attr('display') !== 'none') {
            prevMeridiem.focus();
          }

          break;

        case 39: // right arrow
          if ($timePickerSelection.next().attr('tabindex') !== "-1") {
            $timePickerSelection.next().focus();
          } else {
            $timePickerSelection.next().next().focus();
          }

          break;

        case 40: // down arrow
          let postMeridiem = $timePickerSelection.parent().next('.-oneX-time, .-oneX-time--selected').find(`.${$timePickerSelection.attr('class')}`);

          _self.incrementTime(event);

          if (postMeridiem.attr('display') !== 'none') {
            postMeridiem.focus();
          }

          break;

        case 13:  // enter
          _self.chosenTime($timePickerSelection.closest('.-oneX-timepicker'));
          break;

        case 32:  // space
          if($timePickerSelection.hasClass('-oneX-meridiem')) {
            _self.changeMeridiem(event);
          }

          break;

        case 27: // esc
          _self.hideClock();
          break;
        }
      }

      event.type === 'keydown' && oneX.$(':focus').addClass('-oneX-time-focus-state');
    },

    submitTime: function(event) {
      const $timepicker = oneX.$(event.target).closest('.-oneX-timepicker');

      oneX.Timepicker.chosenTime($timepicker);
    },

    chosenTime: function ($timepicker) {
      const timeVal = $timepicker.find('.-oneX-time--selected').text().replace(/\s+/g, ''),
        $timeInput = $timepicker.prev().prev('input');

      $timeInput.val(timeVal).change().focus();
      oneX.Timepicker.hideClock();
    },

    toggleClock: function(event) {
      const _self = oneX.Timepicker;

      event.preventDefault();

      const $clock = oneX.$(event.currentTarget).next();

      if ($clock.prev('button').attr('aria-expanded') === 'true' || $clock.attr('aria-expanded') === 'true') {
        _self.hideClock();
      } else {
        _self.showClock($clock);
        event.type === 'keydown' && $clock.find('.-oneX-hour').addClass('-oneX-time-focus-state');
      }
    },

    showClock: function($clock) {
      const _self = oneX.Timepicker;

      _self.hideClock();

      $clock.prev('button').attr("aria-expanded", true);
      $clock.attr("aria-label", "time picker");

      _self.setCurrentTime($clock);

      $clock.show();
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        $clock.find('.-oneX-hour').focus();
        $clock.find('.-oneX-hour').attr("tabindex", "0")
        $clock.find('.-oneX-minute').attr("tabindex", "0")
        $clock.find('.-oneX-time--selected .-oneX-meridiem').attr("tabindex", "0")
      }
      $clock.find('.-oneX-colon').attr({'aria-label':'colon', 'tabindex': '-1'});

      oneX.$('body').on('click keydown touchend', oneX.Timepicker.closeClockIfBackgroundSelected);
      oneX.$('body').on('touchstart touchcancel', oneX.Timepicker.setDraggingOff);
      oneX.$('body').on('touchmove', oneX.Timepicker.setDraggingOn);
    },

    hideClock: function() {
      const $clock = oneX.$('.-oneX-timepicker');

      $clock.prev('button').attr("aria-expanded", false);

      oneX.Timepicker.removeFocusState();

      $clock.hide();

      oneX.$('body').off('click keydown touchend', oneX.Timepicker.closeClockIfBackgroundSelected);
      oneX.$('body').off('touchstart touchcancel', oneX.Timepicker.setDraggingOff);
      oneX.$('body').off('touchmove', oneX.Timepicker.setDraggingOn);
    },

    closeClockIfBackgroundSelected: function (event) {
      if (!oneX.Timepicker.dragging) {
        const $element = oneX.$(event.target);

        if ($element.closest('.-oneX-btn-control').length === 0 && $element.closest('.-oneX-timepicker').length === 0) {
          oneX.Timepicker.hideClock();
        }      
      }
    },


    setCurrentTime: function($clock) {
      const _self = oneX.Timepicker,
        time = new Date(),
        isMidday = time.getHours() === 12,
        inputValue = $clock.parent().find('input').val();

      let isPM = time.getHours() >= 12,
        hour = time.getHours() - (isPM & !isMidday ? 12: 0),
        minute = time.getMinutes();

      $clock.find('.-oneX-time--selected .-oneX-meridiem').text(isPM ? "PM" : "AM");

      if (inputValue !== "") {
        hour = inputValue.substr(0, 2);
        minute = inputValue.substr(3, 2);
        isPM = (inputValue.substr(6, 2) === 'PM') ? true : false;
      }

      _self.displayHours(hour, $clock);
      _self.displayMinutes(minute, $clock);

      if (isPM) {
        _self.setPM($clock);
      } else {
        _self.setAM($clock);
      }
    },

    displayHours: function(hour, $clock) {
      const hourText = hour.toString().length < 2 ? `0${hour}` : hour;

      $clock.find('.-oneX-hour').text(hourText).attr('aria-label', `Selected Hour ${hourText}`);
    },

    displayMinutes: function(minute, $clock) {
      let interval = 1;

      if ($clock.parent().find('input').attr('data-time-interval') !== undefined) {
        interval = oneX.Timepicker.getInterval($clock);
      };

      if (interval !== 1) {
        minute = Math.ceil(parseInt(minute) / parseInt(interval)) * parseInt(interval);
        minute = minute > 59 ? 0 : minute
      }

      let minuteText = minute.toString().length < 2 ? `0${minute}` : minute;
      $clock
        .find(".-oneX-minute")
        .text(minuteText)
        .attr("aria-label", `Selected Minute ${minuteText}`);
    },

    setAM: function($clock) {
      $clock.find('.-oneX-time .-oneX-meridiem').attr('tabindex', '-1').text('').addClass('-oneX-hidden').removeAttr("aria-label");
      $clock.find('.-oneX-time--selected + .-oneX-time .-oneX-meridiem').attr('tabindex', '0').text('PM').removeClass('-oneX-hidden').attr("aria-label", "PM");
      $clock.find('.-oneX-time--selected .-oneX-meridiem').text('AM').attr("aria-label", "Selected AM");
    },

    setPM: function($clock) {
      $clock.find('.-oneX-time .-oneX-meridiem').attr('tabindex', '0').text('AM').removeClass('-oneX-hidden').attr("aria-label", "AM");
      $clock.find('.-oneX-time--selected + .-oneX-time .-oneX-meridiem').attr('tabindex', '-1').text('').addClass('-oneX-hidden').removeAttr("aria-label");
      $clock.find('.-oneX-time--selected .-oneX-meridiem').text('PM').attr("aria-label", "Selected PM");
    },

    changeMeridiem: function(event) {
      const _self = oneX.Timepicker,
        $selectedMeridiem = oneX.$(event.target),
        $clock = $selectedMeridiem.closest('.-oneX-timepicker');

      if ($selectedMeridiem.text() === 'AM') {
        _self.setAM($clock);
      } else {
        _self.setPM($clock);
      }

      $clock.find('.-oneX-time--selected .-oneX-meridiem').focus();
    },

    incrementTime: function(event) {
      let $selectedTime = oneX.$(event.target);
      const _self = oneX.Timepicker,
        $clock = $selectedTime.closest('.-oneX-timepicker');

      if (event.target.classList.contains('-oneX-hour--up')) {
        $selectedTime = $clock.find('.-oneX-hour');
      } else if (event.target.classList.contains('-oneX-minute--up')) {
        $selectedTime = $clock.find('.-oneX-minute');
      }

      if ($selectedTime.hasClass('-oneX-hour')) {
        let $hourToChange = parseInt($clock.find('.-oneX-hour').text()) + 1;

        if ($hourToChange === 13) {
          $hourToChange = 1;
        }

        _self.displayHours($hourToChange, $clock);
      }

      if ($selectedTime.hasClass('-oneX-minute')) {
        const interval = _self.getInterval($clock);
        let $minuteToChange = parseInt($clock.find('.-oneX-minute').text()) + parseInt(interval);

        if ($minuteToChange > 59) {
          $minuteToChange = 0;
        }

        _self.displayMinutes($minuteToChange, $clock);
      }
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        $selectedTime.focus();
      }
    },

    decrementTime: function(event) {
      let $selectedTime = oneX.$(event.target);
      const _self = oneX.Timepicker,
        $clock = $selectedTime.closest('.-oneX-timepicker');

      if (event.target.classList.contains('-oneX-hour--down')) {
        $selectedTime = $clock.find('.-oneX-hour');
      } else if (event.target.classList.contains('-oneX-minute--down')) {
        $selectedTime = $clock.find('.-oneX-minute');
      }

      if ($selectedTime.hasClass('-oneX-hour')) {
        let $hourToChange = parseInt($clock.find('.-oneX-hour').text()) - 1;

        if ($hourToChange === 0) {
          $hourToChange = 12;
        }

        _self.displayHours($hourToChange, $clock);
      }

      if ($selectedTime.hasClass('-oneX-minute')) {
        const interval = _self.getInterval($clock);
        let $minuteToChange = parseInt($clock.find('.-oneX-minute').text()) - parseInt(interval);

        if ($minuteToChange < 0) {
          $minuteToChange = 60 - parseInt(interval);
        }

        _self.displayMinutes($minuteToChange, $clock);
      }
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        $selectedTime.focus();
      }
    },

    getInterval: function($clock) {
      return ($clock.parent().find('input').attr('data-time-interval') === undefined) ? 1 : parseInt($clock.parent().find('input').attr('data-time-interval'));
    },

    setTime: function(clockId, hour, minute, meridiem) {
      const _self = oneX.Timepicker,
        $clock = oneX.$("#" + clockId).parent().find('.-oneX-timepicker');

      _self.displayHours(hour, $clock);
      _self.displayMinutes(minute, $clock);

      if (meridiem.toUpperCase() === 'AM') {
        _self.setAM($clock);
      } else {
        _self.setPM($clock);
      }
    },

    setDraggingOn: function() {
      oneX.Timepicker.dragging = true;
    },

    setDraggingOff: function() {
      oneX.Timepicker.dragging = false;
    },

    dragging: false,
    kiboTimeBtn: null,
    kiboTimeSubmit: null
  };

  oneX.Config.queues(_COMPONENT);
})();
