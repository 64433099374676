(function () {
  const _COMPONENT = {
    name: 'Table',
    selectors: '[class^="-oneX-table"]'
  };

  oneX.Table = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {

      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.tableA11y(_element);

        if (_element.hasClass('-oneX-table--expandable')) {
          _element.find('.-oneX-tbody--collapse').find('.-oneX-tr, .-oneX-tr .-oneX-th--row, .-oneX-tr .-oneX-td').css('display', 'none');
          const rowHead = _element.find('.-oneX-tbody:not(.-oneX-tbody--expandable) .-oneX-tr .-oneX-th--row');

          if (rowHead && rowHead.length > 0) {
            oneX.$(rowHead).each(function () {
              _self.kiboExpandable = new Kibo(this);
              _self.kiboExpandable.down('space', _self.toggleRow);  
            })
          }
          _element.find('.-oneX-tbody:not(.-oneX-tbody--expandable) .-oneX-tr .-oneX-th--row').on('mousedown', _self.toggleRow);
        }

        if (_element.hasClass('-oneX-table--sortable')) {
          _self.kiboSortable = new Kibo(_element[0]);
          _self.kiboSortable.down('space', _self.sortTable);

          _element.find('.-oneX-th--col:not(:first-child)').on('mousedown', _self.sortTable);
        }

        if (_element.data('max-visible')) {
          _self.setVisible(_element, 0);
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _self.kiboExpandable && _self.kiboExpandable.unregisterKeys('down', 'space', _self.toggleRow);
      _self.kiboSortable && _self.kiboSortable.unregisterKeys('down', 'space', _self.sortTable);
      
      _element.find('.-oneX-tbody:not(.-oneX-tbody--expandable) .-oneX-tr .-oneX-th--row').off('mousedown', _self.toggleRow);
      _element.find('.-oneX-th--col').off('mousedown', _self.sortTable);
      
      _element.find('.-oneX-th--col[class*="-oneX-chevron"]:not([class*="gray"])').off('keydown mousedown', _self.horizontalScroll);

      _element.removeData(_COMPONENT.name, _COMPONENT.name); 
    },

    tableA11y: function (_element) {

      if (!_element.is('table')) {
        const isFunctionalTable = !(_element.hasClass('-oneX-table--standard') || _element.hasClass('-oneX-table--comparison'));

        isFunctionalTable ? _element.attr('role', 'grid') : _element.attr('role', 'table');
        _element.find('.-oneX-thead, .-oneX-tbody').attr('role', 'rowgroup');
        _element.find('.-oneX-tr').attr('role', 'row');
        _element.find('.-oneX-th--col:first-of-type').text().length > 0 ? _element.find('.-oneX-th--col:first-of-type').attr('role', 'columnheader') : _element.find('.-oneX-th--col:first-of-type').attr('role', 'cell');
        _element.find('.-oneX-th--col:not(:first-of-type)').attr('role', 'columnheader');
        _element.find('.-oneX-th--row').attr('role', 'rowheader');
        isFunctionalTable ? _element.find('.-oneX-td').attr('role', 'gridcell') : _element.find('.-oneX-td').attr('role', 'cell');
      }

      if (_element.hasClass('-oneX-table--expandable')) {
        const $rowHeader = _element.find('.-oneX-tbody .-oneX-th--row');
        
        for (let i = 0; i < $rowHeader.length; i++) {
          oneX.$($rowHeader[i]).attr('tabindex', 0)
            .attr('aria-label', `${oneX.$($rowHeader[i]).text().trim()}. Select row header to expand and collapse.`)
            .attr('aria-expanded', 'false');
        }
      }

      if (_element.hasClass('-oneX-table--sortable')) {
        const $colHeader = _element.find('.-oneX-th--col');

        for (let i = 1; i < $colHeader.length; i++) {
          oneX.$($colHeader[i]).attr('tabindex', 0)
            .attr('aria-label', `${oneX.$($colHeader[i]).text().trim()}. Sort table by column values, ascending.`);
        }
      }
    },

    toggleRow: function (event) {
      event.preventDefault();

      const $adjacentTbody = oneX.$(event.target).closest('.-oneX-tbody').next();

      if ($adjacentTbody.hasClass('-oneX-tbody--expandable')) {
        const $rowHeader = oneX.$(event.target);

        $adjacentTbody.find('.-oneX-tr, .-oneX-tr .-oneX-th--row, .-oneX-tr .-oneX-td').slideToggle(250);

        $rowHeader.hasClass('-oneX-chevron-up') ? $rowHeader.removeClass('-oneX-chevron-up').attr('aria-expanded', 'false') : $rowHeader.addClass('-oneX-chevron-up').attr('aria-expanded', 'true');
        $adjacentTbody.hasClass('-oneX-tbody--collapse') ? $adjacentTbody.removeClass('-oneX-tbody--collapse') : $adjacentTbody.addClass('-oneX-tbody--collapse');
      } 
    },

    sortTable: function (event) {
      event.preventDefault();

      const $columnHeader = oneX.$(event.target),
        $table = $columnHeader.closest('.-oneX-table--sortable'),
        columnIndex = $columnHeader.index() - 1,
        sortDirection = ($columnHeader.data('sort-direction') === 'desc') ? 'desc' : 'asc',
        newSortDirection = (sortDirection === 'asc') ? 'desc' : 'asc';

      $table.find('.-oneX-th--col:not(:first)')
        .attr('aria-label', 'Sort table by column values, ascending.')
        .data('sort-direction', 'asc')
        .removeAttr('aria-sort')
        .removeClass('-oneX-chevron-up');

      let $rows, 
        switching = true, 
        incrementor, 
        shouldSwitch;

      while (switching) {
        switching = false;

        $rows = $table.find('.-oneX-tr');

        for (incrementor = 1; incrementor < ($rows.length - 1); incrementor++) {
          shouldSwitch = false;

          let cellA = oneX.$($rows[incrementor]).find('.-oneX-td')[columnIndex],
            cellB = oneX.$($rows[incrementor + 1]).find('.-oneX-td')[columnIndex];

          let cellValueA = cellA.innerText.toLowerCase(),
            cellValueB = cellB.innerText.toLowerCase();

            if (oneX.$(cellA).attr('data-onex-date') !== undefined) {
              const d = new Date(cellValueA);
              if ( !isNaN(d.getTime()) ) {
                cellValueA = 
                  d.getFullYear() + " " + 
                  ("0"+(d.getMonth()+1)).slice(-2) + "-" +
                  ("0" + d.getDate()).slice(-2) + "-" + 
                  ("0" + d.getHours()).slice(-2) + ":" + 
                  ("0" + d.getMinutes()).slice(-2);
              }
            }

            if (oneX.$(cellB).attr('data-onex-date') !== undefined) {
              const d = new Date(cellValueB);
              if ( !isNaN(d.getTime()) ) {
                cellValueB = 
                  d.getFullYear() + " " + 
                  ("0"+(d.getMonth()+1)).slice(-2) + "-" +
                  ("0" + d.getDate()).slice(-2) + "-" + 
                  ("0" + d.getHours()).slice(-2) + ":" + 
                  ("0" + d.getMinutes()).slice(-2);
              }
            }
    
          if (sortDirection === 'asc') {
            if (cellValueA > cellValueB) {
              shouldSwitch = true;
              break;
            }
          }
          else {
            if (cellValueA < cellValueB) {
              shouldSwitch = true;
              break;
            }
          }
        }

        if (shouldSwitch) {
          $rows[incrementor].parentNode.insertBefore($rows[incrementor + 1], $rows[incrementor]);

          switching = true;
        }
      }

      $columnHeader.data('sort-direction', newSortDirection);
      (newSortDirection === 'asc') ? $columnHeader.removeClass('-oneX-chevron-up').attr('aria-label', `${$columnHeader.text().trim()} column sorted to descending. Sort table by column values, ascending.`).attr('aria-sort', 'descending') : $columnHeader.addClass('-oneX-chevron-up').attr('aria-label', `${$columnHeader.text().trim()} column sorted to ascending. Sort table by column values, descending.`).attr('aria-sort', 'ascending');
    },

    setVisible: function ($table, incrementor) {
      const _self = this,
        maxVisible = parseInt($table.data('max-visible')),
        $caption = $table.find('.-oneX-tcaption'),
        $rows = $table.find('.-oneX-tr');

      let cellsLength;
      
      for (let i = 0; i < $rows.length; i++) {
        const $cells = oneX.$($rows[i]).find('.-oneX-th--col:not(:first-child), .-oneX-td');

        $cells.attr('tabindex', '');
        cellsLength = $cells.length;

        oneX.$($cells).addClass('-oneX-hidden');

        for (let i = 0 + incrementor; i < cellsLength; i++) {
        
          if (i < incrementor + maxVisible) {
            oneX.$($cells[i]).removeClass('-oneX-hidden');
          }
        }
      }

      $caption.text(`Showing columns ${incrementor + 1}-${incrementor + maxVisible} of ${cellsLength}`);

      _self.setChevrons(oneX.$($rows[0]), incrementor, maxVisible, cellsLength, $caption);
    },

    setChevrons: function ($headerRow, incrementor, maxVisible, cellsLength, $caption) {
      const _self = oneX.Table,
        $headerCellRight = $headerRow.find(':not(:first-child):not([class*="-oneX-hidden"]):last'),
        $headerCellLeft = $headerRow.find(':not(:first-child):not([class*="-oneX-hidden"]):first'),
        captionText = $caption.text().replace('-', ' through ').replace('of', 'out of');
        
        (incrementor + maxVisible === cellsLength) ? $headerCellRight.addClass('-oneX-chevron-right--gray').prepend('<span class="-oneX-clipped">Right navigation disabled.&nbsp;</span>').removeAttr('aria-label') : $headerCellRight.addClass('-oneX-chevron-right--blue').attr('aria-label', `Select chevron for right navigation. Currently ${captionText}. ${$headerCellRight.text().trim()}.`).prepend('<span class="-oneX-clipped">Right navigation.&nbsp;</span>');
        (incrementor === 0) ? $headerCellLeft.addClass('-oneX-chevron-left--gray').prepend('<span class="-oneX-clipped">Left navigation disabled.&nbsp;</span>').removeAttr('aria-label') : $headerCellLeft.addClass('-oneX-chevron-left--blue').attr('aria-label', `Select chevron for left navigation. Currently ${captionText}. ${$headerCellLeft.text().trim()}.`).prepend('<span class="-oneX-clipped">Left navigation.&nbsp;</span>');
  
      $headerRow.find('.-oneX-th--col').off('keydown mousedown', _self.horizontalScroll);
      $headerRow.find('.-oneX-th--col[class*="-oneX-chevron"]:not([class*="gray"])')
        .attr('tabindex', 0)
        .on('keydown mousedown', _self.horizontalScroll);
    },

    horizontalScroll: function (event) {
      if (event.type === 'mousedown' || event.which === 32) {
        event.preventDefault();

        const _self = oneX.Table,
          $chevron = oneX.$(event.target),
          $table = $chevron.closest('[class^="-oneX-table"]'),
          $rows = $table.find('.-oneX-tr'),
          direction = $chevron.hasClass('-oneX-chevron-right--blue') ? 'right' : 'left',
          incrementor = (direction === 'right') ? oneX.$($rows[0]).find(':not(:first-child):not([class*="-oneX-hidden"]):first').index(): oneX.$($rows[0]).find(':not(:first-child):not([class*="-oneX-hidden"]):first').prev().index() - 1;
  
          oneX.$($rows[0]).find('*').removeAttr('aria-label').removeClass('-oneX-chevron-right--blue -oneX-chevron-right--gray -oneX-chevron-left--blue -oneX-chevron-left--gray').find('span').remove();
         _self.setVisible($table, incrementor);
  
         (direction === 'right') ? $chevron.next().focus() : $chevron.prev().focus();
      }
    },

    updateMaxVisible: function (_element, numCols) {

      const $element = oneX.$(_element); 

      if (!$element.hasClass('-oneX-table--standard') || $element.data('max-visible') === undefined) {
        console.warn($element, "is not a oneX horizontal table--no action taken");
      } else if (!isNaN(parseInt(numCols))) {
        if (parseInt(numCols) < 2) {
          console.warn("numCols must be at least 2");
        }
        else {
          const maxShow = $element.find('.-oneX-th--col').length - 1;
          numCols = numCols > maxShow ? maxShow : numCols;
          $element.data('max-visible', numCols);
          oneX.$($element.find('.-oneX-tr')[0]).find('*').removeAttr('aria-label').removeClass('-oneX-chevron-right--blue -oneX-chevron-right--gray -oneX-chevron-left--blue -oneX-chevron-left--gray').find('span').remove();
          oneX.Table.setVisible($element, 0);
        }
      }
      else {
        console.warn("improper parameter 2 '" + numCols + "' is not a number--no action taken");
      }
    },
  
    kiboExpandable: null,
    kiboSortable: null
  };

  oneX.Config.queues(_COMPONENT);
})();
