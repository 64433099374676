(function () {
  const _COMPONENT = {
    name: 'ProgressIndicator',
    selectors: '[data-type^="1x-progress-indicator"], .-oneX-progress'
  };

  oneX.ProgressIndicator = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element);
        }
        if (_element.hasClass("-oneX-progress") && _element.find(".-oneX-progress__chapter_value").length===1) {
          const width = _element.find(".-oneX-progress__chapter_value").width();
          const parentWidth = _element.find(".-oneX-progress__chapter_value").offsetParent().width();
          const percent = 100*width/parentWidth;
          const childTag = oneX.$(`<div class='-oneX-progress-marker' style='width:${percent + '%'}'></div>`);

          _element.removeClass("-oneX-progress").addClass("-oneX-progress-bar -oneX-progress--single");

          const newstr =_element.html().replace(/&nbsp;/g, '');
          _element.html(newstr);
          _element.find(".-oneX-clipped").remove();
          _element.find(".-oneX-progress__chapter_value").width("").addClass("-oneX-progress-tracker").append(childTag);

          const observer = new MutationObserver(function(mutations) {
            oneX.ProgressIndicator.adjustWidthForOldProgress(_element);
          });
            observer.observe(oneX.$(".-oneX-progress__chapter_value")[0], {attributes: true, attributeFilter: ["style"]});
        }
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    renderHTML: function (_element) {
      
      const type = _element.data("type");
      const complete = _element.data("percentage-complete");


      if (type === '1x-progress-indicator--single') {
        const percentage = _element.data("percentage-complete");
        const $divTag = oneX.$("<div class='-oneX-progress-tracker'></div>");
        const $childTag = oneX.$(`<div class='-oneX-progress-marker' style='width:${percentage + '%'}'></div>`);
        _element.attr({"role": "progressbar","aria-label":"progressbar","aria-valuemin":0, "aria-valuemax": 100,"aria-valuenow":complete ? complete : 0});
        _element.addClass("-oneX-progress-bar -oneX-progress--single");

        $divTag.append($childTag);
        _element.append($divTag);
      }
      else {
        const $ulTag = oneX.$("<ul class='-oneX-progress-tracker'></ul>")
        const totalSteps = _element.data("total-steps");
        const currentStep = parseInt(_element.data("current-step"));
        const visitedStep = parseInt(_element.data("last-visited"));
        _element.attr({"role": "text","aria-live":"polite",'aria-label': "Progressbar step "+ currentStep +" of "+totalSteps});
        _element.addClass("-oneX-progress-bar -oneX-progress--multi");
        
        for (let i = 1; i <= totalSteps; i++) {
          const $liTag = oneX.$("<li class='-oneX-progress-step'> </li>")

          if (i === currentStep) { 
            $liTag.addClass("is-current");            
          } 
          if (i < currentStep) {
            $liTag.addClass("is-completed");
          } 
          if (i > currentStep && i <= visitedStep) {
            $liTag.addClass("is-visited");            
          }
          $ulTag.append($liTag); 
        }
       _element.append($ulTag);
      }
    },

    adjustWidthForOldProgress: function (_element) {
      const width = _element.find(".-oneX-progress__chapter_value").width();
      const childMarker = _element.find(".-oneX-progress-marker");

      if (oneX.ProgressIndicator.updatedProgressWidth !== true) {

      oneX.ProgressIndicator.updatedProgressWidth = true;
      _element.find(".-oneX-progress__chapter_value").width("");
      childMarker.width(width);
      }
      else {
        oneX.ProgressIndicator.updatedProgressWidth = false;
        }
    },

    singleProgressUpdate: function (id, percentageComplete) {

      const progressDivTag = oneX.Util.selectById(id);

      progressDivTag.find('.-oneX-progress-tracker .-oneX-progress-marker').width(percentageComplete+"%");
      progressDivTag.attr('aria-valuenow', percentageComplete);
    },

    multiProgressIncrement: function (id) {
      const progressDivTag = oneX.Util.selectById(id);
      const currentListItem = progressDivTag.find('.-oneX-progress-tracker li.is-current'); 
      const steps = progressDivTag.find('.-oneX-progress-tracker li').length;
      const currentStep = currentListItem.index() +1;

      if (currentStep < steps) {
        progressDivTag.attr("aria-label", "Progressbar step " + (currentStep +1) + " of "+steps);
        currentListItem.attr("aria-valuenow", "completed").addClass('is-completed').removeClass('is-current').next().attr("aria-valuenow", "current").addClass('is-current').removeClass('is-visited');
      }
    },

    multiProgressDecrement: function (id) {
      const progressDivTag = oneX.Util.selectById(id);
      const currentListItem = progressDivTag.find('.-oneX-progress-tracker li.is-current'); 
      const steps = progressDivTag.find('.-oneX-progress-tracker li').length;
      const currentStep = currentListItem.index() +1;

      if (currentStep >1) {
        progressDivTag.attr("aria-label", "Progressbar step " + (currentStep -1) + " of "+steps);
        currentListItem.attr("aria-valuenow", "visited").addClass('is-visited').removeClass('is-current').prev().attr("aria-valuenow", "current").addClass('is-current').removeClass('is-completed');
      }
    },
    
    updatedProgressWidth: false
  };
  oneX.Config.queues(_COMPONENT);
})();