oneX.Util.isGoogleLoaded = function(_objectName, _logIt){
  _objectName = typeof _objectName === "undefined" ? "maps": _objectName;
  _logIt = typeof _logIt === "undefined" ? true : _logIt;
  let _isLoaded = false;
  if (typeof google === "object" && typeof google.maps === "object") {
      _isLoaded = _objectName.toLowerCase() === 'maps' ? true : _isLoaded;
      if (typeof google.maps.places === "object"){
          _isLoaded = _objectName.toLowerCase() === 'places' && _isLoaded===false ? true : _isLoaded;
          if (typeof google.maps.places.Autocomplete === "function"){
            _isLoaded = _objectName.toLowerCase() === 'autocomplete' && _isLoaded===false ? true : _isLoaded;
          }
          if (typeof google.maps.places.SearchBox  === "function"){
            _isLoaded = _objectName.toLowerCase() === 'searchbox' && _isLoaded===false ? true : _isLoaded;
          }
      }
  }

  if (_logIt && !_isLoaded) {
    console.warn("Google API : " +_objectName+" is not loaded");
  }
  return _isLoaded;
};

oneX.Util.selectById = function(id) {
  return oneX.$('[id="' + id + '"]');
};

oneX.Util.triggerEvent = function(element, eventName){
  let event;
  
  if(typeof(Event) === 'function') {
    event = new Event(eventName, {"bubbles":true, "cancelable":false});
  }else{
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  element.dispatchEvent(event);
};

oneX.Util.changeValue = function (element, value) {
  const elementType = element.tagName === 'SELECT' ? window.HTMLSelectElement : window.HTMLInputElement;
  
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(elementType.prototype, "value").set;
 
  nativeInputValueSetter.call(element, value);
};