(function() {
  const _COMPONENT = {
      name : 'ClientName',
      selectors : '[data-acct-menu-username]'
  };

  oneX.ClientName = {
      init : function() {
        if (oneX.$('[data-acct-menu-username]').length > 0) {
          this.username.getUserPreferredName();
        }

        return this;
      },

      username : {
        dasl_method : '/daslClient/retrievecustomername',
        dasl_api_key : '', // removing value for the bug bounty fix, but this doesn't even work in the first place with the value ---DVTS-NavigationCHeaderDisplay (2.0.0)
        dasl_category : '',
        supported_test_environments: ['syst','perf','prep', 'test3', 'test5'],
        dasl_service : function() {
          const _protocol  = "https://", 
          _pod = "digital", 
          _test = ".test", 
          _domain = ".statefarm.com";

          let _lane = "",
            _service =  _protocol + _pod + _domain;

          if (oneX.Util.environment.isTest()) {
            const _host = location.hostname != null ? oneX.Util.environment.location() : "dvnt";

            // Note: Including 'dvnt' because DVNT uses lanes, however BE AWARE that Client service is NOT yet set up in DVNT
            if (_host === 'syst' || _host === 'dvnt') {
              _lane = oneX.Util.environment.lane();
              _service = _protocol + _pod  + "-" + _host + "-" + _lane + _test + _domain;
              this.dasl_category = _lane;
            } else {
              _service =   _protocol + _pod + "-" + _host + _test + _domain;
            }
          }

          return _service + this.dasl_method;
        },

        cookie : { 
          greeting : "headerGreeting", 
          last_known_sso : "dvts-last-known-sso",  
          sso : "sf-icp-sso"
        },

        /* ------------------------------------------------------------------------------ */
        // Added daslClientSSO function for setting current session sso cookie
        /* ------------------------------------------------------------------------------ */
        setSessionCheckCookie: function() {
          oneX.Util.cookie.set(this.cookie.last_known_sso, oneX.Util.cookie.get(this.cookie.sso), 'Session');
        },

        validateSessionCookie : function() {
          const last_known_sso_sso =  oneX.Util.cookie.get(this.cookie.last_known_sso),
            sso = oneX.Util.cookie.get(this.cookie.sso);
          
          let userName = oneX.Util.cookie.get(this.cookie.greeting);
          
          /*--------------------------------------Checking for the session --------------------------------------------------------*/
          if (typeof userName === "string") {   // daslClient is valued
            if (last_known_sso_sso !== sso) {     // but no one is logged in anymore or someone is logged in with a different sso
              userName = null;
              oneX.Util.cookie.destroy(this.cookie.greeting); // daslClient is stale
              oneX.Util.cookie.destroy(this.cookie.last_known_sso);
              console.info('clearing daslClient and associated sso');
            }
          } else if (last_known_sso_sso) { // else: daslClient expired never existed but associated sso cookie still exists in session
            oneX.Util.cookie.destroy(this.cookie.last_known_sso);
            console.info('clearing daslClient sso');
          }
          return userName;
        },
        /* ------------------------------------------------------------------------------ */
        // Displaying the Customer Name on successful AJAX call
        /* ------------------------------------------------------------------------------ */
        displayName: function(bestCustomerName, cookie = false) {
          let $username_span = oneX.$('[data-acct-menu-username]'),
          prefix = $username_span.data('acct-menu-prefix');

          $username_span.html(prefix + bestCustomerName);
          if (cookie) {
            oneX.Util.cookie.set(this.cookie.greeting, bestCustomerName, 120);
          }
        },

        validatePayload : function(data) {
          if(data !== null && data !== undefined){
            if (data.payload !== null && data.payload !== undefined) {
              if (data.payload.bestCustomerName !== null && data.payload.bestCustomerName !== undefined) {
                return true;
              }
            }
          }
        },

        getUserPreferredName : function() {
          const userName = this.validateSessionCookie();
          // ----------------------------------------------------------------------------------------------------------
          if (userName === null || userName === "null" || userName === undefined) {
            if( !this.isClientServiceSupported() ) {
              console.info('INFO: Client name not available in ' + oneX.Util.environment.location() + ' environment');
              return;
            }

            const _self = this;

            oneX.$.ajax({
              type : 'GET',
              url : this.dasl_service(),
              contentType : 'application/json',
              xhrFields : {
                withCredentials : true
              },

              headers : {
                'SR_API_KEY' : this.dasl_api_key,
                'SR_CATEGORY' : this.dasl_category
              },

              success : function (data) {
                if(_self.validatePayload(data)) {
                  _self.displayName(data.payload.bestCustomerName, true);
                }
                _self.setSessionCheckCookie();
              },

              error : function () {
                console.info('Error Processing SSO');
              }
            });
          } 
          else {
            this.displayName(decodeURIComponent(userName));
          }
        },

        isClientServiceSupported: function() {
          return !oneX.Util.environment.isTest() || (oneX.$.inArray(oneX.Util.environment.location(), this.supported_test_environments)>-1);
        }
      }
  };

  oneX.Config.queues(_COMPONENT);
})();

