(function() {
  const _COMPONENT = {
    name : 'FloatingLabel',
    selectors : '.-oneX-textfield__input, .-oneX-textfield--floating-input, .-oneX-dropdown'
  };

  oneX.FloatingLabel = {
    init : function() {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $label = oneX.$('label[for="' + _element.attr('id') + '"]');
        
        if ($label.is('.-oneX-textfield__floatingLabel, .-oneX-dropdown__floatingLabel')) {
          _element.not('.-oneX-dropdown').on('focus', _self.moveLabelUpFocus);
          _element.on('change blur', _self.moveLabelUp);   

          _self.moveLabelUp(_element);

          _element.data(_COMPONENT.name, _COMPONENT.name);
        }
      }
    },

    unbindEvents : function(_element) {
      const _self = this;
      
      _element.off('change blur', _self.moveLabelUp);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    moveLabelUp : function(eventOrElement) {
      const $input = eventOrElement.target ? oneX.$(eventOrElement.target) : eventOrElement,
        $label = oneX.$('label[for="' + $input.attr('id') + '"]');

      if ($input.is(':focus') === false && 
        ($input.val() === null || $input.val().length === 0 || oneX.$('option:selected', this).val() === "")) {
        $label.removeClass('-oneX--floatUp');
      }
      else {
        $label.addClass('-oneX--floatUp');
      }
    },

    moveLabelUpFocus : function() {
      oneX.$('label[for="' + oneX.$(this).attr('id') + '"]').addClass('-oneX--floatUp');
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
