(function() {
  oneX.Util.environment = {

    locale: function() {
      let locale = this.getParameterUri('locale');
      if (locale === '') {
        const lang = oneX.$("html").attr("lang");
        if (lang !== undefined){
          locale = oneX.$("html").attr("lang");
        } else {
          locale = "en-US";
        }
        
      }
      return locale;
    },

    pod: function() {
      return oneX.Util.location.hostname().match(/^[a-z0-9]*/g).toString();
    },

    lane: function() {
      return oneX.Util.location.hostname().match(/(?:-)([a-z])(?=\.)/) ? oneX.Util.location.hostname().match(/(?:-)([a-z])(?=\.)/)[1] : '';
    },

    language: function() {
      return oneX.Util.environment.locale().substring(0, 2);
    },
    domain: function() {
      return oneX.Util.location.hostname().match(/.(com|ca|org)/);
    },

    location: function() {

      let location = "";

      if (oneX.Util.environment.isTest()) {
        const specifiedTestEnv = oneX.$("body").data("onexcontent");
        if (specifiedTestEnv){
          location = specifiedTestEnv;
        }
        else if (oneX.Util.location.hostname().match(/^local/) !== null){
          location = "env1";
        }
        else if (oneX.Util.location.hostname().match(/statefarm.frontify.com/) !== null) {
          location = "prep";
        }
        else if (oneX.Util.location.hostname().match(/([^-]+\d)/) !== null) { // If TP2
          location = oneX.Util.location.hostname().match(/[\w\s]+(?=\.)/)[0];
        }
        else { // If TP1
          location = oneX.Util.location.hostname().replace('sfcheckout-', '').match(/(?:-)(?:\w{2,})/).toString().substring(1)
        }
      }

      if (!location.match(/(test|sandbox|dvnt|syst|perf|prep|prod|env)/)) { // Environment not able to be determined by URL
        location = 'production';
      }
     
      return location;
    },

    isTest: function() {
      return ((oneX.Util.location.hostname().match(/\.(test|tc\w*)\./) !== null || oneX.Util.location.hostname().match(/statefarm.frontify.com/) !== null) && oneX.Util.location.hostname().match(/search.test.c1.statefarm/) === null);
    },

    getParameterUri: function(name) { /*  Retrieves the query string values for the current location.  */
      name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(oneX.Util.location.search());
      
      return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    getStaticHostName: function(test_env) {
      const isLocal = oneX.Util.location.port().length > 0;
      
      let _host = "https://static1.st8fm.com",
        environment = oneX.Util.environment.location();
      
      if (oneX.Util.environment.isTest() || isLocal) {
        if ( isLocal ) {
          environment = 'env1';
        }
        // override to use data-onexcontext if present
        const specifiedTestEnv = oneX.$("body").data("onexcontent");
        if (specifiedTestEnv){
          environment = specifiedTestEnv;
        } 
        if ( typeof test_env !== 'undefined' ) {
          environment = test_env;
        }
        
        _host = "https://static1-" + environment + ".test.st8fm.com";
      }
      
      return _host;
    },

    getRenderedHtmlOrigin: function (path) {
      const locale = (oneX.Util.locale.isSpanish()) ? "/es_US" : "/en_US",
        staticLocale = oneX.Util.environment.getStaticHostName().replace(/(:?)([\/\\]+)/g, function (_, d, s) { return d ? d + s : '/' }) + locale,
        isLocalTestHarness = oneX.Util.location.port().length > 0 && /onex-testharness/ig.test(oneX.Util.location.pathname()), 
        isLocalTesting = (oneX.Util.location.pathname() === '/context.html' || oneX.Util.location.port() === '8888'),
        segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src');

      let renderedHtmlOrigin = `${staticLocale}/dxl-1x/${oneX.Util.location.getVersion()}/renders/${path}`;

      if (isLocalTestHarness) {
        const colon3000 = segmentSection.indexOf(':3000'),
          localPath = segmentSection.substr(0, colon3000 + 5);

          renderedHtmlOrigin = `${localPath}/html/${path}${locale}`;
      }
      else if (isLocalTesting) {
        renderedHtmlOrigin = (oneX.Util.location.port() === '8888') ? `${oneX.Util.location.origin()}` : `base/dist/html/${path}/en_US`;
      }    
      
      return renderedHtmlOrigin;
    }
  };
})();
