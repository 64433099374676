(function () {
  const _COMPONENT = {
    name: 'VerticalIndicator',
    selectors: '.-oneX-vertical-indicator'
  };

  oneX.VerticalIndicator = {
    init: function () {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $mobileViewButtonDropdown = _element.find('button'),
          $activeElement = _element.find('.-oneX-active');
        
        _self.verticalIndicatorA11y(_element);

        _self.debouncedScreenResize = oneX.Util.debounce.debounce(_self.screenResize, 250, _element);
        oneX.$(window).on("resize", _self.debouncedScreenResize);

        $mobileViewButtonDropdown.on('click', _self.openNavigationMenu);

        $mobileViewButtonDropdown.text(`${$activeElement.text().trim()}`);
        _self.incrementProgressBar($activeElement.closest('li').index(), _element.find('li'));

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this,
      $mobileViewButtonDropdown = _element.find('button');
      
      oneX.$(window).off("resize", _self.debouncedScreenResize);
      $mobileViewButtonDropdown.off('click', _self.openNavigationMenu);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    verticalIndicatorA11y: function (_element) {
      const $navigationAnchors = _element.find('a');

      for (let i = 0; i < $navigationAnchors.length; i++) {
        const $navigationListItem = oneX.$($navigationAnchors[i]);
        const current = $navigationListItem.parent().hasClass('-oneX-active') ? 'current ' : '';
        $navigationListItem.attr('aria-label', `${current}step ${i + 1} ${$navigationListItem.text().trim()}`);
      }

      _element.find('.-oneX-disabled a').attr('role', 'link').attr('tabindex', '-1').attr('aria-disabled', true);
    },

    screenResize($verticalIndicator) {
      const $mobileViewButtonDropdown = $verticalIndicator.find('button'),
        $navigationList = $verticalIndicator.find('ol').find('li'),
        $mobilViewTracker = $verticalIndicator.find('.-oneX-vertical-indicator-tracker');

      if (window.matchMedia('(max-width: 991px)').matches) {
        $mobileViewButtonDropdown.removeClass('-oneX-hidden');
        $mobilViewTracker.removeClass('-oneX-hidden');
        $navigationList.css('display', 'none');
      }
      else {
        $mobileViewButtonDropdown.addClass('-oneX-hidden');
        $mobilViewTracker.addClass('-oneX-hidden');
        $navigationList.css('display', 'list-item');
      }
    },

    openNavigationMenu: function (event) {
      const $mobileViewButtonDropdown = oneX.$(event.target),
        $verticalIndicator = $mobileViewButtonDropdown.closest('.-oneX-vertical-indicator'),
        $navigationList = $verticalIndicator.find('ol').find('li'),
        $mobilViewTracker = $verticalIndicator.find('.-oneX-vertical-indicator-tracker');

      if (window.matchMedia('(max-width: 991px)').matches) {
        // $mobileViewButtonDropdown.addClass('-oneX-hidden');
        $mobilViewTracker.insertAfter($mobileViewButtonDropdown);
        $navigationList.css('display', 'list-item');
      }
    },

    panelLinkClick: function (newSection) {
      const _self = oneX.VerticalIndicator,
        $newSection = oneX.$(newSection),
        $verticalIndicator = $newSection.closest('.-oneX-vertical-indicator'),
        $navigationList = $verticalIndicator.find('li'),
        $mobileViewButtonDropdown = $verticalIndicator.find('button'),
        $mobilViewTracker = $verticalIndicator.find('.-oneX-vertical-indicator-tracker');

      if (window.matchMedia('(max-width: 991px)').matches) {
        $mobileViewButtonDropdown.removeClass('-oneX-hidden');
        $mobilViewTracker.removeClass('-oneX-hidden');
        $navigationList.css('display', 'none');
      }
      
      return _self.panelAction($verticalIndicator, $newSection);
    },

    prevbtnPanelClick: function (verticalIndicator) {
      const _self = oneX.VerticalIndicator,
        $verticalIndicator = oneX.$(verticalIndicator),
        $prevSection = $verticalIndicator.find('.-oneX-active').prev();

      if ($prevSection.length) {
        return _self.panelAction($verticalIndicator, $prevSection);
      }
    },

    nextbtnPanelClick: function (verticalIndicator) {
      const _self = oneX.VerticalIndicator,
        $verticalIndicator = oneX.$(verticalIndicator),
        $nextSection = $verticalIndicator.find('.-oneX-active').next();

      if ($nextSection.length) {
        return _self.panelAction($verticalIndicator, $nextSection);
      }
    },

    panelAction: function ($verticalIndicator, $newSection) {
      const _self = oneX.VerticalIndicator,
        $newSectionItem = $newSection.closest('li'),
        $verticalIndicatorList = $verticalIndicator.find('li'),
        $mobileViewButtonDropdown = $verticalIndicator.find('button'),
        $currentActiveIndicator = $verticalIndicator.find('.-oneX-active'),
        sectionData = {
          currentSectionData: $currentActiveIndicator.data('page-nav'),
          newSectionData: $newSectionItem.data('page-nav')
        };

      $verticalIndicatorList.removeClass('-oneX-active').attr("aria-selected", false);
      $newSectionItem.removeClass('-oneX-disabled').addClass('-oneX-visited -oneX-active').attr("aria-selected", true);
      $verticalIndicator.find('li:not(.-oneX-disabled) a').removeAttr("aria-disabled tabindex");
      
      $mobileViewButtonDropdown.text(`${$newSection.text().trim()}`);

      oneX.VerticalIndicator.verticalIndicatorA11y($verticalIndicatorList);
      _self.incrementProgressBar($newSectionItem.index(), $verticalIndicatorList);
      return sectionData;
    },

    incrementProgressBar: function (selectedListIndex, $navigationList) {
      const $progressBar = $navigationList.closest('ol').siblings('.-oneX-vertical-indicator-tracker').find('.-oneX-vertical-indicator-marker');

      selectedListIndex += 1;
      (selectedListIndex === 1) ? $progressBar.width('10%') : $progressBar.width(`${selectedListIndex / $navigationList.length * 100}%`);
    },

    debouncedScreenResize: null
  };

  oneX.Config.queues(_COMPONENT);
})();