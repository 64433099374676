(function () {
  const _COMPONENT = {
    name : 'ExpansionCard',
    selectors : '.-oneX-expansion-card-container'
  };
  
  oneX.ExpansionCard = {
    init: function() {
      const _self = this;
      
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this; 
    },
    
    addElement : function(_element) {
      this.bindEvents(_element);
    },
    
    removeElement : function(_element) {
      this.unbindEvents(_element);
    },
  
    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _element.find('.-oneX-expansion-control').on('click', _self.togglePanel);
          
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },
  
    unbindEvents : function(_element) {
      const _self = this;
      _element.find('.-oneX-expansion-control').off('click', _self.togglePanel);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },
    
    togglePanel: function(event) {
      const $target = oneX.$(event.currentTarget)
      const $targetButton = $target.find('.-oneX-expansion-button')
      const $targetContent = oneX.Util.selectById($targetButton.attr('aria-controls'));
      
      if($targetButton.attr('aria-expanded') === "true") {
        $targetButton.attr('aria-expanded', false);
        $targetContent.slideToggle(400);
        $targetContent.addClass('-oneX-hidden');
      } else {
        $targetButton.attr('aria-expanded', true);
        $targetContent.slideToggle(400);
        $targetContent.removeClass('-oneX-hidden');
      }
    }
  };
  
  oneX.Config.queues(_COMPONENT);
})();
