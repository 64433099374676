(function () {
  const _COMPONENT = {
    name: 'ActionMenu',
    selectors: '.-oneX-action-container'
  };

  oneX.ActionMenu = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _element.find('.-oneX-action-btn').on("click", _self.toggleMenu);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _element.find('.-oneX-action-btn').off("click", _self.toggleMenu);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    toggleMenu: function (event) {

      const $content = oneX.$(event.currentTarget).siblings('.-oneX-action-content');

      if ($content.hasClass('-oneX-hidden')) {
        // show it
        oneX.ActionMenu.showMenu($content);
      }
      else {
        // hide it
        oneX.ActionMenu.hideOpenMenu($content);
      }
    },

    showMenu: function ($content) {

      oneX.ActionMenu.hideOpenMenu();

      oneX.ActionMenu.$currentOpenBtn = $content.siblings('button');

      $content.removeClass('-oneX-hidden');
      oneX.ActionMenu.$currentOpenBtn.attr('aria-expanded', 'true');

      oneX.$('body').on('click keydown touchend', oneX.ActionMenu.clickToCloseMenu);
      oneX.$('body').on('touchstart touchcancel', oneX.ActionMenu.setDraggingOff);
      oneX.$('body').on('touchmove', oneX.ActionMenu.setDraggingOn);
      oneX.$('body').on('focusin', oneX.ActionMenu.checkToCloseMenu);

    },

    hideOpenMenu: function ($passedContent) {

      const $content = $passedContent && $passedContent.length > 0 ? $passedContent : oneX.$('.-oneX-action-content:not(.-oneX-hidden)'),
        $button = $content.siblings('button');

      $content.addClass('-oneX-hidden');
      $button.attr('aria-expanded', 'false');

      oneX.ActionMenu.$currentOpenBtn = null;
      oneX.$('body').off('click keydown touchend', oneX.ActionMenu.clickToCloseMenu);
      oneX.$('body').off('touchstart touchcancel', oneX.ActionMenu.setDraggingOff);
      oneX.$('body').off('touchmove', oneX.ActionMenu.setDraggingOn);
      oneX.$('body').off('focusin', oneX.ActionMenu.checkToCloseMenu);
      // console.log('hideOpenMenu was called with: ', $passedContent);

    },

    checkToCloseMenu: function(event) {

      if (!oneX.$(event.target).hasClass('-oneX-action-item') && !oneX.$(event.target).is(oneX.$('.-oneX-action-content:not(.-oneX-hidden)').siblings('button'))) {
        oneX.ActionMenu.hideOpenMenu();
      }
    },

    clickToCloseMenu: function(event) {
      
      // This gets called too many times, so we need to find the few cases where it should fire
      if (oneX.ActionMenu.dragging) {
        // console.log('no-action-moving:');
        return;
      }

      if (event.originalEvent && event.originalEvent.key === "Shift") {
        // console.log('no-action-shift-key-only:');
        return;
      }

      const $target = oneX.$(event.target),
        $currentOpen = oneX.$('.-oneX-action-content:not(.-oneX-hidden)');

      if ($currentOpen.is($target.siblings('.-oneX-action-content'))) {
        // console.log('no-action--current open');
        return;
      }

      if ($target.hasClass('-oneX-action-content') || $target.parent().hasClass('-oneX-action-content') || 
          $target.hasClass('-oneX-icon') || $target.hasClass('-oneX-action-item') || 
          $target.parent().hasClass('-oneX-action-item') || $target.hasClass('-oneX-action-item-text')) {
        // console.log('no-action-clicked-on-open action menu');
        return;
      }

      if ($target.parent().hasClass('-oneX-action-btn') || $target.hasClass('-oneX-action-btn')) {
        // console.log("clicked on the button or the span");
        return;
      }

      // console.log('Click to Close Menu target: ', $target)

      oneX.ActionMenu.hideOpenMenu();
      // console.log('we closed it!');
    },

    setDraggingOn: function() {

      oneX.ActionMenu.dragging = true;
    },

    setDraggingOff: function() {

      oneX.ActionMenu.dragging = false;
    },

    $currentOpenBtn: null,
    dragging: false
  
  };

  oneX.Config.queues(_COMPONENT);

})();
