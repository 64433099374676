(function () {
  oneX.Util.GoogleMapsMarkers = {

    init: function (mapId, mapCenter, zoomLevel, latLng) {

      const _self = this,
        map = new google.maps.Map(document.getElementById(mapId), {
          center: mapCenter,
          zoom: zoomLevel
        });

      if (latLng) {
        _self.generateMarkers(map, latLng);
      }

      return map;
    },

    generateMarkers: function (map, latLng) {
      const _self = this;

      let icon_char = 'A';

      const markers = latLng.map(function (location) {
        const marker = new google.maps.Marker({
          'position': location,
          'icon': _self.getLocation() + 'pin_' + icon_char + '_default.png',
          'map': map
        });

        _self.markerHover(marker, _self.getLocation() + 'pin_' + icon_char);

        _self.jumpToMarker(map, marker);

        icon_char = String.fromCharCode(icon_char.charCodeAt(0) + 1);

        return marker;
      });

      _self.generateMarkerClusterers(map, markers);
    },

    getLocation: function () {
      const onexScript = (oneX.$('script[src*="1x.core.js"]').length > 0) ? oneX.$('script[src*="1x.core.js"]').attr('src') : 'prod';
      let path;

      const segmentSection = onexScript.match(/:3000|alpha|beta|charlie|prod/);

      if (segmentSection[0] === ':3000') {
        const colon3000 = segmentSection['input'].indexOf(':3000');

        path = segmentSection['input'].substr(0, colon3000 + 5) + "/css/images/maps/";
      } else {
        path = oneX.Util.environment.getStaticHostName() + "/en_US/dxl-1x/" + segmentSection[0] + "/css/images/maps/"
      }

      return path;
    },

    markerHover: function (marker, icon_char_path) {
      marker.addListener('mouseover', function () {
        marker.setIcon(icon_char_path + '_hover.png');
      });

      marker.addListener('mouseout', function () {
        marker.setIcon(icon_char_path + '_default.png');
      });
    },

    jumpToMarker: function (map, marker) {
      marker.addListener('click', function () {
        map.setCenter(marker.getPosition());
      });
    },

    generateMarkerClusterers: function (map, markers) {
      const _self = this,
        mcOptions = {
          styles: [{
            'anchor': [10, 13],
            'height': 50,
            'textColor': '#ffffff',
            'textSize': 13,
            'url': _self.getLocation() + 'pin_multiple_default.png',
            'width': 52
          }],
          labelOrigin: new google.maps.Point(20, 30)
        };

      new MarkerClusterer(map, markers, mcOptions);
    }
  };
})();
