(function () {
  oneX.Util.debounce = {

    debounce: function (func, wait, ...args) {
      let timeout;

      return function () {
        const context = this,
          later = function() {
          timeout = null;

          func.apply(context, args);
        };

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);
      };
    }
  };
})();