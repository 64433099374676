(function () {
  oneX.TextfieldErrors = {
    // Error messages
    addError: function ($element, errText) {
      //to Add an Error state to the ZipCode Field or other input fields
      const elemId = $element.attr("id");

      if (errText && errText !== "") {
        oneX.$("#" + elemId + "-err").text(errText);
      }

      $element.closest(".-oneX-" + elemId).addClass("-oneX-widget--err");
      $element.siblings(".-oneX-widget__err-text").removeClass("-oneX-hidden");
      $element.attr(
        "aria-describedby",
        $element.siblings(".-oneX-widget__err-text").attr("id")
      );
      $element.attr("aria-invalid", true);
      $element.removeAttr("pattern");
    },

    removeError: function ($element) {
      //to Remove the Error state to the Field
      const elemId = $element.attr("id");

      $element.closest(".-oneX-" + elemId).removeClass("-oneX-widget--err");
      $element.siblings(".-oneX-widget__err-text").addClass("-oneX-hidden");
      $element.removeAttr("aria-describedby");
      $element.removeAttr("aria-invalid");
    },
  };
})();
