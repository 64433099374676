(function () {
  const _COMPONENT = {
    name: 'ExpansionPanel',
    selectors: '.-oneX-panel-control'
  };

  oneX.ExpansionPanel = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $panel = oneX.$(_element);

        if ($panel.data("initially-open") === true) {
          $panel.find('.-oneX-panel-button').attr('aria-expanded', true);
          $panel.siblings('.-oneX-panel-content').removeClass('-oneX-hidden').css("width", "auto").css("display", "block");
        }

        $panel.on('click touchend', _self.clickHandler);
        $panel.on('touchstart touchcancel', _self.setDraggingOff);
        $panel.on('touchmove', _self.setDraggingOn);

        _element.data(_COMPONENT.name, _COMPONENT.name);

      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $panel = oneX.$(_element);

      $panel.off('click touchend', _self.clickHandler);
      $panel.off('touchstart touchcancel', _self.setDraggingOff);
      $panel.off('touchmove', _self.setDraggingOn);
      oneX.$("body").removeData("oneX-resize-panel-bound");
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    setDraggingOn: function() {
      oneX.ExpansionPanel.dragging = true;
    },

    setDraggingOff: function() {
      oneX.ExpansionPanel.dragging = false;
    },

    clickHandler: function (event) {
      if (oneX.ExpansionPanel.dragging) {
        // if touchend was part of scrolling the screen
        return;
      }
    
      if (oneX.ExpansionPanel.alreadyClickedPanel) {
        return;
      }

      oneX.ExpansionPanel.alreadyClickedPanel = true;
      setTimeout(function () { 
        oneX.ExpansionPanel.alreadyClickedPanel = false;
      }, oneX.ExpansionPanel.clickDelay);
            
      const $target = oneX.$(event.currentTarget), 
        $targetButton = $target.find('.-oneX-panel-button'),
        $targetContent = oneX.Util.selectById($targetButton.attr('aria-controls'));

      if($targetButton.attr('aria-expanded') === "true"){
        //close panel
        $targetButton.attr('aria-expanded', false);
        $targetContent.slideToggle(400, function easeOutExpo(x) {
          return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
        });
        $targetContent.addClass('-oneX-hidden');
      } else {
        //open panel
        $targetButton.attr('aria-expanded', true);
        $targetContent.css('display','block');
        $targetContent.width($targetContent.width());
        $targetContent.css('display','none');
        $targetContent.slideDown(400, function easeOutExpo(x) {
          return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
        });

        $targetContent.width('auto');
        $targetContent.removeClass('-oneX-hidden');
      }
    },

    alreadyClickedPanel: false,
    dragging: false,
    debouncedCalculatePanelHeight: null,
    clickDelay: 200
  };

  oneX.Config.queues(_COMPONENT);
})();
