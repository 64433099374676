(function() {
  const _COMPONENT = {
    name : 'Autocomplete',
    selectors : ".-oneX-exploded_Address1 input"
  };
  
  oneX.Autocomplete = {
    init : function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {    
        _element.on("input focus", _self.addAutocomplete);
        _element.on("blur", _self.updateSuggestionMessage);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    }, 

    unbindEvents : function(_element) {
      const _self = this;
      
      _element.off('input focus', _self.addAutocomplete);
      _element.off('blur', _self.updateSuggestionMessage);
      
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    //TODO: Evaluate try/catch logic and only use for code that actually throws an error. This code should not throw an exception.
    minKeyStrokesMet: function(input) {
      try {
        let response = false;
  
        if (input.val().length > 2) {
          response = true;
        }
  
        return response;
      } catch (err) {
        console.debug("Validate Key Stroke Failed: " + err);
      }
    },

    //TODO: Evaluate try/catch logic and only use for code that actually throws an error. The try/catch logic should not wrap an entire function.
    explodeAutocompleteAddress: function() {
      try {
        if (typeof oneX.Autocomplete.currentInstance.getPlace() !== "undefined" && oneX.Autocomplete.currentInstance.getPlace().address_components !== undefined) {
          const _self = oneX.Autocomplete,
            $parent = oneX.Autocomplete.currentInstance.input, // Will throw an error if currentInstance is null. Checking currentInstance negates the need for a try/catch.
            $address1 = $parent.find(".-oneX-exploded_Address1 input"),
            addressComponents = oneX.Autocomplete.currentInstance.getPlace().address_components, // Will throw an error if getPlace() is null
            $address2 = $parent.find(".-oneX-exploded_Address2 input"),
            $city = $parent.find(".-oneX-exploded_City input"),
            $state = $parent.find(".-oneX-exploded_State select"),
            $zip = $parent.find(".-oneX-exploded_Zip input");

          oneX.Util.changeValue($city[0], _self.getCity(addressComponents));
          oneX.Util.changeValue($state[0], _self.getValue(addressComponents, "administrative_area_level_1"));
          oneX.Util.changeValue($zip[0], _self.getValue(addressComponents, "postal_code"));

          $zip.data("1x-manualSetValue", true);

          oneX.Util.triggerEvent($city[0], 'input');
          oneX.Util.triggerEvent($zip[0], 'input');

          oneX.Util.triggerEvent($city[0], 'change');
          oneX.Util.triggerEvent($state[0], 'change');
          oneX.Util.triggerEvent($zip[0], 'change');
          
  
          _self.removeAutocomplete();
          oneX.Util.changeValue($address1[0], _self.getAddressLine1(addressComponents, $address1.val()));
          oneX.Util.triggerEvent($address1[0], 'input');
          oneX.Util.triggerEvent($address1[0], 'change');
  
          if ($address1.data("1x-tab-pressed") !== "true") {
            $address2.focus();
            _self.updateSuggestionMessage("");
          }
  
          $address1.data("1x-tab-pressed", "false");
          $address1.data("1x-selectionMade", "false");
        }
      } catch (_error) {
        console.debug('Explode Autocomplete Address ' + _error);
      }
    },

    updateSuggestionMessage(type) {
      if (oneX.Autocomplete.currentInstance !== null) {
        let message = "";

        if (type === "noSuggestions") {
          message = "No suggestions are available";
        } 
        else if (type === "suggestions") {
          message = "Suggestions are available";
        }

        oneX.Autocomplete.currentInstance.input.find(".-oneX-searchSuggestions").html(message);
      }
    },

    removeAutocomplete: function() {
      if (oneX.Autocomplete.currentInstance !== null){
        google.maps.event.removeListener(oneX.Autocomplete.currentInstance.listener); // Will throw an error if currentInstance is null. Checking currentInstance negates the need for a try/catch.
        oneX.Autocomplete.currentInstance = null;
      }
      oneX.Autocomplete.currentServiceInstance = null;
      if (oneX.Autocomplete.observer !== null){
        oneX.Autocomplete.observer.disconnect();
      }
      oneX.$('.pac-container.pac-logo[data-address-id]').remove();
    },

    addAutocomplete: function() {
      const _self = oneX.Autocomplete,
        $input = oneX.$(this);

      if (oneX.Util.isGoogleLoaded('Autocomplete', false) && !$input.hasClass("gm-err-autocomplete")) {
        if (($input.val().length < 4) && (oneX.Autocomplete.currentInstance !== null)) {
          _self.removeAutocomplete();
        }
        if (_self.minKeyStrokesMet($input) && oneX.Autocomplete.currentInstance === null) {
          _self.setGoogleAutocompleteToInput($input);
        }
      }
    },

    setGoogleAutocompleteToInput: function(element) {
      try {
        const _self = this,
          $input = oneX.Util.selectById(element.attr('id'));
        element.off("keydown", _self.keyDownHandler).on("keydown", _self.keyDownHandler);

        oneX.Autocomplete.currentInstance = new google.maps.places.Autocomplete($input[0]);
        //Listens for the places change event to update the fields.
        oneX.Autocomplete.currentInstance.input = element.parents(".-oneX-exploded-fields"); // Will throw an error if currentInstance is null. Checking currentInstance negates the need for a try/catch.
        oneX.Autocomplete.currentInstance.listener = google.maps.event.addListener(oneX.Autocomplete.currentInstance, 'place_changed', _self.explodeAutocompleteAddress);
        //Needed for the callback to Bind the Input to the current Google AutoComplete
        oneX.Autocomplete.currentServiceInstance = new google.maps.places.AutocompleteService();
        oneX.Autocomplete.currentServiceInstance.getQueryPredictions({
          input: $input
        }, _self.bindAutocomplete);
      } catch (err) {
        console.debug("Add Google Autocomplete Issue: " + err);
      }
    },
    
    keyDownHandler : function(event) {
      const prevVal = oneX.$(event.target).val();
      if (oneX.Autocomplete.currentInstance && oneX.$(event.target).val() !== 'undefined') {
        oneX.Autocomplete.currentInstance.prevValue = prevVal;
      }
      if (event.which == "9") {
        oneX.$(event.target).data("1x-tab-pressed", "true");
      } 
      else if (event.which == "13") {
        oneX.$(event.target).data("1x-selectionMade", "true");
        oneX.$(event.target).data("1x-tab-pressed", "false");

        setTimeout(function () {
          if (oneX.Autocomplete.currentInstance && oneX.$(event.target).val() === "undefined") {
            oneX.$(event.target).val(oneX.Autocomplete.currentInstance.prevValue);
          }
        },250);
      }
    },

    bindAutocomplete: function() {
      //Attaches the ID of the input box to the current Google AutoComplete
      const pacLogo = oneX.$('.pac-container.pac-logo:not([data-address-id])');

      pacLogo.attr('data-address-id', oneX.Autocomplete.currentInstance.input.find('.-oneX-exploded_Address1 input').attr("id")); // Will throw an error if currentInstance is null. Checking currentInstance negates the need for a try/catch.
      pacLogo.on('mousedown', oneX.Autocomplete.pacLogoMousedownHandler);

      oneX.Autocomplete.observer = new oneX.Autocomplete.MutationObserver(function(mutations) {
        const _self = oneX.Autocomplete,
          $pacContainer = oneX.$('.pac-container.pac-logo'),
          $address1 = oneX.Autocomplete.currentInstance.input.find(".-oneX-exploded_Address1 input"),
          listVisible = oneX.Autocomplete.currentInstance.input.data("1x-visible");
        
        if (($address1.val().length > 3) && $pacContainer.is(":visible") && (listVisible !== "true")) {
          _self.updateSuggestionMessage("suggestions");
          oneX.Autocomplete.currentInstance.input.data("1x-visible", "true");
          oneX.$(oneX.Autocomplete.currentInstance.input[0]).off("focusout.oneXExplodingFields");
        } 
        else if ($pacContainer.is(":not(:visible)") && listVisible === "true") {
          if ($address1.data("1x-selectionMade") != "true") {
            _self.updateSuggestionMessage("noSuggestions");
          }
          oneX.Autocomplete.currentInstance.input.data("1x-visible", "false");
          oneX.$(oneX.Autocomplete.currentInstance.input[0]).on("focusout.oneXExplodingFields", oneX.Explode.handleFocusOut);
        }
      });
      
      oneX.Autocomplete.observer.observe(document.querySelector('.pac-container.pac-logo'), {
        attributes: true
      });
    },
    
    pacLogoMousedownHandler : function() {
      oneX.Autocomplete.currentInstance.input.find('.-oneX-exploded_Address1 input').data("1x-selectionMade", "true"); // Will throw an error if currentInstance is null. Checking currentInstance negates the need for a try/catch.
    },

    getAddressLine1: function(addressComponents, originalInput="") {

      const _self = this,
        streetName = _self.getValue(addressComponents, 'route');
      let streetNumber = _self.getValue(addressComponents, 'street_number');

      if (streetNumber === "" && originalInput !== "") {
        // attempt to get it from the originalInput
        const firstWordOfStreetName = streetName.split(" ")[0],
          endOfStreetNum = originalInput.indexOf(firstWordOfStreetName);
        if (endOfStreetNum > 0) {
          streetNumber = originalInput.substring(0, endOfStreetNum - 1);
        }
      }
      return (streetNumber) ? (streetNumber + ' ' + streetName) : streetName;
    },

    getCity: function(addressComponents){
      const _self = this;
      let city = _self.getValue(addressComponents, 'locality');
      if (!city){
        city = _self.getValue(addressComponents, 'sublocality_level_1');
      } 
      return city;
    },

    getValue: function(addressComponents, type) {
      let resultType = '';

      if (typeof addressComponents !== "undefined") {
        const component = addressComponents.filter(function(component) {

          return (component.types[0] === type) ? true : false;
        })[0];

        resultType = component ? component.short_name : '';
      }

      return resultType;
    },
    
    currentInstance: null,
    
    currentServiceInstance: null,
    
    MutationObserver: window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver,
    
    observer: null
  };

  oneX.Config.queues(_COMPONENT);

})();
