(function () {
  oneX.Util.animations = {

    animateContainer: function ($container, property, beginVal, endVal, isShow, speed, callback) {
      $container.removeAttr("style");

      $container.attr("style", property + ": " + beginVal + ";");
      $container.removeClass('-oneX-hidden');
      const propString = '{"' + property + '": "' + endVal + '"}';
      const properties = JSON.parse(propString);

      $container.animate(
        properties,
        speed,
        "swing",
        function () {
          if (isShow === null) {
            //console.log(isShow, "not hidden nor style removed");
          }
          else {
            if (!isShow) {
              $container.addClass('-oneX-hidden');
              //console.log(isShow, "hidden");
            }
            $container.removeAttr("style");
            //console.log(isShow, "style removed");
          }
          if (typeof callback === 'function' ) {
            callback();
          }
        }
      );
    },

    displayToast: function (toastContainer, returnFocus, milliSeconds = 5000) {
      const $toastContainer = oneX.$(toastContainer),
        $returnFocus = oneX.$(returnFocus);
      let theTop = 81;

      if (window.matchMedia('(min-width: 768px)').matches) {
        theTop = 84;
      }
      if (window.matchMedia('(min-width: 992px)').matches) {
        theTop = 112;
      }
      if($toastContainer.attr("data-toast-id")){
        clearTimeout($toastContainer.attr("data-toast-id"));
      }
      milliSeconds = (milliSeconds < 4000) ? 4000 : (milliSeconds > 10000) ? 10000 : milliSeconds;

      oneX.Util.animations.animateContainer($toastContainer, 'top', '-200px', theTop + 'px', true, 500,
        function () {
          $toastContainer.find('.-oneX-toast__text').focus();
          $toastContainer.find('.-oneX-toast__text').on('keydown', function (event) {
            if (event.which === 9) {
              // tab or shift-tab will return to calling element
              $returnFocus.data('triggered', 'true');
              $returnFocus.focus();
            }
          })
        }
      );

      const id = setTimeout(function () {
        oneX.Util.animations.animateContainer($toastContainer, 'opacity', '1', 0, false, 500, function () {
          if (!($returnFocus.data('triggered') === 'true')) {
            $returnFocus.focus();
          } else {
            $returnFocus.data('triggered', 'false');
          }
        }
        );
        $toastContainer.removeAttr("data-toast-id")
      }, milliSeconds + 500);
      $toastContainer.attr("data-toast-id",id)
    },

    animationSpeed: 400

  };
})();
