(function() {
  const _COMPONENT = {
    name : 'Timeline',
    selectors : '.-oneX-timeline'
  };

  oneX.Timeline = {
    init : function() {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.debouncedResizeTimeline = oneX.Util.debounce.debounce(_self.resizeTimeline, 5);
        oneX.$(window).on("resize", _self.debouncedResizeTimeline);

        _element.on('click', 'button.-oneX-timeline-sort-control', _self.sortDate);

        oneX.Timeline.setTimeline(_element.find(".-oneX-timeline-sort-control"));

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;

      _element.off('click', 'button.-oneX-timeline-sort-control', _self.sortDate);
      oneX.$(window).off("resize", _self.debouncedResizeTimeline);


      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    setTimeline: function($sortButton) {
      const $timeline = $sortButton.closest(".-oneX-timeline"),
        $sortDesc = $timeline.find(".-oneX-timeline-sort-desc"),
        $orderedEvents = $timeline.find(".-oneX-active, .-oneX-past, .-oneX-today, .-oneX-future"),
        $firstEvent = $timeline.find(".-oneX-active, .-oneX-past, .-oneX-today, .-oneX-future").first(),
        $lastEvent = $timeline.find(".-oneX-active, .-oneX-past, .-oneX-today, .-oneX-future").last();
        
      $orderedEvents.addClass("-oneX-timeline__line");

      if ($sortButton.hasClass("-oneX-icon--chevron--up")) {
        $firstEvent.addClass("-oneX-timeline__line");
        $lastEvent.removeClass("-oneX-timeline__line");

        if (oneX.Util.locale.isSpanish()) {
          $sortButton.attr('aria-label', oneX.Timeline.esSortDesc);
          $sortDesc.text(oneX.Timeline.esSortByNew).siblings(".-oneX-clipped").text(oneX.Timeline.esTimelineSortedAsc);
        } else {
          $sortButton.attr('aria-label', oneX.Timeline.enSortDesc);
          $sortDesc.text(oneX.Timeline.enSortByNew).siblings(".-oneX-clipped").text(oneX.Timeline.enTimelineSortedAsc);
        }
      } else {
        let $firstEventDate = new Date($firstEvent.find('.-oneX-timeline-activity').text()).getTime(),
          $lastEventDate = new Date($lastEvent.find('.-oneX-timeline-activity').text()).getTime();

        if ($firstEventDate < $lastEventDate) {
          $firstEvent.removeClass("-oneX-timeline__line");
          $lastEvent.addClass("-oneX-timeline__line");
        } else {
          $lastEvent.removeClass("-oneX-timeline__line");
          $firstEvent.addClass("-oneX-timeline__line");
        }

        if (oneX.Util.locale.isSpanish()) {
          $sortButton.attr('aria-label', oneX.Timeline.esSortAsc);
          $sortDesc.text(oneX.Timeline.esSortByOld).siblings(".-oneX-clipped").text(oneX.Timeline.esTimelineSortedDesc);
        }
        else {
          $sortButton.attr('aria-label', oneX.Timeline.enSortAsc);
          $sortDesc.text(oneX.Timeline.enSortByOld).siblings(".-oneX-clipped").text(oneX.Timeline.enTimelineSortedDesc);
        }
      }

      if ($firstEvent.hasClass('-oneX-past') || $firstEvent.hasClass('-oneX-today') || $firstEvent.hasClass('-oneX-future') || 
      $lastEvent.hasClass('-oneX-past') || $lastEvent.hasClass('-oneX-today') || $lastEvent.hasClass('-oneX-future')) {
        $firstEvent.addClass("-oneX-timeline__line");
        $lastEvent.removeClass('-oneX-timeline__line');
      }
    },

    sortDate: function (event) {
      const $sortButton = oneX.$(event.target),
            $timeline = $sortButton.closest(".-oneX-timeline"),
            $timelineUl = $timeline.find("ul"),
            $timelineLi = $timelineUl.find("li");

      const $timelineLiOrder = $timelineLi.get().reverse();

      if($sortButton.hasClass("-oneX-icon--chevron--down")) {
        $sortButton.removeClass("-oneX-icon--chevron--down").addClass("-oneX-icon--chevron--up");
      }
      else {
        $sortButton.addClass("-oneX-icon--chevron--down").removeClass("-oneX-icon--chevron--up");
      }

      $sortButton.focus();
      $timelineUl.append($timelineLiOrder);
      oneX.Timeline.setTimeline($sortButton);
    },

    resizeTimeline: function (event) {
      const $timeline = oneX.$('.-oneX-timeline');

      if ($timeline.width() > 600) {
        $timeline.removeClass('-oneX-stack');
      } else {
        $timeline.addClass('-oneX-stack');
      }

    },

    enSortByOld : "Sorted by newest",
    esSortByOld : "Ordenado con los más recientes primero",
    enSortByNew : "Sorted by oldest",
    esSortByNew : "Ordenado con los más antiguos primero",
    enTimelineSortedDesc : "Timeline sorted by date: descending order",
    esTimelineSortedDesc : "Cronología ordenada por fecha: orden descendente",
    enTimelineSortedAsc : "Timeline sorted by date: ascending order",
    esTimelineSortedAsc : "Cronología ordenada por fecha: orden ascendente",
    enSortDesc : "sort timeline by descending",
    esSortDesc : "ordenar la cronología por descendente",
    enSortAsc : "sort timeline by ascending",
    esSortAsc : "ordenar la cronología por ascendente"
  };
  oneX.Config.queues(_COMPONENT);
})();
