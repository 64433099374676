(function() {
  oneX.Util.cookie = {

    returnAll : function() {
      const cookies = {};

      oneX.$.each(document.cookie.split(';'), function(index, value) {
        const arr = value.split('='), 
        key = arr[0].replace(/^\s+/, '').replace(/\s+$/, '');

        let cookieValue = null; 

        if (arr.length > 1) {
          cookieValue = arr.slice(1).join('');
        }

        cookies[key] = cookieValue;
      });

      return cookies;
    },

    get : function(name) {
      const list = this.returnAll();

      let cookieValue = null; 

      oneX.$.each(list, function(key, value) {
        if (key === name) {
          cookieValue = decodeURIComponent(value);
        }
      });

      return cookieValue;
    },

    set : function(name, value, time, domain) {
      
      const _self = this;

      //----------------Added the Variables and if-else condition for checking time is set to Session or not
      let time_in_seconds,
        expires_at,
        expiration_time;

      if(time !== 'Session') {
        const today = new Date(),
          offset = (time === null ||typeof time === 'undefined') ? (1000 * 60 * 60 * 24) : (time * 1000);
        
        time_in_seconds = (time === null ||typeof time === 'undefined') ? (60 * 60 * 24) : (time);
        expires_at = new Date(today.getTime() + offset);
        expiration_time = expires_at.toGMTString();
      } 
      else {
        time_in_seconds = time;
        expiration_time = time;
      }

      /*
       * In HTTP version 1.1, expires was deprecated and replaced with the easier-to-use max-age—instead
       * of having to specify a date, you can just say how long the cookie can live. By setting either
       * of these, the cookie will persist until its time runs out, otherwise—if you set neither—the
       * cookie will last until you close your browser (a “session cookie”).
       *
       * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
       *
       * Setting time_in_seconds to a string negates the value 'max-age' value from being set, and defaults to a session cookie.
       */

      if (time_in_seconds === 0) {
        time_in_seconds = 'Session';
        expiration_time = 'Session';
      }

      const cookies = _self.setCookieProperties(value, expiration_time, time_in_seconds, domain, name);

      document.cookie = cookies;
      return cookies;
    },
    
    getSFDomain : function (domain) {
      let top_domain = oneX.Util.environment.domain()[0]; //.com | .ca

      if ( top_domain.indexOf('.') === -1 ) {
        top_domain = '.com';
      }

      return (typeof domain !== 'undefined') ? domain : '.statefarm' + top_domain;
    },
    
    setCookieProperties : function (value, expiration_time, time_in_seconds, domain, name) {
      const _self = this;
      
      return oneX.$.map({
        name : encodeURIComponent(value),
        expires : expiration_time,// removed expires_at.toGMTString() and replaced with expiration_time
        'max-age' : time_in_seconds,
        path : '/',
        domain :  _self.getSFDomain(domain) + ";secure;SameSite=Lax"     
      }, 
      function(cookieValue, key) {
        return [ (key === 'name') ? name : key, cookieValue ].join('=');
      }).join(';');  
    },

    destroy : function(name, domain) {
      return this.set(name, null, -1000000, domain);
    }
  };
})();
