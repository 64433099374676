(function() {
  oneX.Util.location = {
    hostname : function() { // Returns the hostname of a URL
      return location.hostname.toLowerCase();
    },
    search : function() { // Returns the querystring part of a URL
      return location.search.toString();
    },
    protocol : function() { // Returns the protocol of a URL
      return location.protocol;
    },
    port : function() { // Returns the port number of a URL
      return location.port;
    },
    pathname : function() { // Returns the path name of a URL
      return location.pathname;
    },
    origin : function() { // Returns the protocol, hostname and port number of
      // a URL
      return location.origin;
    },
    href : function() { // Returns the entire URL
      return location.href;
    },
    host : function() { // Returns the hostname and port number of a URL
      return location.host;
    },
    hash : function() { // Returns the anchor part (#) of a URL
      return location.hash;
    },
    getVersion : function() { // Returns alpha, beta or prod
      const path = oneX.Util.location.pathname().match(/onex-testharness-([a-z]*)/);
      let version = "prod";

      if (path && path[1]) {
        version = path[1];
      }

      if (version === "master") {
        version = "prod";
      }
      
      return version;
    }
  };
})();
