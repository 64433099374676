(function() {
  oneX.initialized = false;
  oneX.Init = {
    init: function() {
      if (oneX.initialized === false) {
        oneX.INIT_QUEUE.forEach(function(element) {
          element.init(oneX.Init.doneWithInitialization);
        });
        oneX.initialized = true;
       
      }
      
      return this;
    },

    setupCallback: function(elementId, callBackFunct){
      oneX.Init.callbacks [elementId] = callBackFunct;
    },

    doneWithInitialization: function($element){
      if ($element && $element.attr("id")) {
        const callback = oneX.Init.callbacks[$element.attr("id")]
        if (callback && typeof(callback) === "function"){
          callback($element);
        }
      }
    },

    callbacks: []
  };
})();
