(function () {
  const _COMPONENT = {
    name : 'GlobalAlert',
    selectors : '.-oneX-global-alert-container'
  };

    oneX.GlobalAlert = {
      init: function() {
        const _self = this;

        oneX.$(_COMPONENT.selectors).each(function() {
          _self.bindEvents(oneX.$(this));
        });

        return this; 
      },

      addElement : function(_element) {
        this.bindEvents(_element);
      },
  
      removeElement : function(_element) {
        this.unbindEvents(_element);
      },
  
      bindEvents : function(_element) {
        const _self = this;
        
        if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

          const $closeBtn = _element.find('button.-oneX-close-X-button');
          $closeBtn.on("click",_self.hideAlert);

          $closeBtn.on('keydown', _self.closeButtonKeyDown);

          _element.data(_COMPONENT.name, _COMPONENT.name);
        }
      },
  
      unbindEvents : function(_element) {
        const _self = this;

        const $closeBtn = _element.find('button.-oneX-close-X-button');
        $closeBtn.off("click",_self.hideAlert);

        $closeBtn.off('keydown', _self.closeButtonKeyDown);
        
        _element.removeData(_COMPONENT.name, _COMPONENT.name);
      }, 

      hideAlert: function (event) {
        oneX.$(event.target).closest('.-oneX-global-alert-container').addClass("-oneX-hidden");
      },

      closeButtonKeyDown : function(event) {
        if (event.which === 32) {
          oneX.GlobalAlert.hideAlert(event);
        }
      },
    };

    oneX.Config.queues(_COMPONENT);

  })();
