(function () {
  const _COMPONENT = {
    name: 'ProgressIndicatorLabels',
    selectors: '[data-type="progress-indicator"], .-oneX-progress--labels'
  };

  oneX.ProgressIndicatorLabels = {
    init: function (callback) {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this), callback);
      }); 
           
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element, callback) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element, callback);
        }
        else {
          _self.buttonText(_element);
          _self.incrementProgressBar(_element);
          _self.progressIndicatorA11y(_element);

          if (_self.throttledScreenResize === null) {
            // set this up only once; resize will handle all on the page
            _self.throttledScreenResize = oneX.Util.throttle.throttle(_self.screenResize, 5, true);
            oneX.$(window).on("resize", _self.throttledScreenResize);
            oneX.ProgressIndicatorLabels.prevWidth = window.innerWidth;
          }
          _self.setState(_element);

        }
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $progressMenuButtonDropdown = _element.find('button');

      $progressMenuButtonDropdown.off('click', oneX.ProgressIndicatorLabels.toggleProgressMenu);
      oneX.$(window).off("resize", _self.throttledScreenResize);
      _element.find('span.-oneX-clipped[data-onex-clipped="true"]').remove();

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    renderHTML: function (_element, callback) {
      const _self = this,
        labelData = _element.data("labels"),
        oversize = _element.data("oversize");

      _element.load(`${oneX.Util.environment.getRenderedHtmlOrigin('component')}/1x-progress-indicator.html`, function (response, status) {        
        if (status === "success") {
          for (let label of (typeof(labelData) === "object" ? labelData : JSON.parse(labelData)).labels) {
            const status = label.status === undefined ? '' : `${label.status}`,
              url = (status === 'completed' || status === 'visited') && label.url;

            _element.find('ul').append(`<li class="-oneX-${status}"><div>${label.title}</div></li>`);

            url && _element.find('li:last-of-type div').wrap(`<a href="${url}"></a>`);
          }

          if (oversize) {
            _element.find('.-oneX-progress--labels').addClass('-oneX-progress--oversize');
          }
          
          _element.children(':first').unwrap();

          oneX.ProgressIndicatorLabels.init();
          
          if (callback && typeof (callback) === "function") {
            callback();
          }
        }    
        else {
          console.debug(`Error with loading progress-indicator ${response}`);
        }
      });
    },

    updateA11yData: function (_element) {

      if (typeof _element !== "object") {
        return;
      }

      oneX.$(_element).each(function () {
        const $element = oneX.$(this);

        if ($element.hasClass('-oneX-progress--labels')) {
          $element.find('span.-oneX-clipped[data-onex-clipped="true"]').remove();
          $element.find('button').text($element.find('li.-oneX-current div').text());
          oneX.ProgressIndicatorLabels.incrementProgressBar($element);
          oneX.ProgressIndicatorLabels.progressIndicatorA11y($element);
          $element.find('button').append(`<span data-onex-clipped="true" class="-oneX-clipped">${$element.find('li.-oneX-current div span').html().trim()}</span>`);
        }
      });

    },

    progressIndicatorA11y: function (_element) {
      const labels = _element.find('li'),
        totalSteps = labels.length;
      let stepNumber = 1;

      for (let label of labels) {
        const steps = (`step ${stepNumber}  of ${totalSteps}`),
          status = oneX.$(label).attr('class').split("-oneX-")[1],
          labelContent = `<span data-onex-clipped="true" class="-oneX-clipped">${steps} ${status} </span>`;
        oneX.$(label).find('div').prepend(labelContent);
        stepNumber++;
      }
    },

    buttonText: function (_element) {
      const $progressMenuButtonDropdown = _element.find('button'),
        currentItem = _element.find('li.-oneX-current'),
        currentText = currentItem.text(),
        currentStep = currentItem.index() + 1,
        liLength = _element.find('li').length,
        status = currentItem.attr('class').split("-oneX-")[1],
        steps = (`step ${currentStep} of ${liLength}`);

      $progressMenuButtonDropdown.text(`${currentText}`).on('click', oneX.ProgressIndicatorLabels.toggleProgressMenu);
      $progressMenuButtonDropdown.append(`<span data-onex-clipped="true" class="-oneX-clipped">${steps} ${status}</span>`);
    },

    setState($progressIndicator) {
      const $progressMenuButtonDropdown = $progressIndicator.find('button'),
        $currentList = $progressIndicator.find('ul'),
        $mobileViewTracker = $progressIndicator.find('.-oneX-progress-indicator-tracker'),
        oversize = $progressIndicator.hasClass('-oneX-progress--oversize');

      if (window.innerWidth < 992 || oversize) {
        $progressMenuButtonDropdown.removeClass('-oneX-hidden').attr('aria-expanded', false);
        $mobileViewTracker.removeClass('-oneX-hidden');
        $currentList.addClass('-oneX-hidden');
      }
      else {
        $progressMenuButtonDropdown.addClass('-oneX-hidden');
        $mobileViewTracker.addClass('-oneX-hidden');
        $currentList.removeClass('-oneX-hidden');
      }
    },

    screenResize() {

      if ((oneX.ProgressIndicatorLabels.prevWidth > 991 && window.innerWidth < 992) || (oneX.ProgressIndicatorLabels.prevWidth < 992 && window.innerWidth > 991)) {

        oneX.$('.-oneX-progress--labels').each(function() {
          if (!oneX.$(this).hasClass('-oneX-progress--oversize')) {
            oneX.ProgressIndicatorLabels.setState(oneX.$(this));
          }
        }); 
      }

      oneX.ProgressIndicatorLabels.prevWidth = window.innerWidth;
    },

    toggleProgressMenu: function (event) {
      const $progressMenuButtonDropdown = oneX.$(event.target),
        $progressIndicator = $progressMenuButtonDropdown.closest('.-oneX-progress--labels'),
        $mobileViewTracker = $progressIndicator.find('.-oneX-progress-indicator-tracker'),
        $currentList = $progressMenuButtonDropdown.next('ul'),
        oversize = $progressIndicator.hasClass('-oneX-progress--oversize');;

      // if (window.matchMedia('(max-width: 991px)').matches || oversize) {
        if ($progressMenuButtonDropdown.attr('aria-expanded') === "true") {
          $progressMenuButtonDropdown.attr('aria-expanded', false);
          $mobileViewTracker.removeClass('-oneX-hidden');
          $currentList.addClass('-oneX-hidden');
        }
        else {
          $progressMenuButtonDropdown.attr('aria-expanded', true);
          $mobileViewTracker.addClass('-oneX-hidden');
          $currentList.removeClass('-oneX-hidden');
        }
      // }
    },

    incrementProgressBar: function (_element) {
      const $progressBar = _element.find('.-oneX-progress-indicator-marker'),
        currentStep = _element.find('ul').children('.-oneX-current').index() + 1,
        totalSteps = _element.find('ul').children().length,
        barWidth = (currentStep / totalSteps) * 100;

      $progressBar.width(`${barWidth}%`);
    },

    prevWidth: null,
    throttledScreenResize: null
  };

  oneX.Config.queues(_COMPONENT);
})();
