(function () {
  const _COMPONENT = {
    name: 'ProgressiveLoader',
    selectors: '.-oneX-progressive-loader-container'
  };

  oneX.ProgressiveLoader = {
    init : function() {
      const _self = this;
      
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const value = _element.data("percent");

        _self.initialPercent(_element, value);

        _element.data(_COMPONENT.name, _COMPONENT.name);

      }
    },

    unbindEvents : function(_element) {
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    initialPercent: function(_element, value) {
      if (Number.isInteger(value) && (value >= 0 && value <= 100)) {
        _element[0].dataset.percent = value.toString();
        _element[0].getElementsByClassName('-oneX-progressive-loader-inner-percentage')[0].innerHTML = value + '%';
        _element[0].getElementsByClassName('-oneX-progressive-loader-circle')[0].style.backgroundImage = 'conic-gradient(' + oneX.ProgressiveLoader.red + ' ' + value + '%, #FFF 0)';
      }
    },

    updatePercent: function(_element, newValue) {
      let oldValue = _element[0].dataset.percent;
      
      oldValue = parseInt(oldValue);

      if (Number.isInteger(newValue) && (newValue >= 0 && newValue <= 100)) {
        _element[0].dataset.percent = newValue.toString();

        const interval = setInterval(function () {
          if (oldValue == newValue) {
            clearInterval(interval);
          } else if (newValue > oldValue) {
            oldValue += 1;
            _element[0].getElementsByClassName('-oneX-progressive-loader-inner-percentage')[0].innerHTML = oldValue + '%';
            _element[0].getElementsByClassName('-oneX-progressive-loader-circle')[0].style.backgroundImage = 'conic-gradient(' + oneX.ProgressiveLoader.red + ' ' + oldValue + '%, #FFF 0)';
          } else {
            oldValue -= 1;
            _element[0].getElementsByClassName('-oneX-progressive-loader-inner-percentage')[0].innerHTML = oldValue + '%';
            _element[0].getElementsByClassName('-oneX-progressive-loader-circle')[0].style.backgroundImage = 'conic-gradient(' + oneX.ProgressiveLoader.red + ' ' + oldValue + '%, #FFF 0)';
          }
        }, 20);
      }
    },

    red: '#d62311'  // $primary-merna-03-digital

  };

  oneX.Config.queues(_COMPONENT);
})();
