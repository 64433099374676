(function() {
  const _COMPONENT = {
    name : 'Explode',
    selectors : ".-oneX-exploding-fields, [data-type^='1x-exploding-field']"
  };
  oneX.Explode = {
    init : function(callback) {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this), callback);
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element, callback) {    
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        if (_element.data("type") && _element.is(":empty")) { 
          oneX.Explode.renderHTML(_element, callback);
        }
        else {
          _element.find('.-oneX-explode').on('focus', _self.handleFocus);
          oneX.Explode.updateFullName(_element.find('.-oneX-exploded-fields input:first'));

          oneX.$("button").on("mousedown", _self.mouseClickAid);
        }
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;
      
      _element.find('.-oneX-explode').off('focus', _self.handleFocus);
      oneX.$("button").off('mousedown', _self.mouseClickAid);
      oneX.$("button").off('click', _self.buttonClickHandler);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    renderHTML: function (_element, callback) {
      const htmlFile = (_element.data("type").indexOf('address') !== -1) ? "/1x-address.html" : "/1x-name.html",
        locale = (oneX.Util.locale.isSpanish()) ? "/es_US" : "/en_US",
        staticLocale = oneX.Util.environment.getStaticHostName().replace(/(:?)([\/\\]+)/g, function(_,d,s){ return d ? d+s : '/' }) + locale,
        port = oneX.Util.location.port(),
        isLocalTestHarness = port === "4200" && /onex-testharness/ig.test(oneX.Util.location.pathname()),
        segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src'),
        baseId = _element.data("base_id")?_element.data("base_id"):"",
        label = _element.data("label")?_element.data("label"):"",
        lines = _element.data("lines")?_element.data("lines"):2,
        classes = _element.attr("class");

      let localPath = "http://localhost:3000";

      if (segmentSection !== undefined && segmentSection !== null){
        const colon3000 = segmentSection.indexOf(':3000');
        if (colon3000 > -1) {
          localPath = segmentSection.substr(0, colon3000 + 5);
        }
      }

      const htmlPath = (isLocalTestHarness) ? localPath + "/html/component" + locale : staticLocale + "/dxl-1x/" + oneX.Util.location.getVersion() + "/renders/component"

      _element.load(htmlPath + htmlFile, function (response, status) {
        if (status === "success") {
          if (baseId !== ""){
            _element.find("[id]").each(function(){
              const currentId = oneX.$(this).attr("id"),
                regEx = new RegExp(currentId,"g");
              _element.html(_element.html().replace(regEx, baseId + "-" + currentId));
            });
          }
          if (label !== ""){
            _element.find("label").each(function(){
              const currLabel = oneX.$(this).html(),
                regEx = new RegExp(currLabel.replace(/\*/,"\\" + "\*"),"g");
                if (["Address", "Address line 1", "Dirección", "Dirección línea 1"].includes(currLabel)) {
                  oneX.$(this).html(oneX.$(this).html().replace(regEx, label + " " + currLabel));
                }
            });
          }          

          if (lines === 2) {
            _element.find('textarea').attr('rows', '2');
            _element.find('.-oneX-exploded_Address2 input').attr('data-1x-newLine', 'true');
            _element.find('.-oneX-exploded_City input').removeAttr('data-1x-separate');
          }

          const $firstChild = _element.children(':first')
          if (classes != null && classes != ""){
            $firstChild.attr("class", $firstChild.attr("class") + " " + classes)
          } 
          $firstChild.unwrap();
          

          oneX.Explode.init();
          oneX.FloatingLabel.init(); 
          oneX.AutoFormat.init();  
          oneX.Autocomplete.init();
          oneX.Textfield.init();
          if (callback && typeof(callback) === "function"){
            callback(_element);
          }
        } else {
          console.debug("Error with loading the html component " + response);
        }
      });
    },

    mouseClickAid : function(event) {
      oneX.$(this).addClass("-onex-button-click");

      oneX.$("button").not(".-oneX-buttonClickHandler").addClass("-oneX-buttonClickHandler").on("click", oneX.Explode.buttonClickHandler); 
    },
    
    buttonClickHandler : function(event) {
      oneX.$(this).removeClass("-onex-button-click");
      oneX.Explode.handleFocusOut(event);
    },

    handleFocus : function(event) {
      const $focussedField = oneX.$(this),
        focussedFieldId = $focussedField.attr('id');
      
      const $explodedFieldsGroup =  oneX.$('[data-exploded-trigger="' + focussedFieldId + '"]');
      $focussedField.hide();
      oneX.$('label[for="' + focussedFieldId + '"]').hide();

      if (oneX.$('body').hasClass('-oneX-user-back-tabbing')) {
        $explodedFieldsGroup.show().find(':input:last').focus();
      }
      else {
        $explodedFieldsGroup.show().find(':input:first').focus();
      }
      $explodedFieldsGroup.on('focusout.oneXExplodingFields', oneX.Explode.handleFocusOut);
      $explodedFieldsGroup.on('focusin.oneXExplodingFields', oneX.Explode.clearCollapse);
      oneX.$(window).resize();
    },

    clearCollapse : function() {
      clearTimeout(oneX.Explode.delayedCollapse);
    },

    handleFocusOut : function(event) {
      oneX.Explode.updateFullName(event.target);
      oneX.Explode.delayedCollapse = oneX.Explode.resetExplodedInput(oneX.$(this));

      return true;
    },

    resetExplodedInput : function(parent) {
      setTimeout(function () {
        if ((!oneX.$.contains(parent[0], document.activeElement)) && !oneX.$(parent[0]).is(oneX.$(document.activeElement)) && oneX.$(".-onex-button-click").length === 0) {
          const $triggerField = oneX.Util.selectById(parent.attr('data-exploded-trigger'));
          if (parent.find(".-oneX-widget--err").length === 0) {
            $triggerField.show().blur();
            oneX.$('label[for="' + $triggerField.attr('id') + '"]').show();
            parent.hide();
          }
          oneX.$(this).off('focusout.oneXExplodingFields', oneX.Explode.handleFocusOut);
          oneX.$(this).off('focusin.oneXExplodingFields', oneX.Explode.clearCollapse);
        }
      }, 150);
    },

    updateFullName : function(field) {
      const fullValue = [], parent = oneX.$(field).parents('div[data-exploded-trigger]');

      parent.find(':input').each(function() {
        const $this = oneX.$(this);
        if (oneX.Autocomplete.currentInstance && $this.val() === "undefined") {
          $this.val(oneX.Autocomplete.currentInstance.prevValue);
        }
        const val = oneX.AutoFormat.adjustForEmptyValue($this);

        $this.change();

        if (fullValue != 0) {
          if (($this.parent().is(".-oneX-exploded_Address2") && (val != 0)) || (!$this.parent().is(".-oneX-exploded_Address2") && ($this.is('[data-1x-separate]')))) {
            fullValue.push(", ");
          }
        }

        if ($this.is("input")) {
          if (val) {
            fullValue.push(val);
          }
        }
        else {
          const select = $this.find('option:selected');
          
          if (select.val()) {
            fullValue.push(select.text());
          }
        }

        if ($this.is('[data-1x-newLine]')) {
          fullValue.push('\n');
        }

        if ($this.is('[data-1x-space]') && val != "") {
          fullValue.push(' ');
        }
      });

      oneX.Util.selectById(parent.data('explodedTrigger')).val(fullValue.join('').trim()).change();
    },

    delayedCollapse: null
  };

  oneX.Config.queues(_COMPONENT);
})();
