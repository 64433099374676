/* eslint-disable complexity */
(function () {
  const _COMPONENT = {
    name: 'Header',
    selectors: '[data-type^="1x-header"], #oneX-header'
  };

  oneX.Header = {
    init: function (callback) {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors), callback);
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element, callback) {

      if (_element.length > 0 && _element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const _self = this;

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element, callback);
        }
        else {
          _self.debouncedResetMenusOnResize = oneX.Util.debounce.debounce(_self.resetMenusOnResize, 250);

          oneX.$(document).on('keydown', function(event) { if (event.key == "Escape") {oneX.Header.scrimClick(event);}});
          oneX.$(window).on("resize", _self.debouncedResetMenusOnResize);

          oneX.$('.-oneX-skipContent-link').on("focusin", _self.focusSkipToMainContent);
          oneX.$('.-oneX-skipContent-link').on('focusout', _self.hideSkipToMainContent);
          oneX.$(".-oneX-skipToMain-content a").on('click', _self.clickSkipToMainContent);

          oneX.$('.-oneX-header-top-menu-btn-hitbox').on('pointerdown keydown', _self._toggleMegaMenu);
          // Mobile touchscreen event & double tapping on mobile for voiceover
          oneX.$('.-oneX-header-top-menu-btn-hitbox').on('dblclick', _self.clickToggleMenuContainer);
          // On hover open mega menu 
          oneX.$('.-oneX-header-top-menu-btn-hitbox').on('mouseenter',_self.openMenuContainer);
          // After cursor leaves mega menu panel or title close mega menu
          oneX.$('.-oneX-header-main-nav-list').on('mouseleave', _self.closeMenuContainer);

          oneX.$('.-oneX-header-top-menu-btn-hitbox').on('touchstart touchcancel', _self.setDraggingOff);
          oneX.$('.-oneX-header-top-menu-btn-hitbox').on('touchmove', _self.setDraggingOn);
          // oneX.$('.-oneX-link--block menuLevel2 ').on('focusin', _self.openMenuContainer);
          oneX.$('.-oneX-header-util-menu-btn').filter('button').on('click touchend', _self.toggleUtilContainer);
          oneX.$('.-oneX-header-util-menu-btn').filter('button').on('touchstart touchcancel', _self.setDraggingOff);
          oneX.$('.-oneX-header-util-menu-btn').filter('button').on('touchmove', _self.setDraggingOn);
          oneX.$('.-oneX-header-menu-button').on('click', _self.toggleHamburgerMenu);
          oneX.$('#navMenuBack').on('click touchend', _self.backClick);
          oneX.$('#navMenuBack').on('touchstart touchcancel', _self.setDraggingOff);
          oneX.$('#navMenuBack').on('touchmove', _self.setDraggingOn);
          oneX.$(".-oneX-menuLevel1-mobile").parent().on('click touchend', _self.subMenuShow);
          oneX.$(".-oneX-menuLevel1-mobile").parent().on('touchstart touchcancel', _self.setDraggingOff);
          oneX.$(".-oneX-menuLevel1-mobile").parent().on('touchmove', _self.setDraggingOn);
          oneX.$(".-oneX-header-main-nav-menu").hover(_self.mainNavHover);
          oneX.$('.-oneX-header-util-nav ul').hover(_self.utilNavHover);
          
          oneX.$(".-oneX-header-menu-button").on("keydown", _self.hamburgerIntoMenu);
          oneX.$('.-oneX-header-nav-apps-container').on("keydown", _self.handleKeystroke);
          oneX.$(".-oneX-header-user-nav-button").on("click", _self.toggleUserSubmenu);
          oneX.$(".-oneX-header-user-nav-button").on("keydown", _self.userSubmenuKeyHandler);
          oneX.$(".-oneX-submenu-usermenu-link").on("keydown", _self.userSubmenuKeyHandler);

          oneX.$('.-oneX-header-submenu-container .-oneX-header-close-X-button').on("click", _self.scrimClick);
          oneX.$('.-oneX-header-submenu-container .-oneX-header-close-X-button').on("keydown", _self.scrimClick);
          oneX.$('.-oneX-utilmenu-search .-oneX-header-close-X-button').on("click", _self.toggleUtilContainer);

          if (oneX.Util.locale.isSpanish()) {
            oneX.$('#oneX-header').addClass('-oneX-header-es');
          }

          _self.resetMenusOnResize();
          _self.moveHiddenLanguageLink();
          _self.checkMobileDevice();

          oneX.Header.prevWidth = oneX.$('body').width();
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {

      const _self = this;

      oneX.$(document).off('keydown', function(event) { if (event.key == "Escape") {oneX.Header.scrimClick(event);}});
      oneX.$(window).off("resize", _self.debouncedResetMenusOnResize);

      oneX.$('.-oneX-skipContent-link').off('focusin', _self.focusSkipToMainContent);
      oneX.$('.-oneX-skipContent-link').off('focusout', _self.hideSkipToMainContent);
      oneX.$(".-oneX-skipToMain-content a").off('click', _self.clickSkipToMainContent);
      oneX.$('.-oneX-header-top-menu-btn-hitbox').off('pointerdown keydown', _self._toggleMegaMenu);
      oneX.$('.-oneX-header-top-menu-btn-hitbox').off('dblclick', _self.clickToggleMenuContainer);
      oneX.$('.-oneX-header-top-menu-btn-hitbox').off('touchstart touchcancel', _self.setDraggingOff);
      oneX.$('.-oneX-header-top-menu-btn-hitbox').off('touchmove', _self.setDraggingOn);
      oneX.$('.-oneX-header-top-menu-btn-hitbox').off('mouseenter',_self.openMenuContainer);
      // oneX.$('.-oneX-link--block menuLevel2 ').off('focusin', _self.openMenuContainer);
      oneX.$('.-oneX-header-main-nav-list').off('mouseleave',_self.closeMenuContainer);
      oneX.$('.-oneX-header-util-menu-btn').filter('button').off('click touchend', _self.toggleUtilContainer);
      oneX.$('.-oneX-header-util-menu-btn').filter('button').off('touchstart touchcancel', _self.setDraggingOff);
      oneX.$('.-oneX-header-util-menu-btn').filter('button').off('touchmove', _self.setDraggingOn);
      oneX.$('.-oneX-header-menu-button').off('click', _self.toggleHamburgerMenu);
      oneX.$('#navMenuBack').off('click touchend', _self.backClick);
      oneX.$('#navMenuBack').off('touchstart touchcancel', _self.setDraggingOff);
      oneX.$('#navMenuBack').off('touchmove', _self.setDraggingOn);
      oneX.$(".-oneX-menuLevel1-mobile").parent().off('click touchend', _self.subMenuShow);
      oneX.$(".-oneX-menuLevel1-mobile").parent().off('touchstart touchcancel', _self.setDraggingOff);
      oneX.$(".-oneX-menuLevel1-mobile").parent().off('touchmove', _self.setDraggingOn);
      oneX.$(".-oneX-header-main-nav-menu").off('mouseenter mouseleave', _self.mainNavHover);
      oneX.$('.-oneX-header-util-nav ul').off ('mouseenter mouseleave', _self.utilNavHover);

      oneX.$(".-oneX-header-menu-button").off("keydown", _self.hamburgerIntoMenu);
      oneX.$('.-oneX-header-nav-apps-container').off("keydown", _self.handleKeystroke);
      oneX.$(".-oneX-header-user-nav-button").off("click", _self.toggleUserSubmenu);
      oneX.$(".-oneX-header-user-nav-button").off("keydown", _self.userSubmenuKeyHandler);
      oneX.$(".-oneX-submenu-usermenu-link").off("keydown", _self.userSubmenuKeyHandler);

      oneX.$('.-oneX-header-submenu-container .-oneX-header-close-X-button').off("click", _self.scrimClick);
      oneX.$('.-oneX-utilmenu-search .-oneX-header-close-X-button').off("click", _self.toggleUtilContainer);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    checkMobileDevice: function (event) {

      if (window.matchMedia("(pointer:coarse)").matches) {
        oneX.Header.mobileDevice = true;
        oneX.$('.-oneX-navmenu-anchor-has-submenu').addClass('-oneX-disable-header-link');
      } else {
        oneX.Header.mobileDevice = false;
      }

    },

    handleKeystroke: function (event) {
      if (
        event.key === 'Tab' &&
        oneX.$("[data-session-timeout]:visible").length === 0
      ) {
        if (oneX.$(".-oneX-header-menu-button").hasClass("-oneX-button--selected")) {
          const $tabbableNode = oneX.$(".-oneX-header-nav-apps-container").find(oneX.Util.componentWithScrim.tabbable)
            
          if (event.shiftKey) {
            if ($tabbableNode.filter(":visible:first").is(oneX.$(event.target))) {
              event.preventDefault();
              oneX.$(".-oneX-header-menu-button").focus();
            }
          } else if ($tabbableNode.filter(":visible:last").is(oneX.$(event.target))) {
            event.preventDefault();
            oneX.$(".-oneX-header-menu-button").focus();
          }
        }
      }


      // On mobile/tablet, if menu item has chevron right for submenu
      if (event.key === ' ' && oneX.Header.mobileDevice) {
        const $target = oneX.$(event.target)
        const $mobileMenuChevronRight = $target.siblings('span.-oneX-sub-navigation-menu__chevron-right-mobile')

        // target has mobile chevron right
        if ($mobileMenuChevronRight.length !== 0) {
          event.preventDefault();
          // simulate pressing enter
          $target.parent().trigger(oneX.$.Event('click'));

          // target is a link with no submenu
        } else if ($target.attr('href')) {
          event.preventDefault();
          $target[0].click();

        }
      }
    },

    hamburgerIntoMenu: function (event) {
      if (event.key === 'Tab' && oneX.$('[data-session-timeout]:visible').length === 0 && oneX.$(".-oneX-header-menu-button").hasClass('-oneX-button--selected')) {
        event.preventDefault();

        const $tabbableNode = oneX.$('.-oneX-header-nav-apps-container').find(oneX.Util.componentWithScrim.tabbable)

        if (event.shiftKey) {
          $tabbableNode.filter(':visible:last').focus();
        } else {
          $tabbableNode.filter(':visible:first').focus();
        }

      }
    },

    renderHTML: function (_element, callback) {
      const limited = (_element.data("limited") === true) ? "-limited" : "",
        generic = (_element.data("generic") === true) ? "-generic" : "",
        loginDestinationUrl = _element.data("login-destination-url"),
        icons = (_element.data("icons") === true) ? true : false,
        hideLogin = _element.data("hide-login"),
        cancelUrl = _element.data("cancel-url"),
        hideSecureMessages =  _element.data("hide-messages"),
        hideSecuritySettings = _element.data('hide-security-settings'),
        hideDocumentCenter =  _element.data("hide-document-center"),
        hidePreferences =  _element.data("hide-preferences"),
        hideMainNavigationLinks = _element.data('hide-main-links'),
        breadcrumbsJSON = (_element.data("breadcrumbsjson")),

        isAuth = (_element.data("type").indexOf('-auth') !== -1),
        headerHTML = isAuth ? "/navigation" + generic + "-header-auth" + limited + ".html" : "/navigation" + generic + "-header-unauth" + limited + ".html",
        headerLocale = (oneX.Util.locale.isSpanish()) ? "/es_US" : "/en_US",
        // eslint-disable-next-line no-useless-escape
        headerStaticLocale = oneX.Util.environment.getStaticHostName().replace(/(:?)([\/\\]+)/g, function(_,d,s){ return d ? d+s : '/' }) + headerLocale,
        port = oneX.Util.location.port(),
        languageURL = _element.data("language-url"),
        isLocalTestHarness = ((port === "4200" || port === "3001") && /onex-testharness/ig.test(oneX.Util.location.pathname())) || 
        (port === "8888" && oneX.$('script[src*="test-footer.js"]').attr('src') === "/test-footer.js" ) || 
        ((port === "4200" || port === "4201") && oneX.$('head title').html() === "1x Test Harness Angular"),
        segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src');

      let localPath = "http://localhost:3000";

      if (segmentSection !== undefined && segmentSection !== null){
        const colon3000 = segmentSection.indexOf(':3000');
        if (colon3000 > -1) {
          localPath = segmentSection.substr(0, colon3000 + 5);
        }
      }

      const headerPath = (isLocalTestHarness) ? localPath + "/html/header" + headerLocale : headerStaticLocale + "/dxl-1x/" + oneX.Util.location.getVersion() + "/renders/header"

      _element.load(headerPath + headerHTML, function (response, status) {
        if (status === "success") {

          if (isLocalTestHarness) {
            _element.html(
              _element.html()
                .replace(/{{domain_root}}/g, headerStaticLocale)
                .replace(/{{www_root}}/g, oneX.Util.location.hostname())
                .replace(/dxl-1x\/prod/g, `dxl-1x/${oneX.Util.location.getVersion()}`)
            );

            if (icons) {
              _element.html(
                _element.html()
                  // eslint-disable-next-line no-useless-escape
                  .replace(/https\:\/\/static1-env1.test.st8fm.com\/e[n,s]_US\/dxl-1x\/prod\//g, "http://localhost:3000/")
              );
            }
          }

          // if this div is there, update so it shows in the TH what "version" of 1x is running on an IOS device when testing
          oneX.$('#-oneX-build').html('header.js:232 manual timestamp 2024-07-30 11:02');  // Only need to update this when trying to be sure to test a specific version of 1x
          
          if (icons && headerStaticLocale.match(/.test./) !== null) {
            _element.html(
              _element.html()
                .replace(/dxl-1x\/prod/g, `dxl-1x/${oneX.Util.location.getVersion()}`)
            );
          }

          if (hideLogin && _element.data("limited") === true && _element.data("type").indexOf('unauth') !== -1) {
            _element.find('.-oneX-header-login-nav .-oneX-header-top-menu-btn-hitbox').addClass('-oneX-hidden');
            _element.find('.-oneX-header-nav-login-button').addClass('-oneX-hidden');
            _element.find('.-oneX-header-login-nav a').addClass('-oneX-hidden');
            _element.find('.-oneX-header-login-nav a').removeClass('-oneX-header-top-menu-btn');

          }

          if (languageURL && languageURL !== ""){
            oneX.Header.setLanguageLink(languageURL);
          }
  
          if (_element.find('.-oneX-header-login-nav a').attr('href') && !isAuth) {
            let href = _element.find('.-oneX-header-login-nav a').attr('href').concat('?');

            if(loginDestinationUrl) {
              href += `goto=${loginDestinationUrl}&`;
            }

            if(cancelUrl) {
              href += `cancel=${cancelUrl}`;
            }

            _element.find('.-oneX-header-login-nav a').attr('href', href);
          }

          const $userMenuList = oneX.$("header").find('.-oneX-submenu-usermenu ul'),
            $mainNavigationButtonsB = oneX.$('header').find('.-oneX-header-main-nav-menu .-oneX-header-top-menu-btn').filter(':not(:first)'),
            $insuranceMenu = oneX.$('header').find('.-oneX-header-main-nav-menu .-oneX-header-top-menu-btn[data-for="insurance-new"]');
            //$mainNavigationButtonsB = oneX.$('header').find('.-oneX-header-main-nav-menu .-oneX-header-top-menu-btn:not(.-oneX-header-top-menu-btn__has-submenu)').filter(':not(a)');

          if(!limited) {
            $userMenuList.find('[data-id="myaccounts"]').remove();
          }

          if (hideSecuritySettings) {
            $userMenuList.find('[data-id="secset"]').remove();
          }
          if (hideDocumentCenter) {
            $userMenuList.find('[data-id="docs"]').remove();
          }

          if(hidePreferences) {
            $userMenuList.find('[data-id="prefs"]').remove();
          }
          
          if (hideMainNavigationLinks) {
            $mainNavigationButtonsB.closest('li').addClass('-oneX-hidden');
            $insuranceMenu.closest('li').removeClass('-oneX-hidden');  // Unhide the one
          }

          if ( oneX.$('.-oneX-header-user-nav-button img').length > 0 && (hideSecureMessages || hideSecuritySettings || hideDocumentCenter || hidePreferences || hideMainNavigationLinks)) {
            const newSource = oneX.$('.-oneX-header-user-nav-button img').attr('src').replace('user.svg', 'business.svg');
            oneX.$('.-oneX-header-user-nav-button img').attr('src', newSource);
          }


          if (breadcrumbsJSON) {
            oneX.Header.setBreadcrumbs(breadcrumbsJSON);
            oneX.Header.toggleBreadcrumbs(true);
          }

          _element.children(':first').unwrap();

          oneX.Header.init();
          oneX.Help.init(); 
          oneX.HelpMenu.init();
          oneX.Search.init();
          oneX.FloatingLabel.init();  // desktop - get a quote - zip code isn't floating if I don't call this
          oneX.AutoFormat.init();  // desktop - get a quote - zip code doesn't have underscores if I don't call this
          oneX.Modal.init();  // main links spanish need to display language warning
          oneX.ButtonSelectors.init();
          oneX.Textfield.init();
          
          oneX.Util.triggerEvent(document,  "headerRendered");  
          
          if (callback && typeof(callback) === "function"){
            callback(_element);
          }

          setTimeout(function () {
            // hidden help menu gets turned on by an outside script. Calling this will hide the limited language link if help gets turned on, so call after a delay
            // this call is also triggered by a tablet flip or other resize event
            oneX.Header.hideLimitedLangLink();
          },1500);

        } else {
          console.debug("Error with loading the header " + response + "--" + headerPath + headerHTML);
        }
      });
    },

    addPreLogoutProcessing: function(appLogoutFunct) {
      document.addEventListener("headerRendered", function(e){ oneX.$("a.-oneX-header-logout").on("click", function(){appLogoutFunct()})});
    },

    focusSkipToMainContent: function () {
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        oneX.$('.-oneX-skipContent-link').removeClass('-oneX-clipped');
      }
    },

    hideSkipToMainContent: function () {
      if (window.matchMedia('(min-width: 992px)').matches) {
        oneX.$('.-oneX-skipContent-link').addClass('-oneX-clipped');
      }
    },

    clickSkipToMainContent: function (event) {
      event.preventDefault();
      oneX.$('.-oneX-mainContent-starts').attr('tabindex', '-1').focus();
    },

    setLanguageLink: function (url) {
      const $header_languageLink = oneX.$('#oneX-header').find('.-oneX-lang-link');  // li

      $header_languageLink.removeClass("-oneX-hidden -oneX-lang-link-unavailable");
      $header_languageLink.removeAttr('aria-hidden');
      $header_languageLink.find('a').attr('href', url);
      oneX.HelpMenu.removeHiddenUtil(true);
    },

    moveHiddenLanguageLink: function () {
      const $hiddenLanguageLink = oneX.$('#hiddenLanguageLink'),
        $footer_languageLink = oneX.$('.-oneX-footer').find('.-oneX-lang-link'), // won't use anymore, let it stay hidden
        $header_languageLink = oneX.$('#oneX-header').find('.-oneX-lang-link');  // li

      if ($header_languageLink.length > 0 && $header_languageLink.hasClass("-oneX-lang-link-unavailable")) {
        // try footer
        if ($footer_languageLink.length > 0 && $footer_languageLink.find('a').length > 0 && $footer_languageLink.find('a').attr('href') !== '') {
          $header_languageLink.removeClass("-oneX-hidden -oneX-lang-link-unavailable");
          $header_languageLink.removeAttr("aria-hidden");
          $header_languageLink.find('a').attr('data-locale', $footer_languageLink.find('a').attr('data-locale')).attr('href', $footer_languageLink.find('a').attr('href'));
          $footer_languageLink.addClass('-oneX-hidden');
          $footer_languageLink.attr('aria-hidden','true');
        }
        else {
          // try SFX hidden lang link
          if ($hiddenLanguageLink.length > 0) {
            $header_languageLink.removeClass("-oneX-hidden -oneX-lang-link-unavailable");
            $header_languageLink.removeAttr("aria-hidden");
            $header_languageLink.find('a').attr('data-locale', $hiddenLanguageLink.find('a').attr('data-locale')).attr('href', $hiddenLanguageLink.find('a').attr('href'));
            $footer_languageLink.addClass('-oneX-hidden');
            $footer_languageLink.attr('aria-hidden','true');
    
            $hiddenLanguageLink.remove();
          }
        }
      }
      oneX.Header.hideLimitedLangLink();
    },

    setDraggingOn: function() {
      oneX.Header.dragging = true;
    },

    setDraggingOff: function() {
      oneX.Header.dragging = false;
    },

    preventDefault: function (event) {
      event.preventDefault();
    },

    //for third level of menu
    subMenuShow: function (event) {
      const $clickedAnchorMobile = oneX.$(event.currentTarget.tagName === 'SPAN' ? event.currentTarget.querySelector('a') : event.currentTarget);

      if (oneX.Header.dragging || $clickedAnchorMobile.siblings('.-oneX-sub-navigation-menu__chevron-right-mobile').length === 0) {
        // if touchend was part of scrolling the screen (dragging) or there is no submenu (no chevron) ... just get out
        return;
      }

      event.preventDefault();

      const clickedIDmobile = '#' + $clickedAnchorMobile.attr('id'),  // like 0-banking-mobile
        menuIDmobile = '#' + oneX.$(clickedIDmobile).closest('section').parent().closest('section').attr('id'),
        $clickedAnchor = oneX.$(clickedIDmobile.split("-mobile")[0]),
        menuID = '#' + oneX.$($clickedAnchor).closest('section').parent().closest('section').attr('id'),
        $backContainer = oneX.$('.-oneX-header-navmenu-back-container');

      $backContainer.attr('data-returnTo2', $clickedAnchor.attr('id') + '-mobile');

      const level2MenuLabel = $clickedAnchorMobile.closest(".-oneX-submenu-section").siblings('.-oneX-menuItem-title-mobile').text();

      $backContainer.find('button').attr('aria-label', 'back to ' + level2MenuLabel + ' menu');
      $backContainer.find('button').text(level2MenuLabel);
      oneX.Header.animateContainer(oneX.$(menuIDmobile), "left", "0", -oneX.$(window).width() + "px", false);
      oneX.Header.animateContainer(oneX.$(menuID), "left", oneX.$(window).width() + "px", "0", true);
      oneX.$(".-oneX-sub-navigation-menu, .-oneX-menuItem-title, .-oneX-sub-navigation-menu__chevron-right").addClass('-oneX-hidden');
      oneX.$(".-oneX-menuLevel1").addClass('-oneX-menuLevel1--title');
      $clickedAnchor.closest('section').find('ul').removeClass('-oneX-hidden');
      $clickedAnchor.closest('section').removeClass('-oneX-hidden');

      setTimeout(function () {
        // put focus on back button
        $backContainer.find('button').focus();
      }, oneX.Header.animationSpeed + 25);
    },

    subMenuClose: function () {
      const $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        returnFocusId = '#' + $backContainer.attr('data-returnTo2'),
        level2menu = '#oneX-submenu-' + $backContainer.attr('data-returnto1'),
        level2menuMobile = '#oneX-submenu-' + $backContainer.attr('data-returnto1') + '-mobile';

      if ($backContainer.attr('data-returnto1') === undefined || oneX.$(level2menu).hasClass('-oneX-hidden')) {
        return;
      }
      
      oneX.Header.animateContainer(oneX.$(level2menuMobile), "left", -oneX.$(window).width() + "px", "0", true);
      oneX.Header.animateContainer(oneX.$(level2menu), "left", "0", oneX.$(window).width() + "px", false);
      $backContainer.find('button').text('Back');
      setTimeout(function () {
        oneX.$(".-oneX-subMenu-items").not('.-oneX-header-bottom .-oneX-subMenu-items').addClass('-oneX-hidden');
        //oneX.$(".-oneX-subMenuItem-title").addClass('-oneX-hidden');
        oneX.$(".-oneX-sub-navigation-menu, .-oneX-menuItem-title, .-oneX-sub-navigation-menu__chevron-right").removeClass('-oneX-hidden');
        oneX.$(".-oneX-menuLevel1").removeClass('-oneX-menuLevel1--title');
  
        $backContainer.find('button').attr("aria-label", "back to main menu");
        // put focus on item that was clicked
        if (returnFocusId && returnFocusId.length > 0) {
          const $menuItem = oneX.$(returnFocusId);
          $backContainer.removeAttr('data-returnTo2');
          if ($menuItem.length > 0) {
            $menuItem[0].focus();
          }
        }  
      }, oneX.Header.animationSpeed + 25);
    },

    mainNavHover: function (event) {

    },

    utilNavHover: function () {
      oneX.Header.initialUtilHover = true;
    },

    animateMainNavVerticalMobile: function (direction, initial = true) {
      // only for tablet/mobile
      const $mainNav = oneX.$(".-oneX-header-main-nav"),
        $utilNav = oneX.$(".-oneX-header-util-nav"),
        $navContainer = oneX.$('.-oneX-header-nav-container'),
        $header = oneX.$('#oneX-header'),
        headerHeight = oneX.Header.getHeaderHeight() + "px";

      let endProperty,
        beginProperty;

      if (direction === "down") {
        beginProperty = headerHeight;
        endProperty = "100vh";
      }
      else {
        beginProperty = "100vh";
        endProperty = headerHeight;
      }

      $utilNav.addClass('-oneX-hidden');
      
      if (initial) {
        // animate height of header if not yet open
        if (direction === "up") {
          setTimeout(function () { oneX.Header.showTopNav(); }, oneX.Header.animationSpeed * 0.9);
        }

        $header.removeAttr("style");
        oneX.Header.removeScrim();  // can't have scrim while animating
        $header.attr("style", "height: " + beginProperty + ";");
        
        $header.animate(
          {
            height: endProperty
          },
          oneX.Header.animationSpeed,
          "swing",
          function () {
            $utilNav.removeClass('-oneX-hidden');
            $mainNav.removeAttr("style");
            if (direction === "up") {
              oneX.Header.removeScrim();
              $header.removeAttr("style");
            }
            else {
              oneX.Header.addScrim();
            }        
          }
        )
      }
      else 
      {
        // animate height of main nav since header is already open
        $navContainer.attr("style", "height: " + beginProperty + ";");
        $navContainer.addClass('-oneX-header-nav-show');

        $navContainer.animate(
          {
            height: endProperty
          },
          oneX.Header.animationSpeed,
          "swing",
          function () {
            $utilNav.removeClass('-oneX-hidden');
            $navContainer.removeAttr("style");
            if (direction === "up") {
              $navContainer.removeClass('-oneX-header-nav-show');
            }
          }
        );
      }
    },

    animateMainNavVerticalDesktop: function (direction) {
      // only for desktop
      const $buttons = oneX.$(".-oneX-header-top-menu-btn"),
        $mainNav = oneX.$(".-oneX-header-main-nav");
      if ($buttons.length === 0) {
        // limited header exception; just go back
        return;
      }

      const $header = oneX.$(".-oneX-header"),
        slideHeight = "62px";

      let endProperty;
      let beginProperty;
      $buttons.removeAttr("style");

      if (direction === "down") {
        beginProperty = 0;
        endProperty = slideHeight;
      }
      else {
        beginProperty = slideHeight;
        endProperty = 0;
        $mainNav.removeClass('-oneX-hidden');
      }

      $header.attr("style", "overflow: hidden");
      $buttons.attr("style", "margin-top: " + beginProperty);

      $buttons.animate(
        {
          "margin-top": endProperty
        },
        oneX.Header.animationSpeed,
        "swing",
        function () {
          $header.removeAttr("style");
          if (direction === "up") {
            $buttons.removeAttr("style");
          }
          else {
            $buttons.attr("style", "display: none");
            $mainNav.addClass('-oneX-hidden');
          }
          
        }
      );
    },

    userSubmenuKeyHandler: function (event) {
      const $currentTarget = oneX.$(event.currentTarget),
        $userMenuBtn = oneX.$('.-oneX-header-user-nav-button'),
        $firstItem = oneX.$(oneX.$('.-oneX-submenu-usermenu:not(.-oneX-hidden) ul li a')[0]),
        $lastItem = oneX.$('.-oneX-submenu-usermenu:not(.-oneX-hidden) ul li:last-of-type a');

      switch(event.keyCode) {
      case 27:
        // if esc key, just close the menu
        oneX.Header.closeUserSubmenu();
        $userMenuBtn.focus();
        break;
      case 40:
        // down arrow
        if (!$currentTarget.is($userMenuBtn)) {
          if ($currentTarget.is($lastItem)) {
            // on last item, focus to first item
            event.preventDefault();
            $firstItem.focus();
          } else {
            // focus to next item
            event.preventDefault();
            $currentTarget.parent().next().find('a').focus();
          }
        }
        break;
      case 38:
        // up arrow
        if (!$currentTarget.is($userMenuBtn)) {
          if ($currentTarget.is($firstItem)) {
            // on first item, focus to last item
            event.preventDefault();
            $lastItem.focus();
          } else {
            // focus to prev item
            event.preventDefault();
            $currentTarget.parent().prev().find('a').focus();
          }
        }
        break;
      case 9:
        // tab
        if (($currentTarget.is($userMenuBtn) && event.shiftKey) || ($currentTarget.is($lastItem) && !event.shiftKey)) {
          // if shift tab on the button, just close the menu
          oneX.Header.closeUserSubmenu();           
        }
        break;
        default:
      }
    },

    closeUserSubmenu: function () {
      const $headerUserNavButton = oneX.$('.-oneX-header-user-nav-button[aria-expanded="true"]'),
        $headerUserNavSubmenu = $headerUserNavButton.siblings('.-oneX-submenu-usermenu');

      if($headerUserNavButton.length > 0) {
        $headerUserNavButton.attr('aria-expanded', 'false');
        $headerUserNavSubmenu.slideToggle(250);
        $headerUserNavSubmenu.addClass('-oneX-hidden');
      }
    },

    toggleUserSubmenu: function (event) {
      const $headerUserNavButton = oneX.$(event.currentTarget),
        $headerUserNavSubmenu = $headerUserNavButton.siblings('.-oneX-submenu-usermenu');
      
      if($headerUserNavButton.attr('aria-expanded') === "true") {
        oneX.Header.closeUserSubmenu();
      }
      else {
        oneX.Header.userNavClicked = true;
        oneX.Header.showTopNav(); // closeOpenMainMenu(oneX.Header.animationSpeed);
        $headerUserNavButton.attr('aria-expanded', 'true');
        $headerUserNavSubmenu.slideToggle(250);
        $headerUserNavSubmenu.removeClass('-oneX-hidden');
        $headerUserNavButton.focus();
      }
    },

    getHeaderHeight: function () {

      let headerHeight;

      if (!oneX.$('.-oneX-header').hasClass('-oneX-header-limited')) {
        headerHeight = 96;  // auth-b is always 64 + 32
      } else if (window.matchMedia('(max-width: 767px)').matches) {
        headerHeight = 60;
      } else if (window.matchMedia('(max-width: 991px)').matches) {
        headerHeight = 60;
      } else {
        headerHeight = 85; // old is 85, new auth is 96 // was 75 -- added 27 for new util menu bar
      }

      return headerHeight;
    },

    addError : function ($element, errText) {     //to Add an Error state to the ZipCode Field or other input fields 
      const elemId = $element.attr("id");

      if (errText && errText !== "") {
        oneX.$("#" + elemId + "-err").text(errText);
      }

      $element.closest('.-oneX-' + elemId).addClass('-oneX-widget--err');
      $element.siblings(".-oneX-widget__err-text").removeClass("-oneX-hidden");
      $element.attr("aria-describedby", $element.siblings(".-oneX-widget__err-text").attr("id"));
      $element.attr("aria-invalid", true);
      $element.removeAttr("pattern");
      
    },

    removeError : function ($element) { //to Remove the Error state to the Field
      const elemId = $element.attr("id");

      $element.closest('.-oneX-' + elemId).removeClass('-oneX-widget--err');
      $element.siblings(".-oneX-widget__err-text").addClass("-oneX-hidden");
      $element.removeAttr("aria-describedby");
      $element.removeAttr("aria-invalid");

    },

    animateMainNavHorizontal: function (direction) {
            // only for tablet/mobile
      const $navContainer = oneX.$('.-oneX-header-nav-container'),
        $utilNav = oneX.$(".-oneX-header-util-nav"),
        slideWidth = -oneX.$(window).width();

      let endProperty,
        beginProperty;

      $navContainer.removeAttr("style");
      $utilNav.addClass('-oneX-hidden');

      if (direction === "left") {
        beginProperty = 0;
        endProperty = slideWidth + "px";
      }
      else {
        beginProperty = slideWidth + "px";
        endProperty = 0;
      }

      $navContainer.attr("style", "position: absolute; width: " + -(slideWidth + 30) + "px; left: " + beginProperty + ";");
      
      $navContainer.animate(
        {
          left: endProperty
        },
        oneX.Header.animationSpeed,
        "swing",
        function () {
          $navContainer.removeAttr("style");
          if (direction !== "left") {
            $utilNav.removeClass('-oneX-hidden');
          }
          else {
            $navContainer.removeClass('-oneX-header-nav-show');
          }
        }
      );
    },

    animateContainer: function ($container, property, beginVal, endVal, isShow) {
      $container.removeAttr("style");

      if (property === "left" && endVal === "0" && ($container.attr("id") === "oneX-submenu-banking-mobile" || $container.attr("id") === "oneX-submenu-investments-mobile")) {
        const _self = oneX.Header;
        oneX.$(".-oneX-menuLevel1-mobile").parent().off('click touchend', _self.subMenuShow);
        oneX.$(".-oneX-menuLevel1-mobile").parent().on('click touchend', _self.preventDefault);
        oneX.$(".-oneX-menuItem-title-mobile").on('click', _self.preventDefault);
      }

      $container.attr("style", property + ": " + beginVal + ";");
      $container.removeClass('-oneX-hidden');
      $container.removeAttr('aria-hidden');
      const propString = '{"' + property + '": "' + endVal + '"}';
      const properties = JSON.parse(propString);

      $container.animate(
        properties,
        $container.attr('id') === "oneX-submenu-search" && !isShow ? oneX.Header.animationSpeed / 2 : oneX.Header.animationSpeed,
        "swing",
        function () {
          if (property === "left" && endVal === "0" && ($container.attr("id") === "oneX-submenu-banking-mobile" || $container.attr("id") === "oneX-submenu-investments-mobile")) {
            const _self = oneX.Header;
            oneX.$(".-oneX-menuLevel1-mobile").parent().on('click touchend', _self.subMenuShow);
            oneX.$(".-oneX-menuLevel1-mobile").parent().off('click touchend', _self.preventDefault);
            oneX.$(".-oneX-menuItem-title-mobile").off('click', _self.preventDefault);
          }    
          if (!isShow) {
            $container.addClass('-oneX-hidden');
            $container.attr('aria-hidden', 'true');
          }
          $container.removeAttr("style");  
        }
      );
    },

    initHideClass: function () {
      oneX.$.each(oneX.$('.-oneX-hide-link'), 
        function (index, value) {
          oneX.$(oneX.$('.-oneX-hide-link')[index]).closest('.-oneX-submenu-section-wrapper').addClass('-oneX-hide-link-master')
        }
      );
    },

    openMenuContainer: function(event) {
      const isMobileHeaderShowing = oneX.$('.-oneX-header-mobile-tablet-nav-section').css("display") !== "none";
      if (oneX.Header.mobileDevice || isMobileHeaderShowing) {
        return;
      }

      const $navContainer = oneX.$('.-oneX-header-nav-container');

      // iOS accessibility voiecover/apple device fix
      if(event.originalEvent.key === "Tab") {
        return;
      }

      // remove tabbing class if mouse enter on another menu if previously tabbing
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        oneX.$('body').removeClass('-oneX-user-tabbing')
        oneX.$('body').removeClass('-oneX-user-back-tabbing')
      }

      // the oneX-header-nav-show class is only present on mobile
      if (!$navContainer.hasClass('-oneX-header-nav-show')) {
        //ignore hover on links with no container ID  
        if (!oneX.$(event.currentTarget).children(".-oneX-header-top-menu-btn ").data("for")) {
          return;
        }

        const $theHitBox = event.type === 'focusin' ? oneX.$(event.currentTarget).siblings('.-oneX-header-top-menu-btn-hitbox') : event.currentTarget,
          menuShortName = oneX.$($theHitBox).children(".-oneX-header-top-menu-btn ").data("for"),
          menuID = '#oneX-submenu-' + menuShortName,
          $header = oneX.$('#oneX-header'),
          scrimTypeString = "header",
          $breadcrumbs = oneX.$('.-oneX-breadcrumbs'),
          $megaMenuPanelTitle = oneX.$($theHitBox).find(".-oneX-header-top-menu-btn"),
          $chevronDown = $megaMenuPanelTitle.siblings('.-oneX-header-top-chevron-btn'),
          openMenu = '#oneX-submenu-' + oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').not("[data-for = '"+ menuShortName+" ']").data('for'),
          documentClientHeight = document.documentElement.clientHeight,
          documentScrollHeight = document.documentElement.scrollHeight;

        // If the search util widget is open, there will be an 
        // extra margin gap between the attached megamenu and
        // the header. This will apply a negative margin to the 
        // row of buttons in order to remove that gap
        if (oneX.$(".-oneX-header").hasClass('-oneX-search-open')) {
          oneX.$($theHitBox).css('margin-bottom', "-3px")
        } else {
          oneX.$($theHitBox).css('margin-bottom', "")
        }

        $header.addClass('-oneX-header-app-open');
       
        //If page has overflow then add stop scrollbar
        if (documentScrollHeight > documentClientHeight) {
          oneX.$('body').addClass('-oneX-header-stop-scroll');
        }

        if(oneX.Header.mainNavOpen){
          //change panel with out animation
          oneX.$(menuID).css('z-index', 2010);
          oneX.$(menuID).detach().insertAfter($theHitBox);
          oneX.$(menuID).removeClass('-oneX-hidden');
          oneX.$(menuID).css('display','block');
          oneX.Header.mainNavOpen = true;

          if (menuID !== openMenu) {
            oneX.$(openMenu).removeAttr('style');
            oneX.$(openMenu).addClass('-oneX-hidden');
          }
          oneX.$('.-oneX-header-top-chevron-btn').blur();
          oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').removeClass('-oneX-header-top-chevron-visible');
          oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').attr('aria-expanded', 'false');
          oneX.$($chevronDown).attr('aria-expanded', 'true');
          $breadcrumbs.attr('style', 'display: none'); 
          oneX.$($megaMenuPanelTitle).attr('aria-expanded', 'true');
        } else {
          //show with animation after small delay 
          oneX.Header.hoverTimeoutID = setTimeout (() => {
            if (oneX.$(".-oneX-content-behind-scrim__" + scrimTypeString).length === 0) {
              oneX.Header.addScrim(true);
            }
            oneX.$(menuID).css('z-index', 2010);
            oneX.$(menuID).detach().insertAfter($theHitBox);
            oneX.$(menuID).slideDown(400, 'swing', () => {
              if (oneX.$(".-oneX-content-behind-scrim__" + scrimTypeString).length === 0) {
                oneX.Header.addScrim(true);
              }});
            oneX.$(menuID).removeClass('-oneX-hidden');
            oneX.Header.mainNavOpen = true;

            oneX.$(openMenu).addClass('-oneX-hidden');
            oneX.$('.-oneX-header-top-chevron-btn').blur();
            oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').removeClass('-oneX-header-top-chevron-visible');
            oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').attr('aria-expanded', 'false');
            oneX.$($chevronDown).attr('aria-expanded', 'true');
            $breadcrumbs.attr('style', 'display: none'); 
            oneX.$($megaMenuPanelTitle).attr('aria-expanded', 'true');
          }, 300);
        }
      }
    },

    closeMenuContainer: function(event) {
      clearTimeout(oneX.Header.hoverTimeoutID);
      
      const isMobileHeaderShowing = oneX.$('.-oneX-header-mobile-tablet-nav-section').css("display") !== "none";
      if (oneX.Header.mobileDevice || isMobileHeaderShowing) {
        return;
      }

      const $SubMenus = oneX.$(event.currentTarget).children(".-oneX-header-submenu-container"),
        menuID = "#" + oneX.$($SubMenus).attr("id"), 
        menuShortName = menuID.substring(14),
        $header = oneX.$('#oneX-header'),
        $theButton = oneX.$('.-oneX-header-top-menu-btn[data-for="' + menuShortName + '"]'),
        $chevronDown = $theButton.siblings('.-oneX-header-top-chevron-btn'),
        $breadcrumbs = oneX.$('.-oneX-breadcrumbs'),
        $backContainer = oneX.$('.-oneX-header-navmenu-back-container');


      const hasMenu = oneX.$(event.relatedTarget).closest('.-oneX-header-top-menu-btn-hitbox').children(".-oneX-header-top-menu-btn ").data("for");
      const isCursorInHeader = oneX.$(event.relatedTarget).closest('.-oneX-header-main-nav-menu').length > 0;

      // conditional for menu closing immediately if mouse is not in header and user is tabbing
      // If user is tabbing skip the hide/switch panel conditions, if they're not proceed with hiding/switching
      if (!oneX.$('body').hasClass('-oneX-user-tabbing')) {
        //If cursor is  on any header Item with a menu
        if (isCursorInHeader && hasMenu) {
          //Just hide prior panel leaving scrim 
          oneX.$(menuID).removeAttr('style');
          oneX.$($SubMenus).detach().insertAfter($backContainer);
          oneX.$(menuID).addClass('-oneX-hidden');
          oneX.$(menuID).css('z-index', 2006);
        } else {
          // Animate closing and remove scrim
          oneX.$(".-oneX--scrim__header").removeAttr("style");
          oneX.Header.removeScrim();
          oneX
            .$(menuID)
            .stop()
            .slideUp(400, "swing", function () {
              oneX.$(menuID).addClass("-oneX-hidden");
              oneX.$($SubMenus).detach().insertAfter($backContainer);
              oneX.$(menuID).css("z-index", 2006);
              oneX.$("body").removeClass("-oneX-header-stop-scroll");
            });
          oneX.Header.mainNavOpen = false;
          $header.removeClass("-oneX-header-app-open");
        }
        
        $theButton.attr('aria-expanded', 'false');
        $breadcrumbs.removeAttr('style');
        oneX.$($theButton).attr('aria-expanded', 'false');
        oneX.$($chevronDown).attr('aria-expanded', 'false');
        oneX.$('.-oneX-header-top-chevron-btn').blur()
        oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true]').blur();
        $chevronDown.removeClass('-oneX-header-top-chevron-visible');
        $theButton.attr('aria-expanded', 'false')
      }
    },

    clickToggleMenuContainer: function (event) {
      oneX.$(event.currentTarget).click();
    },

    /* 
       Trigger the function by listening to keydown and pointerDown events, 
       BUT only open mega menu when we have a:
        - touch event
        - click in the mobile view opened by the hamburger icon only. 
        - keydown events spacebar and enter
        - pointerdown event when chevron has focus (this cover keydown events when using NVDA abd JAWS screen readers)
    */
    _toggleMegaMenu: function (event) {
      const isTouchableDevice = 'ontouchstart' in document.documentElement;
      // combo is chevron and mobile? for checking on title
      const isChevronFocused = oneX.$(event.target).hasClass('-oneX-header-top-chevron-btn') && oneX.$(event.target).is(':focus');
      const isValidToggleKeyPressed = (event.type === 'keydown' && (event.key === "Enter" || event.key === " ")) || (event.type === 'pointerdown' && isChevronFocused);
      const isDesktopMode = oneX.$('.-oneX-header-mobile-tablet-nav-section').css("display") === "none";
      const isClickEvent = event.type === "pointerdown" && !isTouchableDevice;
      const isTouchEvent = event.type === "pointerdown" && isTouchableDevice;
      // Valid key on desktop mobile view has ongoing bug in prod where panel flashes and closes on enter, but works fine on space
      const isMobileViewOnDesktop = !isDesktopMode && !isTouchableDevice;

      // Target is chevron button on desktop or tablet
      const isTopChevon = oneX.$(event.target).hasClass('-oneX-header-top-chevron-btn') && (isTouchableDevice || isDesktopMode)

      // Allow all pointerdown events on desktop mobile view (hamburger menu)
      const isInvalidInputMobileViewOnDesktop = isMobileViewOnDesktop && !isClickEvent
      /*
       1. If a valid keydown event (spacebar or enter) occurs, continue
       2. If a touch event is fired from a touch screen device, continue
       3. If a click event (not touch) is registered in mobile view on desktop, continue
       4. If dragging or alreadyClickedMenu don't continue
       */
      if (
        ( !isValidToggleKeyPressed &&
          !isTouchEvent &&
          (isDesktopMode || isTouchableDevice || isInvalidInputMobileViewOnDesktop)) ||
          oneX.Header.dragging ||
          oneX.Header.alreadyClickedMenu
      ) {
        return;
      }

      if (event.pointerType  ===  'touch') {
        oneX.$('.-oneX-header-top-chevron-btn').blur()
        oneX.$('.-oneX-header-top-menu-btn').blur()
      }

      oneX.Header.alreadyClickedMenu = true;
      setTimeout(function () { oneX.Header.alreadyClickedMenu = false; },500);
      const theButton = event.pointerType  ===  'keydown' ? oneX.$(event.currentTarget).siblings('a.-oneX-header-top-menu-btn') : oneX.$(event.currentTarget).children('.-oneX-header-top-menu-btn'),
        $theAnchor = oneX.$(event.currentTarget).find('a.-oneX-submenu-usermenu-link'),
        menuShortName = oneX.$(theButton).data("for"),
        $theButton = oneX.$('.-oneX-header-top-menu-btn[data-for="' + menuShortName + '"]'),
        $theHitBox = $theButton.parent(),
        $chevronDown = $theButton.siblings('.-oneX-header-top-chevron-btn'),
        menuID = '#oneX-submenu-' + menuShortName,
        menuIDmobile  = '#oneX-submenu-' + menuShortName + "-mobile",
        $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        $navContainer = oneX.$('.-oneX-header-nav-container'),
        $breadcrumbs = oneX.$('.-oneX-breadcrumbs'),
        $logoContainer = oneX.$('.-oneX-header-logo-container'),
        $headerSubMenuContainer = oneX.$('.-oneX-header-submenu-container'),
        scrimTypeString = "header",
        $header = oneX.$('#oneX-header'),
        documentClientHeight = document.documentElement.clientHeight,
        documentScrollHeight = document.documentElement.scrollHeight

      // close open util menu
      oneX.$(".-oneX-header").removeClass('-oneX-search-open');

      // close user menu
      oneX.Header.closeUserSubmenu();

      // the "new" header has a logout anchor link instead of a button.  Handle that here:
      if ($theAnchor.length > 0) {
        $theAnchor[0].click();
        return;
      }
      
      //if on mobile device and menu is still hidden prevent link click
      if (oneX.$(menuID).hasClass('-oneX-hidden') && oneX.Header.mobileDevice) {
        event.preventDefault();
      } 
      
      // first used on Vehicle menu to hide it at desktop; also used on discounts
      if (oneX.$('.-oneX-hide-link-master').length === 0 && oneX.$('.-oneX-hide-link').length > 0) 
      {
        oneX.Header.initHideClass(); 
      }
      
      if (oneX.$(menuID).hasClass('-oneX-hidden')) {
        // When target is hitbox, desktop, and valid keypress on panel title, nav to link
        // Not oneX.Header.mobileDevice to eliminate tablet mode from firing
        if (isValidToggleKeyPressed && !isTopChevon && !$navContainer.hasClass('-oneX-header-nav-show')) {
          // Desktop/tablet mode space/enter on panel title should nav to url
          // prevents default space bar scroll
          event.preventDefault();
          oneX.$('body').addClass('-oneX-header-stop-scroll');
          oneX.$(event.target)[0].click();
          return;
        } 
        
        // Desktop/tablet mode space/enter on chevron should expand menu        
        if (isValidToggleKeyPressed && isTopChevon) {
          event.preventDefault();
        }

        // show it
        oneX.$('.-oneX-header-utilmenu-container ').addClass('-oneX-hidden'); // hide all other util menus
        const openHeight = oneX.Header.closeOpenMainMenu(400);

        // if mobile then unhide the back container
        if ($navContainer.hasClass('-oneX-header-nav-show')) {
          oneX.Header.animateMainNavHorizontal("left");

          if (oneX.$(menuIDmobile).length === 0) {
            oneX.$(menuID).find('.-oneX-subMenu-items').removeClass('-oneX-hidden');
            oneX.Header.animateContainer(oneX.$(menuID), "left", oneX.$(window).width() + "px", "0", true);
          } else {
            oneX.Header.animateContainer(oneX.$(menuIDmobile), "left", oneX.$(window).width() + "px", "0", true);
          }

          oneX.Header.animateContainer($backContainer, "opacity", "0", "1", true);
          oneX.$(".-oneX-down-chevron").addClass("-oneX-hidden");
          oneX.$(".-oneX-back-chevron").removeClass("-oneX-hidden");
          oneX.$('.-oneX-header-navmenu-back button').attr('aria-label', 'back to main menu');
          $backContainer.attr('data-returnTo1', menuShortName);

          setTimeout(function () {
            // put focus on back button
            oneX.$('.-oneX-header-navmenu-back button').focus();
          }, oneX.Header.animationSpeed + 25);

        } else {
          // if desktop then move menu to directly after the button for accessibility
          if (documentScrollHeight > documentClientHeight) {
            oneX.$('body').addClass('-oneX-header-stop-scroll');
          }
          oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').removeClass('-oneX-header-top-chevron-visible');
          oneX.$('.-oneX-header-top-menu-btn[aria-expanded="true"]').attr('aria-expanded', 'false');
          oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').attr('aria-expanded', 'false');

          oneX.$(menuID).css('z-index', 2010);
          oneX.$(menuID).detach().insertAfter($theHitBox);


          if (oneX.$(".-oneX-content-behind-scrim__" + scrimTypeString).length === 0) {
            oneX.Header.addScrim(true);
            oneX.$(menuID).slideDown(400, 'swing', () => {
              if (oneX.Header.mobileDevice) {
                oneX.$('.-oneX-navmenu-anchor-has-submenu[data-for="' + menuShortName + '"]').removeClass('-oneX-disable-header-link');
              }
            });
          }
          $header.addClass('-oneX-header-app-open');
          oneX.$(menuID).removeClass('-oneX-hidden');


          $breadcrumbs.attr('style', 'display: none'); 
          $theButton.attr('aria-expanded', 'true');
          oneX.Header.mainNavOpen = true;
          $chevronDown.attr('aria-expanded', 'true');

          if(oneX.$(event.target).hasClass('-oneX-header-top-chevron-btn') && event.type  ===  'keydown') {
            $chevronDown.addClass('-oneX-header-top-chevron-visible');
          }
        }
      } else {
        // hide it
        //if mobile then hide back container
        if ($navContainer.hasClass('-oneX-header-nav-show')) {
          // never gets here because on mobile, the back button is clicked
        } else {
          const panelItemHasSubmenu = oneX.$(event.currentTarget).children('.-oneX-navmenu-anchor-has-submenu').length > 0

          // panel isn't hidden, valid key press, and item has a submenu
          if (isValidToggleKeyPressed && panelItemHasSubmenu) {
            event.preventDefault();

            $header.removeClass("-oneX-header-app-open");
            oneX.$(".-oneX--scrim__header").removeAttr("style");
            oneX.Header.removeScrim()
            oneX.Header.mainNavOpen = false;
          }

          // if desktop, put the menu container back
          oneX.$('.-oneX--scrim__header').removeAttr('style');
          oneX.Header.removeScrim();
          oneX.$(menuID).stop().slideUp(400, "swing", function () {

            if (oneX.Header.mobileDevice) {
              oneX.$('.-oneX-navmenu-anchor-has-submenu[data-for="' + menuShortName + '"]').addClass('-oneX-disable-header-link');
            }
            
            $header.removeClass('-oneX-header-app-open');
            oneX.$(menuID).removeAttr('style');
            oneX.$(menuID).addClass('-oneX-hidden');
            oneX.$(menuID).detach().insertAfter($backContainer);
            $theButton.attr('aria-expanded', 'false');
            oneX.Header.mainNavOpen = false;
            $chevronDown.attr('aria-expanded', 'false');
            $chevronDown.removeClass('-oneX-header-top-chevron-visible');
            $breadcrumbs.removeAttr('style');
            oneX.$('body').removeClass('-oneX-header-stop-scroll');
          });
        }

        // On tablet mode landscape mode enter/space should act same as desktop
        if (isValidToggleKeyPressed && isTopChevon && isDesktopMode && isTouchableDevice) {
          oneX.Header.scrimClick
        }
      }

      oneX.$(window).resize();
    },

    toggleUtilContainer: function (event) {
      if (oneX.Header.dragging) {
        // if touchend was part of scrolling the screen
        return;
      }
          
      if (oneX.Header.alreadyClickedUtil) {
        return;
      }

      oneX.Header.alreadyClickedUtil = true;
      setTimeout(function () { oneX.Header.alreadyClickedUtil = false; }, oneX.Header.animationSpeed);

      const theButton = event.currentTarget,
        menuShortName = oneX.$(theButton).data("for") === undefined ? undefined : oneX.$(theButton).data("for"),
        menuID = menuShortName === undefined ? oneX.$('.-oneX-header-utilmenu-container:not(.-oneX-hidden)') : '#oneX-submenu-' + menuShortName,
        $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        $navContainer = oneX.$('.-oneX-header-nav-container'),
        $mainMenu = oneX.$('#oneX-header-main-menu-btn');

      if (oneX.$(menuID).hasClass('-oneX-hidden')) {
        // show it
        oneX.$('#oneX-header').removeClass('-oneX-header-app-open');  // remove existing style override for when switching directly between menus
        oneX.$('.-oneX-header-utilmenu-container ').addClass('-oneX-hidden'); // hide all other menus
        oneX.Header.closeOpenMainMenu(0);
        oneX.Header.closeUserSubmenu();

        // if mobile 
        if ($navContainer.hasClass('-oneX-header-nav-show')) {
          oneX.Header.animateContainer($backContainer, "opacity", "0", "1", true);
          oneX.Header.animateMainNavVerticalMobile("up",false);
          oneX.Header.animateContainer(oneX.$(menuID), "top", oneX.$(window).height() + "px", oneX.Header.getHeaderHeight() + "px", true);

          oneX.$(".-oneX-down-chevron").removeClass("-oneX-hidden");
          oneX.$(".-oneX-back-chevron").addClass("-oneX-hidden");

          $backContainer.attr('data-returnTo1', menuShortName);
          setTimeout(function () {
            // we really shouldn't need these as the animateContainer statements above will remove hidden, but
            // for some reason on ios 13.3.1 with voice over on, it does not unhide the containers
            $backContainer.removeClass('-oneX-hidden');
            oneX.$(menuID).removeClass('-oneX-hidden');
            //
            
            $backContainer.find('button').focus();
          }, oneX.Header.animationSpeed + 25);
        } else {
          // desktop
          // disable opened item
          if (oneX.$('.-oneX-header').hasClass('-oneX-header-limited')) {
            oneX.$(theButton).attr('disabled', 'disabled').attr("aria-hidden", true);
            oneX.Header.removeScrim();

            // hide all other util items and show main menu
            const langHref = oneX.$('#oneX-header').find('.-oneX-lang-link').find('a').attr('href');
            if (langHref && langHref !== '') {
              oneX.Header.animateUtil(oneX.$('.-oneX-header-util-nav li'), oneX.$(theButton).parent(), $mainMenu.parent(), true);
            } else {
              oneX.Header.animateUtil(oneX.$('.-oneX-header-util-nav li:not(.-oneX-lang-link)'), oneX.$(theButton).parent(), $mainMenu.parent(), true);
            }
            oneX.Header.animateMainNavVerticalDesktop("down");
            oneX.$('.-oneX-header-user-nav').addClass('-oneX-hidden');
            oneX.$('.-oneX-header-main-nav').addClass('-oneX-hidden');
          } else {
            oneX.Header.removeScrim();
            oneX.$(theButton).attr("aria-expanded", true);
            oneX.$(theButton).closest('nav').addClass('-oneX-search-open');
            oneX.$('#oneX-submenu-search').addClass('-oneX-hidden').detach().insertAfter(oneX.$(theButton));
            oneX.Header.animateContainer(oneX.$('#oneX-submenu-search'), "height", "0", "96px", true);
          }

          // oneX.Header.animateContainer(oneX.$(".-oneX-header-util-nav"), "top", "7px", "-27px", false);

          setTimeout(function () {
            if (oneX.$('.-oneX-header').hasClass('-oneX-header-limited')) {
              oneX.Header.animateContainer(oneX.$(menuID), "opacity", "0", "1", true);
            }

            $mainMenu.data("for", menuShortName);
            
            //Added initial focus for desktop
            if (oneX.$(menuID).find('.-oneX-textfield--floating-input')[0] !== undefined) {
              oneX.$(menuID).find('.-oneX-textfield--floating-input')[0].focus();
            }
            else if (menuID === "#oneX-submenu-help") {
              oneX.$('.-oneX-help-links-container ul li:first a').focus();
            }
            else {
              $mainMenu[0].focus();
            }
          }, oneX.Header.animationSpeed + 25);
        }

        // add app-open modifier so logo shifts and header size expands via CSS (except for myaccounts)
        if (menuShortName !== 'myaccounts' && menuShortName !== 'help') {oneX.$('.-oneX-header, .-oneX-header-logo-container').addClass('-oneX-header-app-open');}
      } else {
        // hide it
        //if mobile 
        if ($navContainer.hasClass('-oneX-header-nav-show')) {
          $backContainer.addClass('-oneX-hidden');  // hide back container
          oneX.$(menuID).addClass('-oneX-hidden');  // hide the menu closing
        } else {
          // desktop
          if (oneX.$('.-oneX-header').hasClass('-oneX-header-limited')) {
            const langHref = oneX.$('#oneX-header').find('.-oneX-lang-link').find('a').attr('href');
            if (langHref && langHref !== '') {
              oneX.Header.animateUtil(oneX.$('.-oneX-header-util-nav li'), oneX.$(theButton).parent(), $mainMenu.parent(), false);
            } else {
              oneX.Header.animateUtil(oneX.$('.-oneX-header-util-nav li:not(.-oneX-lang-link)'), oneX.$(theButton).parent(), $mainMenu.parent(), false);
            }
            oneX.Header.animateContainer(oneX.$(menuID), "opacity", "1", "0", false);
          } else {
            if (oneX.$(theButton).hasClass('-oneX-header-close-X-button')) {
              oneX.$(".-oneX-header-util-menu-btn[aria-expanded='true']").attr('aria-expanded', 'false');
            } else {
              oneX.$(theButton).attr("aria-expanded", false);
            }
            oneX.$('.-oneX-header').removeClass('-oneX-search-open');
            oneX.Header.animateContainer(oneX.$(menuID), "height", "96px", "0", false);
          }

          setTimeout(function () {
            if (oneX.$('.-oneX-header').hasClass('-oneX-header-limited')) {
              // show all the buttons again
              oneX.$('.-oneX-header-util-nav li button').removeAttr('disabled');
              oneX.$('.-oneX-util-menu-icon-container').attr("aria-hidden", false);
              // hide "main menu"
              $mainMenu.parent().addClass('-oneX-hidden');
              oneX.Header.animateMainNavVerticalDesktop("up");
              oneX.$('.-oneX-header-user-nav').removeClass('-oneX-hidden');
              oneX.$('.-oneX-header-main-nav').removeClass('-oneX-hidden');
              // oneX.Header.animateContainer(oneX.$(".-oneX-header-util-nav"), "top", "-27px", "7px", true);
            }
            // set focus back to item who called
            if(oneX.$('.-oneX-header-util-menu-btn[data-for="' + $mainMenu.data("for") + '"]')[0]) {
              oneX.$('.-oneX-header-util-menu-btn[data-for="' + $mainMenu.data("for") + '"]')[0].focus();
            }

            setTimeout(function () {
              //oneX.$('.-oneX-header-nav-container').removeClass('-oneX-hidden');  // unhide main nav
              oneX.$('#oneX-submenu-search').detach().insertAfter('#oneX-submenu-help');

            }, oneX.Header.animationSpeed + 25);
          }, oneX.Header.animationSpeed + 25);

          // remove app-open modifier so logo shifts and header size shrinks via CSS
          oneX.$('.-oneX-header, .-oneX-header-logo-container').removeClass('-oneX-header-app-open');
        }
      }
    },

    animateUtil: function ($allLi, $clickedLi, $mainMenuLi, showMain) {
      oneX.$.each($allLi, function (index, obj) {
      // take this setTimeout out, if all items can animate at the same time.
      //  setTimeout(function () {
        if (showMain) {
          if (oneX.$(obj).data("width") === undefined) {
            oneX.$(obj).data("width", oneX.$(obj).width());
          }

          if (!oneX.$(obj).is($clickedLi) && !oneX.$(obj).is($mainMenuLi)) {
            oneX.Header.animateContainer(oneX.$(obj), "width", oneX.$(obj).data("width") + "px", "0", false);
          } else if (oneX.$(obj).is($mainMenuLi)) {
            oneX.Header.animateContainer(oneX.$(obj), "width", "0", oneX.$(obj).data("width") + "px", true);
          }
        } else {
          // hide main and show other items
          if (!oneX.$(obj).is($mainMenuLi)) {
            oneX.Header.animateContainer(oneX.$(obj), "width", "0", oneX.$(obj).data("width") + "px", true);
          } else {
            oneX.Header.animateContainer(oneX.$(obj), "width", oneX.$(obj).data("width") + "px", "0", false);
          }
        }
      //  }, 1 * (index));
      });
    },

    showTopNav: function (focusTo, animate = false) {
      const $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        $navContainer = oneX.$('.-oneX-header-nav-container'),
        $utilMenuContainer = oneX.$('.-oneX-header-utilmenu-container '),
        $openContainer = oneX.$('.-oneX-header-utilmenu-container:not(.-oneX-hidden)'),
        $mobileLogoContainer = oneX.$('.-oneX-header-logo-mobile'),
        $focused = oneX.$(':focus');
    
      let closeAnimationSpeed = 0;

      if (animate) {
        closeAnimationSpeed = oneX.Header.animationSpeed;
        if ($openContainer.length !== 0) {
          oneX.Header.animateContainer($openContainer, "opacity", "1", "0", false);
          setTimeout(function () {
            oneX.Header.animateMainNavVerticalDesktop("up");
            oneX.Header.animateContainer(oneX.$(".-oneX-header-util-nav"), "top", "-27px", "7px", true);
  
            oneX.Header.closeOpenMainMenu(closeAnimationSpeed);
            oneX.Header.closeUserSubmenu();

            setTimeout(function () {
              oneX.$('.-oneX-header-nav-container').removeClass('-oneX-hidden');
              // unhide main nav

            }, oneX.Header.animationSpeed + 25);
          }, oneX.Header.animationSpeed * 0.5);
        }
      }
      else {
        oneX.Header.closeOpenMainMenu(closeAnimationSpeed);
        oneX.Header.closeUserSubmenu();
      }

      //removing the spanish login class modifier 
      oneX.Header.clearUtilNavFields();
  
      setTimeout(function () {

        // hide any open container
        $utilMenuContainer.addClass('-oneX-hidden'); // hide all other menus
        oneX.$('.-oneX-header-util-nav li').removeClass('-oneX-hidden');
        oneX.$('.-oneX-header-util-nav li button').removeAttr('disabled');
        oneX.$(".-oneX-lang-link").removeClass("-oneX-hidden");
        oneX.Header.hideLimitedLangLink();
        oneX.$('.-oneX-header-top-chevron-btn[aria-expanded="true"]').removeClass('-oneX-header-top-chevron-visible');
        oneX.$('#oneX-header, .-oneX-header').removeAttr('style');
        oneX.$('.-oneX-header-main-nav, .-oneX-header-user-nav, .-oneX-header-top-menu-btn, .-oneX-header-util-nav, .-oneX-sub-navigation-menu').removeClass('-oneX-hidden');
        oneX.$(".-oneX-header-top-menu-btn, .-oneX-breadcrumbs").removeAttr('style');
        $navContainer.removeClass('-oneX-hidden -oneX-header-nav-show');
        oneX.$(".-oneX-header").removeClass('-oneX-search-open');
        oneX.$('.-oneX-header-menu-button').removeClass('-oneX-button--selected');
        $backContainer.addClass('-oneX-hidden');
        oneX.$('#oneX-header-main-menu-btn').parent().addClass('-oneX-hidden');

        oneX.$('.-oneX-header-top-menu-btn[aria-expanded="true"').attr('aria-expanded', 'false');
        $mobileLogoContainer.removeAttr('aria-hidden').removeAttr('tabindex');
        
        oneX.Header.removeScrim();
        $focused.blur() ; // close any drop-downs 

        if (focusTo) {
          oneX.$('.-oneX-header-util-menu-btn[data-for="' + focusTo + '"]').focus();
        }
        else if (oneX.Header.hamburgerClicked) {
          oneX.Header.hamburgerClicked = false;
          oneX.$('.-oneX-header-menu-button').focus();
        }
        else if (oneX.Header.userNavClicked) {
          oneX.Header.userNavClicked = false;
          oneX.$('.-oneX-header-user-nav-button').focus();
        }
      }, closeAnimationSpeed);
    },

    closeOpenMainMenu: function (speed) {
      // This will be called from Mobile and Desktop; on Desktop, 
      // Move any open oneX-submenu menu back to default
      const $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        $openContainer = oneX.$('.-oneX-header-submenu-container').parent('.-oneX-header-main-nav-list').find('.-oneX-header-submenu-container'),
        $header = oneX.$('#oneX-header'), 
        $menuAnchor = oneX.$('.-oneX-header-submenu-container').parent('.-oneX-header-main-nav-list').find('.-oneX-navmenu-anchor-has-submenu');

      let openHeight = 0;
      if ($openContainer.length > 0) {
        openHeight = $openContainer.height();
        $openContainer.detach().insertAfter($backContainer);  // put back any moved submenu containers

        if (oneX.Header.mobileDevice) {// if touch enabled device disable anchor  when closed
          oneX.$($menuAnchor).addClass('-oneX-disable-header-link');
        }

        oneX.$('.-oneX-header-spacer').attr('style','height: 0px');

        $openContainer.addClass('-oneX-hidden');
        $openContainer.removeAttr('style');
      }

      // put search container back if it is still in the li (was open on desktop)
      if (oneX.$('#oneX-submenu-search').parent().is(oneX.$('#oneX-submenu-search').parent('li'))) {
        oneX.$('#oneX-submenu-search').detach().insertAfter('#oneX-submenu-help');
      }

      // fall thru from Mobile it will still be un-hidden
      oneX.$('.-oneX-header-submenu-container').addClass('-oneX-hidden'); // hide all other main nav submenus
      // else {
      // console.log('1371 skipped removal');
      // }

      oneX.$('.-oneX-header-top-menu-btn').attr('aria-expanded', 'false');
      oneX.$('#oneX-header, .-oneX-header, .-oneX-header-logo-container').removeClass('-oneX-header-app-open');

      return openHeight;
    },

    toggleHamburgerMenu: function () {
      if (oneX.Header.alreadyClickedHamburger) {
        return;
      }

      oneX.Header.alreadyClickedHamburger = true;
      setTimeout(function () { oneX.Header.alreadyClickedHamburger = false; }, oneX.Header.animationSpeed);
       
      const $hamburgerButton = oneX.$('.-oneX-header-menu-button'),
        limitedHeader = oneX.$('.-oneX-header').hasClass("-oneX-header-limited"),
        authHeader = false, // no more auth menu inside hamburger -- // oneX.$('.-oneX-header').hasClass("-oneX-header-auth"),
        helpMenuDisabled = oneX.$('#helpCenterLink').hasClass('hidden-help-center'),
        langLinkDisabled = oneX.$('.-oneX-lang-link').hasClass('-oneX-lang-link-unavailable');

      if(limitedHeader && !authHeader && langLinkDisabled) {
        if ($hamburgerButton.hasClass('-oneX-button--selected')) {
          oneX.Header.hamburgerClicked = true;
          oneX.Header.closeHamburgerMenu();
        } else {
          oneX.$('.-oneX-header-utilmenu-container, .-oneX-header-submenu-container ').addClass('-oneX-hidden'); // hide all other menus
          oneX.$('.-oneX-header-navmenu-back-container').addClass('-oneX-hidden').removeAttr("data-returnto1");
          oneX.$('#oneX-submenu-help').attr("style", "top : " + oneX.Header.getHeaderHeight() + "px").removeClass('-oneX-hidden');
          oneX.$('.-oneX-header-nav-container').removeClass("-oneX-header-nav-show");
          oneX.Header.openHamburgerMenu();
        }
      } else if(limitedHeader && helpMenuDisabled && authHeader && langLinkDisabled) {
        if ($hamburgerButton.hasClass('-oneX-button--selected')) {
          oneX.Header.hamburgerClicked = true;
          oneX.Header.closeHamburgerMenu();
        } else {
          oneX.$('.-oneX-header-utilmenu-container, .-oneX-header-submenu-container ').addClass('-oneX-hidden'); // hide all other menus
          oneX.$('.-oneX-header-navmenu-back-container').addClass('-oneX-hidden').removeAttr("data-returnto1");
          oneX.$('.-oneX-header-nav-container').removeClass("-oneX-header-nav-show");
          oneX.Header.openHamburgerMenu();
        }
      } else {
        if ($hamburgerButton.hasClass('-oneX-button--selected')) {
          oneX.Header.hamburgerClicked = true;
          oneX.Header.subMenuClose();
          oneX.Header.closeHamburgerMenu();
        } else {
          oneX.$('.-oneX-header-nav-container').addClass('-oneX-header-nav-show');
          oneX.Header.openHamburgerMenu();
        }
      }
    },

    closeHamburgerMenu: function () {
      const $hamburgerButton = oneX.$('.-oneX-header-menu-button'),
        $breadcrumbs = oneX.$('.-oneX-breadcrumbs'),
        $mobileLogoContainer = oneX.$('.-oneX-header-logo-mobile');

      oneX.$('.-oneX-skipToMain-content').removeClass('-oneX-hidden'); //Unhides skip to main content link when menu button is clicked
      oneX.$('.-oneX-header-utilmenu-container, .-oneX-header-submenu-container').addClass('-oneX-hidden');
      oneX.$('#oneX-header').removeAttr('style');
      $hamburgerButton.removeClass('-oneX-button--selected');
      $hamburgerButton.attr('aria-expanded', 'false');
      $mobileLogoContainer.removeAttr('aria-hidden').removeAttr('tabindex');
      oneX.Header.animateMainNavVerticalMobile("up");
      $breadcrumbs.removeAttr('style');

    },

    openHamburgerMenu: function () {
      const $hamburgerButton = oneX.$('.-oneX-header-menu-button'),
        $mobileLogoContainer = oneX.$('.-oneX-header-logo-mobile');
  
      oneX.Header.closeUserSubmenu();
      oneX.$('.-oneX-skipToMain-content').addClass('-oneX-hidden'); //hides skip to main content link when menu button is clicked
      oneX.$('#oneX-header').attr('style', 'height: 100vh'); 
      oneX.$('.-oneX-breadcrumbs').attr('style', 'display: none'); 
      $hamburgerButton.addClass('-oneX-button--selected');
      $hamburgerButton.attr('aria-expanded', 'true');
      $mobileLogoContainer.attr('aria-hidden', 'true').attr('tabindex', '-1');
      oneX.Header.animateMainNavVerticalMobile("down");
    },


    backClick: function () {
      if (oneX.Header.dragging) {
        // if touchend was part of scrolling the screen
        return;
      }

      const $container = oneX.$('.-oneX-header-nav-container'),
        $backContainer = oneX.$('.-oneX-header-navmenu-back-container'),
        returnFocusId = $backContainer.attr('data-returnTo2');

      if (returnFocusId && returnFocusId.length > 0) {
        // if 2nd level menu is currently being displayed -- return to mid level
        oneX.Header.subMenuClose();
      }
      else {
        let $menu = oneX.$('.-oneX-header-submenu-container:not(.-oneX-hidden)');
        if ($menu.length > 0) {
        // mid level is currently displayed -- return to unhide top nav
          $container.addClass('-oneX-header-nav-show');
          oneX.Header.animateContainer($backContainer, "opacity", "1", "0", false);
          oneX.Header.animateMainNavHorizontal("right");
          oneX.Header.animateContainer(oneX.$('#' + $menu.attr('id')), "left", "0", oneX.$(window).width() + "px", false);
        }
        else {
          // try Util Container
          $menu = oneX.$('.-oneX-header-utilmenu-container:not(.-oneX-hidden)');
          if ($menu.length > 0) {
            oneX.Header.animateContainer($backContainer, "opacity", "1", "0", false);
            oneX.Header.animateMainNavVerticalMobile("down",false);
            oneX.Header.animateContainer($menu, "top", oneX.Header.getHeaderHeight() + "px", oneX.$(window).height() + "px", false);
          }
        }

        oneX.Header.clearUtilNavFields();
        if (!( window.matchMedia('(min-width: 768px)').matches)) {
          oneX.Header.subMenuClose();
        }

        // put focus on item that was clicked
        const menuShortName = $backContainer.attr('data-returnTo1');
        let $menuItem = oneX.$('button.-oneX-header-top-menu-btn[data-for="' + menuShortName + '"]');
        if ($menuItem.length === 0) {
          // try util
          $menuItem = oneX.$('.-oneX-header-util-menu-btn[data-for="' + menuShortName + '"]');
        }
        $backContainer.removeAttr('data-returnTo1');
        if ($menuItem.length > 0) {
          $menuItem.attr('aria-expanded', 'false');
  
          setTimeout(function () {
            $menuItem[0].focus();
          }, oneX.Header.animationSpeed + 25);
        }
      }
    },

    clearUtilNavFields: function () {
      // Clear out the field
      const $activeField = oneX.$('.-oneX-header-utilmenu-container ');
      $activeField.find('.-oneX-textfield--floating-input').val(''); //instead of entire utilmenu container identify a section
      $activeField.find('.-oneX-textfield__floatingLabel').removeClass('-oneX--floatUp');

    },

    hideLimitedLangLink: function () {
      // if help menu is enabled and lang link is enabled, then hide the lang-link in the login-logout container  -oneX-header-login-logout-containerremoveScrim
      const limitedHeader = oneX.$('.-oneX-header').hasClass("-oneX-header-limited"),
        helpMenuDisabled = oneX.$('#helpCenterLink').hasClass('hidden-help-center'),
        langLinkDisabled = oneX.$('.-oneX-lang-link').hasClass('-oneX-lang-link-unavailable');
      
      if (limitedHeader && !helpMenuDisabled && !langLinkDisabled) {
        oneX.$('.-oneX-header-mobile-tablet-nav-section').find('.-oneX-lang-link').addClass('-oneX-hidden');
      }
    },

    resetMenusOnResize: function () {
      if (oneX.$('body').width() < 991) {
        // oneX.$('.-oneX-header-top-menu-btn').removeAttr('aria-expanded');  // always don't need below 992 -- this was added with S-1151430, but now S-1219587 wants it back out
        oneX.Header.hideLimitedLangLink();
      }

      if (oneX.Header.prevWidth === null) {
        oneX.Header.prevWidth = oneX.$('body').width();
      }
      else {
        if ((oneX.Header.prevWidth < 992 && oneX.$('body').width() > 991) || (oneX.Header.prevWidth < 768 && oneX.$('body').width() > 767)) {
          if (oneX.$('.-oneX-header-menu-button').hasClass('-oneX-button--selected')) {
            // if we are flipping tablet from portrait (or width growing MD->LG) to landscape and the menu is open, then close all menus
            oneX.Header.showTopNav();
          }

        } else if ((oneX.Header.prevWidth > 991 && oneX.$('body').width() < 992) || (oneX.Header.prevWidth > 767 && oneX.$('body').width() < 768)) {
          oneX.$('.-oneX-header-top-menu-btn').attr('aria-expanded','false');
          
          if (oneX.$('.-oneX-header-utilmenu-container:not(.-oneX-hidden)').length > 0 
              || oneX.$('.-oneX-header-submenu-container').parent('li').find('.-oneX-header-submenu-container').length > 0 
              || oneX.$('.-oneX-header-navmenu-back-container').data('returnto1') === "shop"
              || oneX.$('.-oneX-header-user-nav button[aria-expanded="true"]').length > 0) {
            // if we are flipping tablet from landscape to portrait (or width shrinking LG->MD) and any util menu is open, then close all menus
            oneX.Header.showTopNav();
          }
        }
      }
      oneX.Header.prevWidth = oneX.$('body').width();
    },

    addScrim: function (desktop = false) {
      if (!desktop) { 
        oneX.Header.bodyBGColor = oneX.$("body").css('background-color');
        oneX.$("body").css("background-color", "#FFF"); // This is to override the default body background-color to #white (for the SFX pages)
      }

      const $header = oneX.$('#oneX-header');
      let $currentParent = $header.parent();

      oneX.$($header.prevAll().get().reverse()).wrapAll("<div class='-oneX-content-behind-scrim__header'></div>");
      $header.nextUntil().wrapAll("<div data-flyover-scrim class='-oneX-content-behind-scrim__header'></div>");

      while ($currentParent.prop('nodeName') !== "BODY") {
        oneX.$($currentParent.prevAll().get().reverse()).wrapAll("<div class='-oneX-content-behind-scrim__header'></div>");
        $currentParent.nextUntil().wrapAll("<div class='-oneX-content-behind-scrim__header'></div>");
        $currentParent = $currentParent.parent();
      }

      oneX.$('.-oneX-content-behind-scrim__header').attr('aria-hidden', 'true');
      
      if (desktop) {
        oneX.$('<div data-scrim-header class="-oneX--scrim__header"></div>').insertBefore('[data-flyover-scrim]');
      
        oneX.$('.-oneX--scrim__header').animate({opacity: 1}, oneX.Header.animationSpeed, 'swing');
        
        oneX.$('.-oneX-content-behind-scrim__header').on("focusin", oneX.Header.scrimClick);
        oneX.$('[data-scrim-header]').off("click", oneX.Header.scrimClick).on("click", oneX.Header.scrimClick);

        // oneX.$('html').addClass('-oneX-remove-second-scroll-bar');
      
        oneX.$('#oneX-header').addClass('-oneX-scrim-top__header');
      }
      
      oneX.$(".-oneX--scrim__header").on("click", function () {
        oneX.$(".-oneX-header__menu-button.-oneX-button--selected").click().focus();
      });
    },

    removeScrim: function () {
      const $header = oneX.$('#oneX-header');
      // remove all scrims
      while (oneX.$('.-oneX-content-behind-scrim__header').children().length > 0) {
        oneX.$(oneX.$('.-oneX-content-behind-scrim__header').children()[0]).unwrap();
      }

      oneX.$('.-oneX--scrim__header').animate({opacity: 0}, 395, 'swing', () => {oneX.$('.-oneX--scrim__header').remove();

        if (oneX.Header.bodyBGColor !== null) {
          oneX.$("body").css("background-color", oneX.Header.bodyBGColor);
        }

        oneX.$('#oneX-header').removeClass('-oneX-scrim-top__header');
      });
    },

    scrimClick: function (event) {
      const isTouchableDevice = 'ontouchstart' in document.documentElement;
      // combo is chevron and mobile? for checking on title
      const isDesktopMode = oneX.$('.-oneX-header-mobile-tablet-nav-section').css("display") === "none";

      if(event.type === 'keydown') {
        if ( !(event.key === "Enter" || event.key === " " || event.key === "Escape")) {
          return;
        }
      }

      const isCloseButton = oneX.$(event.currentTarget).hasClass('-oneX-header-close-X-button')

      // x button on tablet landscape when panel expanded don't nav on tap/click/keypress
      if (isCloseButton && (isDesktopMode || isTouchableDevice)) {
        event.preventDefault()
      }

      oneX.$('.-oneX-header-top-chevron-btn.-oneX-header-top-chevron-visible').focus();
      oneX.$('.-oneX-header-top-menu-btn[aria-expanded = true').trigger(oneX.$.Event('keydown', { key: 'Enter' }));
    },

    toggleBreadcrumbs: function (force = false) {
      if (force || oneX.$('.-oneX-breadcrumbs').hasClass('-oneX-hidden')) {
        // turn on
        oneX.$('.-oneX-breadcrumbs').removeClass('-oneX-hidden'); 
      }
      else {
        // turn off
        oneX.$('.-oneX-breadcrumbs').addClass('-oneX-hidden'); 
      }
    },

    setBreadcrumbs: function (jsonBreadcrumbs) {

      // Pass a JSON that looks like this to set the breadcrumbs
      //   {
      //     "breadcrumbs" : [
      //         {
      //             "name":"Finances",
      //             "url":"https://www-sandbox.test.statefarm.com/finances"
      //         },{
      //             "name":"Banking",
      //             "url":"https://www-sandbox.test.statefarm.com/finances/banking"
      //         }
      //     ]
      //   }
      //
      // Call like: oneX.Header.setBreadcrumbs(myJsonString);

      // handle either a string or an object directly 
      const linksJson = typeof(jsonBreadcrumbs) === 'string' ? JSON.parse(jsonBreadcrumbs) : jsonBreadcrumbs;
      let breadCrumbsListItems = '<ul class="-oneX-breadcrumbs-list">';
      const lastOne = linksJson.breadcrumbs.length;
      oneX.$.each(linksJson.breadcrumbs, 
        function (index, obj) {
          if(index < lastOne - 1) {
            breadCrumbsListItems += '<li class="-oneX-breadcrumbs-item"><a href="';
            breadCrumbsListItems += obj.url  + '" class="-oneX-breadcrumbs-link"> <span class="-oneX-breadcrumbs-link-name">';
            breadCrumbsListItems += obj.name + '</span> <span class="-oneX-breadcrumbs-chevron"></span> </a></li>';
          }
          else {
            breadCrumbsListItems += '<li class="-oneX-breadcrumbs-item"><a class="-oneX-breadcrumbs-link">'
            breadCrumbsListItems += obj.name;
            breadCrumbsListItems += '</a> </li>';
          }
        }
      );
      breadCrumbsListItems += '</ul>'
      oneX.$('.-oneX-breadcrumbs-list').replaceWith(oneX.$(breadCrumbsListItems));
    },

    kiboHeaderButton: null,
    kiboFooter: null,
    prevWidth: null,
    animationSpeed: 350,
    initialUtilHover: true,
    alreadyClickedMenu: false,
    alreadyClickedUtil: false,
    alreadyClickedHamburger: false,
    dragging: false,
    debouncedResetMenusOnResize: null,
    bodyBGColor: null,
    hamburgerClicked: false,
    userNavClicked: false,
    mainNavOpen: false,
    hoverTimeoutID: ''
  };

  oneX.Config.queues(_COMPONENT);
})();
