(function () {
  const _COMPONENT = {
    name: "SubNav",
    selectors: '.-oneX-subnav-container, [data-type="1x-subnav"]',
  };

  oneX.SubNav = {
    init: function (callback) {
      const _self = this;
      _self._bindEvents(oneX.$(_COMPONENT.selectors), callback);
      return this;
    },

    addElement: function (_element) {
      this._bindEvents(_element);
    },

    removeElement: function (_element) {
      this._unbindEvents(_element);
    },

    _bindEvents: function (_element, callback) {
      const _self = this;

      if (_element.data("type") && _element.is(":empty")) {
        _self._renderHTML(_element, callback);
      } else if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        _self._swapSubnavSections();
        _self.throttled_handleScroll = oneX.Util.throttle.throttle(
          _self._handleScroll,
          50,
          true
        );

        oneX.$(window).on("scroll", _self.throttled_handleScroll);
        oneX.$(window).on("resize", _self._swapSubnavSections);
        _element
          .find(".-oneX-explore-box button")
          .on("click", _self._toggleMenu);
        oneX.$("#getaquote-state-select").on("change", _self.checkValidState);
        oneX.$("#getaquote-zip").on("blur", _self._checkValidZipCode);
        oneX.$(".-oneX-getaquote-form").on("submit", _self._getQuoteFormSubmit);
        oneX.$("body").on("focusin", _self._checkToCloseMenu);
        oneX.$("body").on("click", _self._checkToCloseMenu);

        oneX.SubNav._getQuoteInfo();

        if (oneX.SubNav.defaultNav) {
          oneX.$(".-oneX-quote-box button").attr("disabled", "");
          oneX.$(".-oneX-quote-box input").attr("disabled", "");
          oneX.$(".-oneX-quote-box select").attr("disabled", "");
        } else {
          oneX.$(".-oneX-quote-box").addClass("oneX-subnav-details");
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    _unbindEvents: function (_element) {
      const _self = this;

      oneX.$(window).off("scroll", _self.throttled_handleScroll);
      oneX.$(window).off("resize", _self._swapSubnavSections);
      _element
        .find(".-oneX-explore-box button")
        .off("click", _self._toggleMenu);
      oneX.$("#getaquote-state-select").off("change", _self.checkValidState);
      oneX.$("#getaquote-zip").off("blur", _self._checkValidZipCode);
      oneX.$(".-oneX-getaquote-form").off("submit", _self._getQuoteFormSubmit);
      oneX.$("body").off("focusin", _self._checkToCloseMenu);
      oneX.$("body").off("click", _self._checkToCloseMenu);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    // Swap the subnav order of the drop down and form when in desktop mode
    // in order to have the proper tab order.
    // Swap them back in tablet/mobile if they aren't in the correct order.
    _swapSubnavSections: function () {
      const $exploreBox = oneX.$("#subnav_explore");
      const $quoteBox = oneX.$("#subnav_quote");

      if (window.innerWidth >= 768) {
        $quoteBox.detach().insertAfter($exploreBox);
      } else if (window.innerWidth < 768) {
        $exploreBox.detach().insertAfter($quoteBox);
      }
    },

    _checkToCloseMenu: function (event) {
      const $button = oneX.$(".-oneX-explore-box button");

      //if event target is not a subnav element, close the subnav
      if (
        oneX.$(event.target).closest(".-oneX-explore-box").length === 0 &&
        $button.attr("aria-expanded") === "true"
      ) {
        $button.click();
      }
    },

    _toggleMenu: function (event) {
      const $button = oneX.$(event.currentTarget);
      const $exploreBox = $button.closest(".-oneX-explore-box");

      if ($button.attr("aria-expanded") === "true") {
        $exploreBox.removeClass("-oneX-is-open");
        $button.attr("aria-expanded", "false");
      } else {
        $exploreBox.addClass("-oneX-is-open");
        $button.attr("aria-expanded", "true");
      }
    },

    _handleScroll: function (event) {
      if (oneX.$(".-oneX-subnav-container").length > 0) {
        const $subNavContainer = oneX.$(".-oneX-subnav-container");
        const $subNavContent = $subNavContainer.find(".-oneX-subnav-content");
        const stickyClass = "-oneX-subnav--sticky";
        const containerClass = "-oneX-container";
        const $subnavButton = oneX.$(".-oneX-quote-box button");
        const $subnavInput = oneX.$(".-oneX-quote-box input");
        const $subnaDrop = oneX.$(".-oneX-quote-box select");
        const $form = oneX.$(".-oneX-getaquote-form");
        const below400Zoom = window.devicePixelRatio < 4;
        const buttonPos = $subnavButton.offset().top;
        const inputPos = $subnavInput.offset().top;
        const dropPos = $subnaDrop.offset().top;
        const isNotWrapped = buttonPos === inputPos || buttonPos === dropPos;
        const allowSticky = below400Zoom ? true : isNotWrapped;

        if (oneX.SubNav.prevScroll === -1) {
          // recalibrate position
          oneX.SubNav.subnavPos = $subNavContainer.offset().top;
        }

        if (window.scrollY > oneX.SubNav.subnavPos && allowSticky) {
          $subNavContainer.addClass(stickyClass);
          $subNavContent.addClass(containerClass);
          if (oneX.SubNav.defaultNav) {
            $subnavButton.removeAttr("disabled");
            $subnavInput.removeAttr("disabled");
            $subnaDrop.removeAttr("disabled");
          }
          oneX.SubNav._adjustStickyDropPosition();
          if (oneX.$(".-oneX-subnav-spacer").length === 0) {
            oneX
              .$("body")
              .prepend(
                '<div class="-oneX-subnav-spacer" style="height: 82px;"></div>'
              );
          }
        } else {
          $subNavContainer.removeClass(stickyClass);
          $subNavContent.removeClass(containerClass);
          if (oneX.SubNav.defaultNav) {
            $subnavButton.attr("disabled", "");
            $subnavInput.attr("disabled", "");
            $subnaDrop.attr("disabled", "");
          }
          $form.removeClass("-oneX-widget--err");
          oneX.TextfieldErrors.removeError($subnavInput);
          oneX.TextfieldErrors.removeError($subnaDrop);
          oneX.$(".-oneX-subnav-spacer").remove();
        }

        oneX.SubNav.prevScroll = window.scrollY;
      }
    },

    _getQuoteFormSubmit: function (event) {
      const $getZipcode = oneX.$("#getaquote-zip");
      const $stateSelect = oneX.$("#getaquote-state-select");

      let isError = false;

      if (!/^\d{5}$/.test($getZipcode.val()) && $getZipcode.is(":visible")) {
        oneX.SubNav._addErrorMessages(
          $getZipcode,
          oneX.SubNav._validateNonEmptyFields($getZipcode) ? "format" : "empty"
        );
        oneX.SubNav._adjustStickyDropPosition();
        isError = true;
      }

      if ($stateSelect.val() === "" && $stateSelect.is(":visible")) {
        oneX.TextfieldErrors.addError($stateSelect);
        oneX.SubNav._adjustStickyDropPosition();
        isError = true;
      }

      if (isError) {
        return false;
      } else {
        document.dispatchEvent(
          new CustomEvent("intentQuoteEventAA", {
            detail: {
              applicationName: oneX.SubNav.lineOfBusiness,
              postalCode: $getZipcode.val(),
              state: $stateSelect.val(),
            },
          })
        );
      }

      if (oneX.SubNav.intent === "quote") {
        event.preventDefault();
        event.stopPropagation();
        oneX.SubNav._launchQuoteApp();
      }
    },

    _getAppLauncherURL: function () {
      const launcherURL = "ApplicationRouter-web/app/launch";
      const localhost = /localhost?[\.|\:]?\S*/.test(
        oneX.Util.location.hostname()
      );

      let url = "https://apps.statefarm.com/" + launcherURL;

      if (localhost) {
        url = "https://apps-env5.test.statefarm.com/" + launcherURL;
      } else if (oneX.Util.environment.isTest()) {
        let lane = oneX.Util.environment.lane();

        if (lane !== "") {
          lane = "-" + lane;
        }
        url =
          "https://apps-" +
          oneX.Util.environment.location() +
          lane +
          ".test.statefarm.com/" +
          launcherURL;
      }

      return url;
    },

    _launchQuoteApp: function () {
      const $getZipcode = oneX.$("#getaquote-zip");
      const baseURL = oneX.SubNav._getAppLauncherURL();

      const appLauncherInput = {
        applicationName: oneX.SubNav.lineOfBusiness,
        language: oneX.Util.environment.language(),
        postalCode: $getZipcode.val(),
        stateAbbreviation: oneX.$("#getaquote-state-select").val(),
      };

      oneX.$.ajax({
        type: "GET",
        url: baseURL,
        data: appLauncherInput,
        dataType: "jsonp",
        async: true,
        cache: false,

        success: function (responseData) {
          oneX.SubNav._processResults(responseData);
        },

        error: function () {
          console.info("Error Processing ");

          oneX.SubNav._addErrorMessages($getZipcode, "error");
        },
      });
    },

    _checkValidZipCode: function () {
      const $getZipcode = oneX.$("#getaquote-zip");
      const $form = $getZipcode.closest("form");
      const isZipCodeVisible = $getZipcode.is(":visible");

      if (isZipCodeVisible) {
        if (oneX.SubNav._validateZipCodeInput($getZipcode)) {
          $form.removeClass("-oneX-widget--err");
        } else {
          $form.addClass("-oneX-widget--err");
        }
      }
    },

    _validateZipCodeInput: function ($input) {
      if ($input.val().length > 0 && !/^\d{5}$/.test($input.val())) {
        oneX.SubNav._addErrorMessages($input, "format");
        oneX.SubNav._adjustStickyDropPosition();
      } else {
        oneX.TextfieldErrors.removeError($input);
        return true;
      }
    },

    _validateNonEmptyFields: function ($input) {
      const inputValue = $input.val();

      if (inputValue.replace(/[)( ]/g, "").length == 0) {
        oneX.SubNav._addErrorMessages($input, "empty");
        oneX.SubNav._adjustStickyDropPosition();
        return false;
      }
      oneX.TextfieldErrors.removeError($input);
      return true;
    },

    _getQuoteInfo: function () {
      const quoteText = oneX.Util.locale.isSpanish()
        ? "Comienza una cotización"
        : "Start a quote";
      const agentText = oneX.Util.locale.isSpanish()
        ? "Localiza a un agente"
        : "Find an Agent";

      if (oneX.SubNav.intent === "agent") {
        oneX.$("#getaQuoteButton").html(agentText);
      } else {
        oneX.$("#getaQuoteButton").html(quoteText);
      }

      if (oneX.Util.locale.isSpanish()) {
        oneX.$("#getaQuoteButton").addClass("-oneX-sp-subnav");
      }

      if (oneX.SubNav.inputType === "state") {
        oneX.$(".-oneX-getaquote-state-select").removeClass("-oneX-hidden");
      } else {
        oneX.$(".-oneX-getaquote-zip").removeClass("-oneX-hidden");
      }
    },

    _processResults: function (data) {
      const $getZipcode = oneX.$("#getaquote-zip");
      const status = data.invalid ? "invalid" : "error";

      if (data !== null && data.result.toString() === "true") {
        window.location = data.RedirectURL;
      } else {
        oneX.SubNav._addErrorMessages($getZipcode, status);
      }
    },

    _adjustStickyDropPosition: function () {
      const $subNavContainer = oneX.$(".-oneX-subnav-container");
      const $exploreBox = $subNavContainer.find(".-oneX-explore-box");
      const $subnavBox = oneX.$(".-oneX-quote-box");
      const $form = oneX.$(".-oneX-getaquote-form");
      if (
        window.matchMedia("(max-width: 767px)").matches &&
        $subNavContainer.hasClass("-oneX-subnav--sticky")
      ) {
        $exploreBox.css("top", $subnavBox.height());
      }
    },

    _addErrorMessages: function ($input, type) {
      let message = "";

      switch (type) {
        case "format":
          message = oneX.Util.locale.isSpanish()
            ? oneX.SubNav.wrongFormatMsgEsp
            : oneX.SubNav.wrongFormatMsgEng;
          break;
        case "error":
          message = oneX.Util.locale.isSpanish()
            ? oneX.SubNav.systemUnavailableMsgEsp
            : oneX.SubNav.systemUnavailableMsgEng;
          break;
        case "empty":
          message = oneX.Util.locale.isSpanish()
            ? oneX.SubNav.emptyMsgEsp
            : oneX.SubNav.emptyMsgEng;
          break;
        case "invalid":
          message = oneX.Util.locale.isSpanish()
            ? oneX.SubNav.invalidMsgEsp
            : oneX.SubNav.invalidMsgEng;
          break;
      }

      oneX.TextfieldErrors.addError($input, message);
    },

    _renderHTML: function (_element, callback) {
      const lob = _element.data("json").lob;
      const icon = _element.data("json").icon;
      const explore = _element.data("json").explore;
      const defaultNav = _element.data("json").default;
      const intent = _element.data("json").intent;
      const inputType = _element.data("json").inputType;
      const locale = oneX.Util.locale.isSpanish() ? "/es_US" : "/en_US";
      const staticLocale =
          oneX.Util.environment
            .getStaticHostName()
            .replace(/(:?)([\/\\]+)/g, function (_, d, s) {
              return d ? d + s : "/";
            }) + locale,
        port = oneX.Util.location.port();

      const isLocalTestHarness =
        ((port === "4200" || port === "3001") &&
          /onex-testharness/gi.test(oneX.Util.location.pathname())) ||
        ((port === "4200" || port === "4201") &&
          oneX.$("head title").html() === "1x Test Harness Angular") ||
        (port === "8000" &&
          oneX.$("head title").html() === "1x Test Harness React");
      const segmentSection = oneX.$('script[src*="1x.fcore.js"]').attr("src");

      let localPath = "http://localhost:3000";

      if (segmentSection !== undefined && segmentSection !== null) {
        const colon3000 = segmentSection.indexOf(":3000");
        if (colon3000 > -1) {
          localPath = segmentSection.substr(0, colon3000 + 5);
        }
      }

      const subnav = isLocalTestHarness
        ? localPath + "/html/component" + locale
        : staticLocale +
          "/dxl-1x/" +
          oneX.Util.location.getVersion() +
          "/renders/component";

      _element.load(subnav + "/1x-subnav.html", function (response, status) {
        if (status === "success") {
          if (isLocalTestHarness) {
            _element.html(
              _element
                .html()
                .replace(/{{domain_root}}/g, staticLocale)
                .replace(/{{www_root}}/g, oneX.Util.location.hostname())
            );
          }

          _element.find(".-oneX-subnav-icon").attr("data-icon", icon);
          oneX.SubNav.lineOfBusiness = lob;
          oneX.SubNav.defaultNav = defaultNav;
          oneX.SubNav.intent = intent;
          oneX.SubNav.inputType = inputType;

          const $exploreList = _element.find(".-oneX-subnav-explore");
          explore.map((item) => {
            if (locale === "/es_US") {
              $exploreList.append(`
              ${
                item.currentPage
                  ? `<li class= -oneX-subnav-current-page-link><a tabindex=0 aria-current="page"><span>${item.spanish}</span></a></li>`
                  : `<li><a href=${item.spanishUrl} aria-description="Go to ${item.spanish} Page"><span>${item.spanish}</span></a></li>`
              }
              `);
            } else {
              $exploreList.append(`
              ${
                item.currentPage
                  ? `<li class= -oneX-subnav-current-page-link><a tabindex=0 aria-current="page"><span>${item.name}</span></a></li>`
                  : `<li><a href=${item.url} aria-description="Go to ${item.name} Page" ><span>${item.name}</span></a></li>`
              }
              `);
            }
          });

          _element.children(":first").unwrap();
          oneX.SubNav.init();
          oneX.FloatingLabel.init();
          oneX.Icons.init();

          if (callback && typeof callback === "function") {
            callback();
          }
        } else {
          console.debug("Error with loading subnav " + response);
        }
      });
    },

    prevScroll: -1,
    subnavPos: 0,
    lineOfBusiness: null,
    defaultNav: null,
    inputType: null,
    intent: null,
    systemUnavailableMsgEng: "System Unavailable",
    systemUnavailableMsgEsp: "El sistema no está disponible",
    wrongFormatMsgEng: "Incorrect format. Enter a valid 5-digit US ZIP Code.",
    wrongFormatMsgEsp:
      "Formato incorrecto Ingresa un código postal válido de 5 dígitos en los Estados Unidos.",
    emptyMsgEng: "Empty field. Answer to continue.",
    emptyMsgEsp: "Casilla vacía. Contesta para continuar.",
    invalidMsgEng: "Enter a valid 5 digit ZIP Code.",
    invalidMsgEsp: "Ingresa un C.P válido de 5 dígitos.",
  };

  oneX.Config.queues(_COMPONENT);
})();
