(function () {
  oneX.Util.componentWithScrim = {

    showScrim: function ($visibleComponent, scrimType) {
      const $content = oneX.$('body').children().not('iframe, script, .-oneX-scrim--ignore');

      $visibleComponent.before('<div class="-oneX-visible-component-placeholder__' + scrimType + '"></div>');
      oneX.Util.componentWithScrim.visibleComponent[scrimType] = $visibleComponent;

      oneX.$($content).wrapAll("<div class='-oneX-content-behind-scrim__" + scrimType + "' aria-hidden='true'></div>");
      $visibleComponent.detach().appendTo(document.body);
      oneX.$('<div data-scrim-' + scrimType + ' class="-oneX--scrim__' + scrimType + '"></div>').insertBefore('.-oneX-content-behind-scrim__' + scrimType);

      $visibleComponent.addClass("-oneX-scrim-top__" + scrimType).show();
      oneX.$('.-oneX--scrim__' + scrimType).addClass("-oneX--scrim__on");
      if (oneX.$('.-oneX-content-behind-scrim__' + scrimType).height() < window.screen.height + 100) {
        oneX.$('.-oneX-content-behind-scrim__' + scrimType).height(window.screen.height + 100); // push horizontal scroll bar off the screen height on short content screens
      }
    },

    removeScrim: function (scrimType, callback, spa_reset_only) {
      const contentBehindScrimArray = oneX.$('.-oneX-content-behind-scrim__' + scrimType).children(),
        delay = (scrimType === 'drawer' && !spa_reset_only) ? 600 : 0;
      oneX.$('.-oneX-content-behind-scrim__' + scrimType + '.-oneX--scrim__on').removeClass("-oneX--scrim__on");

      setTimeout(function () {
        if (contentBehindScrimArray.length > 0) {
          oneX.$(contentBehindScrimArray[0]).unwrap();
        }
        oneX.$('div[data-scrim-' + scrimType + ']').remove();
        oneX.$('.-oneX-scrim-top__' + scrimType).removeClass("-oneX-scrim-top__" + scrimType).hide();

        const $placeholder = oneX.$('.-oneX-visible-component-placeholder__' + scrimType);
        if ($placeholder.length > 0) {
          oneX.Util.componentWithScrim.visibleComponent[scrimType].detach().insertAfter($placeholder);
          $placeholder.remove();
          oneX.Util.componentWithScrim.visibleComponent[scrimType] = null;
        } else if (oneX.Util.componentWithScrim.visibleComponent[scrimType]){
          oneX.Util.componentWithScrim.visibleComponent[scrimType].remove();
        }

        if(typeof callback !== 'undefined') {
          callback();
        }
      }, delay);
    },

    headerKeyDown: function (event) {
      if (event.shiftKey && event.which === 9) {
        event.preventDefault();
        oneX.$('.-oneX-scrim-top__' + event.data.scrimType).find('button.-oneX-close-X-button').focus();
      }
    },

    scrimFocusIn: function (event) {
      const focused = event.target;
      const scrimType = event.data.scrimType;
      const $tabbable = oneX.$('.-oneX-content-behind-scrim__' + scrimType).find(oneX.Util.componentWithScrim.tabbable);
      event.preventDefault();
      //Keep focus on Session timeout model on click of Scrim
      if ((oneX.$('[data-session-timeout]:visible').length > 0) && (scrimType !== "sessionTimeOut")) {
        const $closeBtn = oneX.$('.-oneX-scrim-top__sessionTimeOut').find('button.-oneX-close-X-button');
        $closeBtn.focus();
        return;
      }

      const $closeBtn = oneX.$('.-oneX-scrim-top__' + scrimType).find('button.-oneX-close-X-button');

      if ($closeBtn.length == 0 || ($tabbable[0] && focused == $tabbable[0]) || ($tabbable[1] && focused == $tabbable[1])) {
        const $container = oneX.$('.-oneX-scrim-top__' + scrimType);
        const $containerTabbable = $container.find(oneX.Util.componentWithScrim.tabbable);
        $containerTabbable && $containerTabbable[0].focus();
      } else {
        $closeBtn.focus();
      }
    },

    topComponentFocusIn: function (event) {
      if (oneX.$(event.target).hasClass('-oneX-scrim-top__' + event.data.scrimType)) {
        // Firefox likes to focus on the drawer itself
        event.preventDefault();
        oneX.$(event.target).find('button.-oneX-close-X-button').focus();
      }
    },

    firstTabbableKeyDown: function (event) {
      if (event.shiftKey && event.which === 9 && event.data.scrimType !== 'modal' && event.data.scrimType !== 'sessionTimeOut') {
        // modal/sessionTimeOut has it's own tab handler, so don't do it here.
        event.preventDefault();
        oneX.$('.-oneX-scrim-top__' + event.data.scrimType).find('button.-oneX-close-X-button').focus();
      }
    },

    visibleComponent: [],
    tabbable: 'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
  };
})();
