(function () {
  const _COMPONENT = {
    name: 'Carousel',
    selectors: '.-oneX-carousel-container'
  };

  oneX.Carousel = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        // binds go here
        _element.find('.-oneX-carousel-skip a').on("focusin", _self.focusSkipCarousel);
        _element.find('.-oneX-carousel-skip a').on('focusout', _self.hideSkipCarousel);

        _element.find('.-oneX-carousel-strip').on("touchstart", _self.handleTouchStart);
        _element.find('.-oneX-carousel-strip').on("touchmove", _self.handleTouchMove);
        _element.find('.-oneX-carousel-strip').on("touchend", _self.handleTouchEnd);
        _element.find('.-oneX-carousel-buttons button:first-of-type').on("click", _self.navigateLeftClick);
        _element.find('.-oneX-carousel-buttons button:last-of-type').on("click", _self.navigateRightClick);

        _element.find('.-oneX-carousel-buttons button').attr('tabindex', '0');
        _element.find('.-oneX-carousel-buttons button:first-of-type').attr('aria-label','Select arrow for left navigation.');
        _element.find('.-oneX-carousel-buttons button:last-of-type').attr('aria-label','Select arrow for right navigation.');
        _element.find('.-oneX-carousel-buttons button:first-of-type').html('<span class="-oneX-clipped">Navigate Left</span>');
        _element.find('.-oneX-carousel-buttons button:last-of-type').html('<span class="-oneX-clipped">Navigate Right</span>');
        _element.find('.-oneX-carousel-buttons').prepend('<div class="-oneX-clipped" aria-live="polite" tabindex="-1"></div>');

        _self.debouncedResize = oneX.Util.debounce.debounce(_self.resize, 250);

        if (!oneX.$("body").hasClass("-oneX-carousel-resize-bound")){
          oneX.$(window).on("resize", _self.debouncedResize);
          oneX.$("body").addClass("-oneX-carousel-resize-bound");
        }


        _element.data('cardWidth', _element.find('.-oneX-carousel-card').outerWidth());
        _element.data('gutterWidth', '15'); // default
        _element.data('index', 2);  // 0-index
        _element.data('numCards', _element.find('.-oneX-carousel-card').length);
        _element.data('totalWidth', ((_element.data('numCards') + 6) * 570));  // 570 is max card width (so we don't have to worry about it getting too narrow)
        _element.find('.-oneX-carousel-strip').css('width', _element.data('totalWidth') + 'px');

        // clone last two cards before first card
        const $first = oneX.$(_element.find('.-oneX-carousel-card')[0]),
          $second = oneX.$(_element.find('.-oneX-carousel-card')[1]),
          $third = oneX.$(_element.find('.-oneX-carousel-card')[2]),
          $fourth = oneX.$(_element.find('.-oneX-carousel-card')[3]),
          $secondLast = oneX.$(_element.find('.-oneX-carousel-card')[eval(_element.data('numCards') - 2)]),
          $last = oneX.$(_element.find('.-oneX-carousel-card')[eval(_element.data('numCards') - 1)]);

        if (_element.find('.-oneX-carousel-card').length < 4) {
              console.error('not enough cards to create a carousel');
        }
        else {
          $fourth.clone().addClass('-oneX-carousel-card-3').insertAfter($last);
          $third.clone().addClass('-oneX-carousel-card-2').insertAfter($last);
          $second.clone().addClass('-oneX-carousel-card-1').insertAfter($last);
          $first.clone().addClass('-oneX-carousel-card-0').insertAfter($last);
          $secondLast.clone().addClass('-oneX-carousel-card-' + eval(_element.data('numCards') - 2)).insertBefore($first);
          $last.clone().addClass('-oneX-carousel-card-' + eval(_element.data('numCards') - 1)).insertBefore($first);
        }

        // position to first card
        oneX.Carousel.positionToCard(_element.find('.-oneX-carousel-strip'), _element.data(), _element.data('index'), true);
        //_element.find('.-oneX-carousel-strip').css('transform', 'translate3d(-' + eval(eval(_element.data('cardWidth') * 2) + eval(_element.data('gutterWidth'))) + 'px,0,0)');

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      // unbinds here
      _element.find('.-oneX-carousel-skip a').off("focusin", _self.focusSkipCarousel);
      _element.find('.-oneX-carousel-skip a').off('focusout', _self.hideSkipCarousel);

      _element.find('.-oneX-carousel-strip').off("touchstart", _self.handleTouchStart);
      _element.find('.-oneX-carousel-strip').off("touchmove", _self.handleTouchMove);
      _element.find('.-oneX-carousel-strip').off("touchend", _self.handleTouchEnd);
      _element.find('.-oneX-carousel-buttons button:first-of-type').off("click", _self.navigateLeftClick);
      _element.find('.-oneX-carousel-buttons button:last-of-type').off("click", _self.navigateRightClick);

      _element.find('.-oneX-carousel-buttons button').removeAttr('tabindex');
      _element.find('.-oneX-carousel-buttons button').removeAttr('aria-label');
      _element.find('.-oneX-carousel-buttons button').html('');
      _element.find('.-oneX-carousel-buttons').find('.-oneX-clipped').remove();

      oneX.$(window).off("resize", _self.debouncedResize);

      // remove the clones
      _element.find('[class*="-oneX-carousel-card-"]').remove();
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    resize: function (event) {
      oneX.$(_COMPONENT.selectors).each(function () {
        const $container = oneX.$(this),
        $data = $container.data(),
        $strip = $container.find('.-oneX-carousel-strip'),
        newWidth = $container.find('.-oneX-carousel-card').outerWidth();

        if ($data.cardWidth !== newWidth) {
          // $container.data('totalWidth', ($data.numCards + 4) * newWidth);
          // $strip.css('width', $data.totalWidth);
          $container.data('cardWidth', newWidth); 
        }

        oneX.Carousel.positionToCard($strip, $data, $data.index, true); 

      });
    },

    positionToCard: function ($strip, $data, index, animate) {
      const $container = $strip.closest('.-oneX-carousel-container'),
        $aria = oneX.$($container.find('.-oneX-carousel-buttons').find('.-oneX-clipped')[0]);
      let centerAdjust = 0;
      let gridGutter = 15;

      if ($container.hasClass('-oneX-full-width')) {
        if (window.matchMedia('(max-width: 767px)').matches) {
          gridGutter = 24;
        }
        if (window.matchMedia('(max-width: 575px)').matches) {
          gridGutter = 15;  // actual IOS needs 15 here; chrome on desktop looks better with 7 (if MAC scroll bar)
        }
        centerAdjust = (($container.outerWidth() % $data.cardWidth / 2) + gridGutter);
        if (window.matchMedia('(max-width: 375px)').matches) {
          centerAdjust = ((window.innerWidth - $data.cardWidth) / 2 + gridGutter);
        }
      } else if (window.matchMedia('(max-width: 575px)').matches) {
        centerAdjust = (($container.outerWidth() % $data.cardWidth / 2) + gridGutter);
        if (window.matchMedia('(max-width: 424px)').matches) {
          centerAdjust = (window.innerWidth - $data.cardWidth) / 2;
        }
      }

      $container.find('.-oneX-carousel-card').each(function (ind, element) {
        if (index === ind) {
          oneX.$(element).attr('aria-hidden', 'false');
          oneX.$(element).attr('tabindex', '0');
        } else {
          oneX.$(element).attr('aria-hidden', 'true');
          oneX.$(element).attr('tabindex', '-1');
        }

      });


      if (animate) {
        const content = $container.find('.-oneX-carousel-text').length > 0 ? " current slide content: " + $container.find('.-oneX-carousel-text')[index].innerHTML : "";
        $aria.html("Current slide image: " + oneX.$($container.find('img')[index]).attr('alt') + content);
      }

      if (animate) {
        // console.log('animate to: ' + index, 'centerAdjust: ' + centerAdjust);
        $strip.addClass('-oneX-carousel-animate').css('transform', 'translate3d(-' + (index * $data.cardWidth + eval($data.gutterWidth) - centerAdjust) + 'px,0,0)');
      } else {
        // console.log('no animate to: ' + index, 'centerAdjust: ' + centerAdjust);
        $strip.removeClass('-oneX-carousel-animate').css('transform', 'translate3d(-' + (index * $data.cardWidth + eval($data.gutterWidth) - centerAdjust) + 'px,0,0)');
      }
    },

    navigateLeftClick: function (event) {
      const $container = oneX.$(event.target).closest('.-oneX-carousel-container'),
        $data = $container.data(),
        $strip = $container.find('.-oneX-carousel-strip');

      if ($data.index > 0) {
        $data.index--;
      }

      if ($data.index === 1) {
        // first logic
        oneX.Carousel.positionToCard($strip, $data, $data.index, true);
        setTimeout(function() {
          oneX.Carousel.positionToCard($strip, $data, $data.numCards + 1, false);
          $data.index = $data.numCards + 1;
        }, 300);
      } else if ($data.index === 0) {
        // last logic
        oneX.Carousel.positionToCard($strip, $data, $data.numCards + 1, false);
        setTimeout(function() {
          oneX.Carousel.positionToCard($strip, $data, $data.numCards, true);
          $data.index = $data.numCards;
        }, 300);
      } else {
        // normal move left
        oneX.Carousel.positionToCard($strip, $data, $data.index, true);
      }
    },

    navigateRightClick: function (event) {
      const $data = oneX.$(event.target).closest('.-oneX-carousel-container').data(),
        $strip = oneX.$(event.target).closest('.-oneX-carousel-container').find('.-oneX-carousel-strip');

      if ($data.index < $data.numCards + 2) {
        $data.index++;
      }

      if ($data.index === $data.numCards + 1) {
        // last logic
        oneX.Carousel.positionToCard($strip, $data, $data.index, true);
        setTimeout(function() {
          oneX.Carousel.positionToCard($strip, $data, 1, false);
          $data.index = 1;
        }, 300);
      } else if ($data.index === $data.numCards + 2) {
        // first logic
        oneX.Carousel.positionToCard($strip, $data, 1, false);
        setTimeout(function() {
          oneX.Carousel.positionToCard($strip, $data, 2, true);
          $data.index = 2;
        }, 300);
      } else {
        // normal
        oneX.Carousel.positionToCard($strip, $data, $data.index, true);
      }
    },

    handleTouchStart: function (event) {
      const $container = oneX.$(event.target).closest('.-oneX-carousel-container'),
        $data = $container.data();

      $data.touchFlick = true;

      setTimeout(function () {
        $data.touchFlick = false;
      }, 250);

      $data.touchstartx = event.originalEvent.touches[0].pageX;

      $container.find('.-oneX-carousel-animate').removeClass('-oneX-carousel-animate');
    },

    handleTouchMove: function (event) {
      const $container = oneX.$(event.target).closest('.-oneX-carousel-container'),
        $data = $container.data(),
        $strip = oneX.$(event.target).closest('.-oneX-carousel-strip');

      $data.touchmovex = event.originalEvent.touches[0].pageX;
      $data.movex = $data.index * $data.cardWidth + ($data.touchstartx - $data.touchmovex);

      if ($data.movex < $data.totalWidth && Math.abs($data.touchstartx - $data.touchmovex) <= $data.cardWidth) { // Makes the holder stop moving when there is no more content or after one full card width.
        $strip.css('transform','translate3d(-' + $data.movex + 'px,0,0)');
      }

    },

    handleTouchEnd: function (event) {
      const $container = oneX.$(event.target).closest('.-oneX-carousel-container'),
        $data = $container.data(),
        $strip = oneX.$(event.target).closest('.-oneX-carousel-strip');

      const absMove = Math.abs($data.index * $data.cardWidth - $data.movex - eval($data.gutterWidth));

      if (absMove > ($data.cardWidth / 2) || $data.touchFlick === true) {
        if ($data.movex > ($data.index * $data.cardWidth) ) {
          // $data.index++;
          oneX.Carousel.navigateRightClick(event);
        } else if ($data.movex < ($data.index * $data.cardWidth) && $data.index > 0) {
          // $data.index--;
          oneX.Carousel.navigateLeftClick(event);
        }
      } else {
        oneX.Carousel.positionToCard($strip, $data, $data.index, true);
      }
    },

    focusSkipCarousel: function (event) {
      if (oneX.$('body').hasClass('-oneX-user-tabbing')) {
        oneX.$(oneX.$(event.currentTarget).parent()).removeClass('-oneX-clipped');
      }
    },

    hideSkipCarousel: function (event) {
      oneX.$(oneX.$(event.currentTarget).parent()).addClass('-oneX-clipped');
    }

  };

  oneX.Config.queues(_COMPONENT);

})();