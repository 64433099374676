(function() {
  // Returns EN or ES based on URL, Locale Parameter, Cookie or HTML <body> tag
  oneX.Util.locale = {
    getLanguage : function() {
      const spanish = /(es)(_|-)((US)|(us))/;
      return ( /^(es)(\.)/.test( oneX.Util.location.hostname() ) ||
      spanish.test( oneX.Util.location.search() ) ||
      spanish.test( oneX.Util.environment.locale()) ||
      (oneX.Util.environment.locale() === 'es') ) ? "es" : "en";
    },
    isCanadian : function() {
      const canada = /(en)(_|-)((CA)|(ca))/;
      return  canada.test( oneX.Util.environment.locale() ) || canada.test( oneX.Util.location.search() ) ;
    },
    isSpanish : function() {
      return this.getLanguage() === 'es' ? true : false;
    }
  };
})();
