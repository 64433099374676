(function () {
  const _COMPONENT = {
    name: 'Drawer',
    selectors: '.-oneX-drawer-container'
  };

  oneX.Drawer = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        const $closeBtn = _element.find('div[role="dialog"] > button.-oneX-close-X-button');

        $closeBtn.on("click", _self.closeButtonClicked);
        $closeBtn.on('keydown', _self.closeButtonKeyDown);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $closeBtn = _element.find('div[role="dialog"] > button.-oneX-close-X-button');

      $closeBtn.off("click", _self.closeButtonClicked);
      $closeBtn.off('keydown', _self.closeButtonKeyDown);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    showDrawer: function (callerId) {
      const _self = this,
        drawerId = oneX.Util.selectById(callerId).attr('data-oneX-opensDrawer'),
        $drawer = oneX.Util.selectById(drawerId);

      if (drawerId !== undefined && $drawer !== undefined) {

        oneX.Util.componentWithScrim.showScrim($drawer, "drawer");
        oneX.Drawer.bindForScrim($drawer);
        oneX.Drawer.removeScrollBars($drawer);

        $drawer.addClass('-oneX-drawer-container__out').removeClass('-oneX-drawer-container__in').attr('data-returnId', callerId);

        oneX.$(window).trigger('resize');

        $drawer.find(".-oneX-drawer-header").focus();
      }
    },

    hideDrawerAndCallback: function (callback, skipFocusAction = true, skipAnimation = false) {

      const $returnToFocus = skipFocusAction ? undefined : oneX.Util.selectById(oneX.$(".-oneX-scrim-top__drawer").attr('data-returnId'));

      oneX.Drawer.cleanUpDrawer(skipAnimation, $returnToFocus, callback);
    },

    cleanUpDrawer: function (skipAnimation, $returnToFocus, callback) {
      const _self = this,
        $drawer = oneX.$(".-oneX-scrim-top__drawer");

      if (skipAnimation) {
        // override animation with in-line style
        $drawer.attr('data-save', $drawer.attr('style'));
        $drawer.attr('style','transition: none');
      }

      $drawer.addClass('-oneX-drawer-container__in').removeClass('-oneX-drawer-container__out');

      _self.kiboEsc.unregisterKeys('down', 'esc', _self.escapeKeyAction);

      _self.removeScrim($returnToFocus, skipAnimation ? true : undefined, callback);

      if (skipAnimation) {
        // override animation with in-line style
        $drawer.attr('style',$drawer.attr('data-save'));
        $drawer.removeAttr('data-save');
      }
    },

    hideDrawer: function (spa_reset_only) {
      const skipAnimation = spa_reset_only ? true : false;

      oneX.Drawer.cleanUpDrawer (skipAnimation, oneX.Util.selectById(oneX.$(".-oneX-scrim-top__drawer").attr('data-returnId')), undefined);
    },

    bindForScrim: function (drawer) {
      const _self = this,
        drawerType = { scrimType: "drawer" };

      _self.kiboEsc = new Kibo();

      _self.kiboEsc.down('esc', oneX.Drawer.escapeKeyAction);
      drawer.find(".-oneX-drawer-header").on("keydown", drawerType, oneX.Util.componentWithScrim.headerKeyDown);

      oneX.$(".-oneX-content-behind-scrim__drawer").on("focusin", drawerType, oneX.Util.componentWithScrim.scrimFocusIn);
      oneX.$(".-oneX-scrim-top__drawer").on("focusin", drawerType, oneX.Util.componentWithScrim.topComponentFocusIn);
      oneX.$(drawer.find(oneX.Util.componentWithScrim.tabbable)[0]).on("keydown", drawerType, oneX.Util.componentWithScrim.firstTabbableKeyDown);
      oneX.$(".-oneX--scrim__drawer").off("click", oneX.Drawer.scrimClick).on("click", oneX.Drawer.scrimClick);

    },

    removeScrim: function ($returnToObject, spa_reset_only, callback) {
      oneX.Util.componentWithScrim.removeScrim("drawer", function () {

        $returnToObject && $returnToObject.focus();
        oneX.$('html').removeClass('-oneX-remove-second-scroll-bar');
        // IOS Fix
        if (oneX.$('body').attr('data-oneX-top') !== 'auto' && oneX.$('body').attr('data-oneX-top') !== undefined) {
          const top = oneX.$('body').attr('data-oneX-top');

          oneX.$('body').removeClass('-oneX-prevent-scrim-scroll');
          oneX.$('body').removeAttr('data-oneX-top');
          window.scrollTo(0, parseInt(top) * -1);
        }
        else {
          oneX.$('body').removeAttr('data-oneX-top');
          oneX.$('body').removeClass('-oneX-prevent-scrim-scroll');  
        }

        if (callback && typeof(callback) === "function"){
          callback();
        }  

      }, spa_reset_only);
    },

    removeScrollBars: function (drawer) {
      oneX.$('html').addClass('-oneX-remove-second-scroll-bar');
      setTimeout(function () {

        if (window.matchMedia('(max-width: 991px)').matches) {
          // IOS fix
          oneX.$('body').attr('data-oneX-top',`-${window.scrollY}px`);
          oneX.$('body').addClass('-oneX-prevent-scrim-scroll');
        }
      }, 15);
    },

    closeButtonClicked: function () {
      oneX.Drawer.hideDrawer();
    },

    escapeKeyAction: function () {
      if ((oneX.$(".-oneX-scrim-top__modal").length === 0) && (oneX.$('[data-session-timeout]:visible').length === 0)) {
        oneX.Drawer.hideDrawer();
      }
    },

    closeButtonKeyDown: function (event) {
      if (event.which === 32) {
        oneX.Drawer.hideDrawer();
      }

      else if (event.which === 9 && !event.shiftKey && oneX.$('[data-session-timeout]:visible').length === 0) {
        event.preventDefault();
        const $tabbable = oneX.$(".-oneX-scrim-top__drawer").find(oneX.Util.componentWithScrim.tabbable);
        $tabbable[0].focus();
      }

      else {
        return;
      }
    },

    scrimClick: function () {
      const $drawer = oneX.$(".-oneX-scrim-top__drawer");
      if ($drawer.length > 0) {
        oneX.Drawer.hideDrawer();
      }
    },

    kiboEsc: null
  };

  oneX.Config.queues(_COMPONENT);

})();