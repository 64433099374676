(function () {
    const _COMPONENT = {
      name: 'ProductFooter',
      selectors: '.-oneX-product-footer'
    };
  
    oneX.ProductFooter = {
      init: function () {
        const _self = this;
        oneX.$(_COMPONENT.selectors).each(function() {
          _self.bindEvents(oneX.$(this));
        });
        return this;
      },
  
      addElement: function (_element) {
        this.bindEvents(_element);
      },
  
      removeElement: function (_element) {
        this.unbindEvents(_element);
      },
  
      bindEvents: function (_element) {
        const _self = this;
  
        if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
          const $productFooterHeadingWrappers = _element.find('[data-grouping]');

          _self.kiboProductFooterHeadingWrappers = new Kibo(_element[0].querySelectorAll('[data-grouping]'));
          _self.kiboProductFooterHeadingWrappers.down(["enter", "space"], _self.toggleProductFooterItems); 

          $productFooterHeadingWrappers.on("click", _self.toggleProductFooterItems);

          _self.debouncedCollapseProductFooterItems = oneX.Util.debounce.debounce(_self.collapseProductFooterItems, 250, _element);
          oneX.$(window).on('resize', _self.debouncedCollapseProductFooterItems);

          _self.productFooterSetup(_element);
          
          _element.data(_COMPONENT.name, _COMPONENT.name);
        }
      },
  
      unbindEvents: function (_element) {
        const _self = this,
          $productFooterHeadingWrappers = _element.find('[data-grouping]');

        _self.kiboProductFooterHeadingWrappers.unregisterKeys('down', ["enter", "space"], _self.toggleProductFooterItems);
        $productFooterHeadingWrappers.off("click", _self.toggleProductFooterItems);
        oneX.$(window).off('resize', _self.debouncedCollapseProductFooterItems);
  
        _element.removeData(_COMPONENT.name, _COMPONENT.name);
      },

      productFooterSetup: function ($productFooter) {
        const $productFooterHeadings = $productFooter.find('button');

        (window.matchMedia('(max-width: 767px)').matches) ? $productFooterHeadings.attr('aria-expanded', false).removeAttr('tabindex').removeClass('-oneX-product-footer-expanded') : $productFooterHeadings.removeAttr('aria-expanded').attr('tabindex', -1);
      },
  
      toggleProductFooterItems: function (event) {
        event.preventDefault();
        const $productFooterHeadings = oneX.$(event.currentTarget).find('button'),
          flipExpanded = ($productFooterHeadings.attr('aria-expanded') === 'true') ? false : true,
          chevronExpand = (flipExpanded) ? '-oneX-product-footer-expanded' : '';

        (window.matchMedia('(max-width: 767px)').matches) && $productFooterHeadings.attr({'aria-expanded': flipExpanded, 'class': chevronExpand}).next('ul').slideToggle(250);
      },

      collapseProductFooterItems: function ($productFooter) {
        const $listItems = $productFooter.find('ul');

        (window.matchMedia('(max-width: 767px)').matches) ? $listItems.hide(250) : $listItems.show(250);

        oneX.ProductFooter.productFooterSetup($productFooter);
      },

      kiboProductFooterHeadingWrappers: null,
      debouncedCollapseProductFooterItems: null
    };
  
    oneX.Config.queues(_COMPONENT);
  })();
  