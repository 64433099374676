(function () {
  const _COMPONENT = {
    name: 'Datepicker',
    selectors: '[data-formatter=date]+button[class~=-oneX-btn-control]'
  };

  oneX.Datepicker = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this,
        $input = _element.prev('input'),
        id = $input.attr('id'),
        disabledDatesData = $input.attr('data-oneX-disabled-dates');


      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        _self.calendars[id] = _self.initDatepicker('[id="' + id + '"]+button[class~=-oneX-btn-control]', _self.parseDisabledDates(disabledDatesData));
        _self.calendars[id].showHideCalendarBinders();
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        id = _element.prev('input').attr('id');


      if (typeof _self !== "undefined" && typeof _self.calendars[id] !== "undefined" && typeof _self.calendars[id].showHideCalendarBinders !== "undefined"){
        _element.off('click', _self.toggleOpenClose);

        _self.calendars[id].remove();

        _element.removeData(_COMPONENT.name, _COMPONENT.name);
      }
    },

    parseDisabledDates: function (disabledDatesData) {
      let disabledDatesJson = null,
        disabledDatesJsonObj = {
          "disabledDates": []
        };

      if (disabledDatesData) {
        disabledDatesJson = oneX.$.parseJSON(disabledDatesData);

        oneX.$.each(disabledDatesJson.disabledDates, (index, date) => {
          disabledDatesJsonObj.disabledDates.push(new Date(date));
        });
      }

      return disabledDatesJsonObj;
    },

    initDatepicker: function (selector, disabledDatesJsonObj) {

      /* JS-Datepicker documentation can be found at https://www.npmjs.com/package/js-datepicker */
      const _self = datepicker(selector, {

        /* Rebind elements on close for this specific selector and tun off binder for closing calendars */
        onHide: (instance) => {
          oneX.$('body').off('click keydown', _self.closeCalendarIfBackgroundSelected);

        },

        onSelect: (instance, date) => {
          /*
           * onSelect gets fired multiple times. This is likely a conflict with 1x code.
           *
           * The follow logic will make sure that date is defined before executing any logic.
           */

          const $calendar = oneX.$(instance.calendar),
            $input = oneX.$(instance.el).prev(),
            options = {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            };

          date = ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + "-" + date.getFullYear();

          $input.val(date.toString()).focus().trigger("input");
        },

        onMonthChange: (instance) => {

          if (!_self.focusedOnMonthYear) {
            const $calendar = oneX.$(instance.calendar);

            /* Since the calendar DOM refreshes whenever a date is changed, this logic ensures that focus remains on the month arrow triggering onMonthChange. */
            if (instance.currentYear < _self.existingYear) {
              $calendar.find('.qs-left').focus();
            } else {
              (instance.currentMonth === 0 && _self.existingMonth === 11 || instance.currentMonth > _self.existingMonth) ? $calendar.find('.qs-right').focus(): $calendar.find('.qs-left').focus();
            }

            /* Capture the value of the month and year prior to onMonth change to aid in month arrow focus. */
            _self.existingMonth = instance.currentMonth;
            _self.existingYear = instance.currentYear;
          } else {
            oneX.$(_self.calendar).find('.qs-month-year').focus();
          }

          _self.focusedOnMonthYear = false;
          _self.rebindEvents();
        },

        position: 'br',
        overlayPlaceholder: 'YYYY',
        customDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        customMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        disabledDates: disabledDatesJsonObj.disabledDates
      });

      _self.rebindEvents = () => {
        const $calendar = oneX.$(_self.calendar);

        _self.unbindCalEvents();
        // navigation
        _self.kiboTabbableComponents.down('tab', _self.tabFocusToCurrentDate);
        _self.kiboYearOverlayInput.down('shift tab', _self.focusToClose);
        _self.kiboYearOverlayInput.down('enter', _self.focusToMonthYear);
        _self.kiboYearOverlaySubmit.down(['enter', 'space'], _self.focusToMonthYear);
        _self.kiboYearOverlayClose.down('tab', _self.focusToYear);
        _self.kiboYearOverlayClose.down(['enter', 'space'], _self.focusToMonthYear);
        $calendar.find('.qs-arrow, .qs-month-year').on('keydown', _self.navigateControls);
        $calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').on('keydown', _self.navigateDays);

        // selection
        $calendar.find('.qs-arrow, .qs-month-year').on('keydown', _self.clickOnEnterAndSpace);
        $calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').on('keydown', _self.clickOnEnterAndSpace);

        $calendar.find('.qs-empty, div.qs-disabled');
        _self.screenReaderA11y();
      };

      _self.unbindCalEvents = () => {
        const $calendar = oneX.$(_self.calendar);

        // navigation
        _self.kiboTabbableComponents.unregisterKeys('down', 'tab', _self.tabFocusToCurrentDate);
        _self.kiboYearOverlayInput.unregisterKeys('down', 'shift tab', _self.focusToClose);
        _self.kiboYearOverlayInput.unregisterKeys('down', 'enter', _self.focusToMonthYear);
        _self.kiboYearOverlaySubmit.unregisterKeys('down', ['enter', 'space'], _self.focusToMonthYear);
        _self.kiboYearOverlayClose.unregisterKeys('down', 'tab', _self.focusToYear);
        _self.kiboYearOverlayClose.unregisterKeys('down', ['enter', 'space'], _self.focusToMonthYear);
        $calendar.find('.qs-arrow, .qs-month-year').off('keydown', _self.navigateControls);
        $calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').off('keydown', _self.navigateDays);

        // selection
        $calendar.find('.qs-arrow, .qs-month-year').off('keydown', _self.clickOnEnterAndSpace);
        $calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').off('keydown', _self.clickOnEnterAndSpace);
      };

      /* Binders to toggle the calendar's visibility. */
      _self.showHideCalendarBinders = () => {
        _self.kiboCalendar.down('esc', _self.closeCalendar);
        oneX.$(_self.calendar).prev('.-oneX-btn-control').on('click', _self.toggleOpenClose);
      };

      /* Rebind overlay after closing. */
      _self.rebindOverLay = () => {
        _self.kiboYearOverlayClose = new Kibo(oneX.$(_self.calendar).find('.qs-close')[0]);
        _self.kiboYearOverlayInput = new Kibo(oneX.$(_self.calendar).find('.qs-overlay-year')[0]);
        _self.kiboYearOverlaySubmit = new Kibo(oneX.$(_self.calendar).find('.qs-submit')[0]);

        _self.kiboYearOverlayInput.down('shift tab', _self.focusToClose);
        _self.kiboYearOverlayInput.down('enter', _self.focusToMonthYear);
        _self.kiboYearOverlaySubmit.down(['enter', 'space'], _self.focusToMonthYear);
        _self.kiboYearOverlayClose.down('tab', _self.focusToYear);
        _self.kiboYearOverlayClose.down(['enter', 'space'], _self.focusToMonthYear);
      };

      /* Helper to open or close the calender when DIV element with class qs-datepicker also has class qs-hidden. */
      _self.toggleOpenClose = (event) => {
        event.preventDefault();
        oneX.$('.qs-datepicker').not(_self.calendar).addClass('qs-hidden');

        if (oneX.$(_self.calendar).hasClass('qs-hidden')) {
          _self.openCalendar();
        } else {
          _self.closeCalendar();
        }
      };

      /* Opens the calendar, rebinds events and remanipulates DOM (since the DOM is refreshed whenever the calendar is opened). */
      _self.openCalendar = () => {
        oneX.$(_self.calendar).removeClass('qs-hidden');
        _self.show();

        _self.rebindEvents();

        _self.existingMonth = _self.currentMonth;
        _self.existingYear = _self.currentYear;

        _self.setDayFocus();
        oneX.$('body').on('click keydown', _self.closeCalendarIfBackgroundSelected);
      };

      /* Closes the calendar. */
      _self.closeCalendar = () => {
        const $calendar = oneX.$(_self.calendar);

        _self.closeOverlay();

        $calendar.prev('.-oneX-btn-control').focus();
        $calendar.addClass('qs-hidden');
        _self.hide();
      };

      /* Closes the calendar. */
      _self.closeCalendarIfBackgroundSelected = (event) => {
        if (event && event.target && oneX.$(event.target).parent().hasClass('qs-disabled')) {
          // console.log('skipped due to disabled');
          return;
        }

        setTimeout(() => {
          const $element = oneX.$(':focus');

          if ($element.closest('.-oneX-btn-control').length === 0 && $element.closest('.qs-datepicker').length === 0) {
            if (typeof _self !== "undefined" && typeof _self.closeOverlay !== "undefined"){
              _self.closeOverlay();
            }

            oneX.$('.qs-datepicker').addClass('qs-hidden');
          }
        }, 100);
      };

      /* Closes the overlay if visible. */
      _self.closeOverlay = () => {
        const $yearOverlay = oneX.$(_self.calendar).find('.qs-overlay');
        if (!$yearOverlay.hasClass('qs-hidden')) {
          $yearOverlay.find('.qs-close').click();
        }
      };

      /* Sets focus on a relevant day. */
      _self.setDayFocus = () => {
        const $calendar = oneX.$(_self.calendar);

        if ($calendar.find('.qs-active').length > 0) {
          $calendar.find('.qs-active').focus();
        } else if ($calendar.find('.qs-current').length > 0) {
          $calendar.find('.qs-current').focus();
        } else {
          $calendar.find('.qs-num').not('.qs-empty, .qs-disabled').first().focus();
        }
      };

      /* Tab from the calendar control to today's date. */
      _self.tabFocusToCurrentDate = (event) => {
        event.preventDefault();
        _self.setDayFocus();
      };

      /* Tab from the year overlay input field to close button. */
      _self.focusToClose = (event) => {
        event.preventDefault();
        oneX.$(_self.calendar).find('.qs-close').focus();
      };

      /* Tab from the year overlay close button to input field. */
      _self.focusToYear = (event) => {
        event.preventDefault();
        oneX.$(_self.calendar).find('.qs-overlay-year').focus();
      };

      /* Focus on month/year. */
      _self.focusToMonthYear = (event) => {
        oneX.$(_self.calendar).find('.qs-month-year').focus();
        _self.focusedOnMonthYear = true;
      };

      /* Navigate the calendar controls using TAB or ARROW keys. */
      _self.navigateControls = (event) => {
        _self.focusedOnMonthYear = false;
        const $currentControl = oneX.$(event.currentTarget);

        event.preventDefault();

        switch (event.keyCode) {
        case 9:
          _self.setDayFocus();

          break;
        case 37: // left
        case 38: // up
          if ($currentControl.prev().length > 0) {
            $currentControl.prev().focus();
          }

          break;
        case 39: // right
        case 40: // down
          if ($currentControl.next().length > 0) {
            $currentControl.next().focus();
          }
        }
      };

      /* Navigate the calendar dates using TAB or ARROW keys. */
      _self.navigateDays = (event) => {
        const $calendar = oneX.$(_self.calendar),
          $currentDate = oneX.$(event.currentTarget);

        event.preventDefault();

        switch (event.keyCode) {
        case 9:
          $calendar.find('.qs-left').focus();

          break;
        case 37: // left
          const $dateLeft = $currentDate.prevAll(':not(.qs-day, .qs-empty, .qs-disabled)').first();

          if ($dateLeft.length > 0) {
            $dateLeft.focus();
          } else {
            _self.changeMonthYear('left');
            _self.focusOnControlsOnUnavailableDates($calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').last(), 'left');
          }

          break;
        case 38: // up
          const $dateAbove = $currentDate.prevAll('.qs-square.qs-num[data-weekday="' + $currentDate.attr('data-weekday') + '"]').not('.qs-empty, .qs-disabled').first();

          if ($dateAbove.length > 0) {
            $dateAbove.focus();
          } else {
            _self.changeMonthYear('left');
            _self.focusOnControlsOnUnavailableDates($calendar.find('.qs-square.qs-num[data-weekday="' + $currentDate.attr('data-weekday') + '"]').not('.qs-empty, .qs-disabled').last(), 'left');
          }

          break;
        case 39: // right
          const $dateRight = $currentDate.nextAll(':not(.qs-empty, .qs-disabled)').first();

          if ($dateRight.length > 0) {
            $dateRight.focus();
          } else {
            _self.changeMonthYear('right');
            _self.focusOnControlsOnUnavailableDates($calendar.find('.qs-square.qs-num').not('.qs-empty, .qs-disabled').first(), 'right');

          }

          break;
        case 40: // down
          const $dateBelow = $currentDate.nextAll('.qs-square.qs-num[data-weekday="' + $currentDate.attr('data-weekday') + '"]').not('.qs-empty, .qs-disabled').first();

          if ($dateBelow.length > 0) {
            $dateBelow.focus();
          } else {
            _self.changeMonthYear('right');
            _self.focusOnControlsOnUnavailableDates($calendar.find('.qs-square.qs-num[data-weekday="' + $currentDate.attr('data-weekday') + '"]').not('.qs-empty, .qs-disabled').first(), 'right');
          }
        }
      };

      /* Accepts a parameter of values 'left' or 'right' and clicks the appropriate calendar control arrow on keydown. */
      _self.changeMonthYear = (direction) => {
        oneX.$(_self.calendar).find('.qs-' + direction).click();
      };

      _self.focusOnControlsOnUnavailableDates = ($availableDate, direction) => {
        if ($availableDate) {
          $availableDate.focus();
        } else {
          oneX.$(_self.calendar).find('.qs-' + direction).focus();
        }
      };

      /* Click any focusable element on ENTER and SPACE. */
      _self.clickOnEnterAndSpace = (event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
          oneX.$(event.target).removeClass('qs-active').click();
        }

        if (oneX.$(event.target).hasClass('qs-month-year')) {
          _self.rebindOverLay();
        }
      };

      /* Sets a data attribute data-weekday for keyboard and screenreader accessibility needs. */
      _self.weekDayData = (weekDays) => {
        let weekIterator = 0;

        weekDays.each((index, weekDay) => {
          if (weekIterator > 6) {
            weekIterator = 0;
          }

          oneX.$(weekDay).attr('data-weekday', _self.fullDays[weekIterator]);

          _self.screenReaderA11ySetDayOfWeekLabel(_self.fullDays[weekIterator], weekDay);

          weekIterator++;
        });
      };

      /* Add ARIA attributes to calendar components. */
      _self.screenReaderA11y = () => {
        const $calendar = oneX.$(_self.calendar);

        _self.screenReaderA11yPrevAndNextMonth();
        _self.weekDayData($calendar.find('.qs-square').not('.qs-day'));

        $calendar.attr('aria-label', 'date picker').attr('role', 'application');
        $calendar.find('.qs-controls').attr('aria-label', 'calendar navigation').attr('role', 'toolbar');
        $calendar.find('.qs-left').attr('aria-label', 'Previous Month ' + _self.fullMonths[_self.prevMonth] + ' ' + _self.prevYear);
        $calendar.find('.qs-month-year').attr('aria-label', 'Year dialog button. Current month and year is ' + _self.fullMonths[_self.currentMonth] + ' ' + _self.currentYear).attr('aria-live', 'assertive').attr('aria-atomic', 'true');
        $calendar.find('.qs-right').attr('aria-label', 'Next Month ' + _self.fullMonths[_self.nextMonth] + ' ' + _self.nextYear);
        $calendar.find('.qs-submit').attr('aria-label', 'Change year dialog button. Current month and year is ' + _self.fullMonths[_self.currentMonth] + ' ' + _self.currentYear);
        $calendar.find('.qs-day').attr('aria-hidden', 'true');
        $calendar.find('.qs-close').attr('aria-label', 'Close dialog');
      };

      /* This function helps ARIA labels for the calendar arrow controls determine the previous and next month and year. */
      _self.screenReaderA11yPrevAndNextMonth = () => {
        if (_self.currentMonth - 1 === -1) {
          _self.prevMonth = 11;
          _self.nextMonth = 1;
          _self.prevYear = _self.currentYear - 1;
          _self.nextYear = _self.currentYear;
        } else if (_self.currentMonth + 1 === 12) {
          _self.prevMonth = 10;
          _self.nextMonth = 0;
          _self.prevYear = _self.currentYear;
          _self.nextYear = _self.currentYear + 1;
        } else {
          _self.prevMonth = _self.currentMonth - 1;
          _self.nextMonth = _self.currentMonth + 1;
          _self.prevYear = _self.currentYear;
          _self.nextYear = _self.currentYear;
        }
      };

      /* This function helps ARIA labels for the dates determine the day, month, weekday and year. */
      _self.screenReaderA11ySetDayOfWeekLabel = (dayOfWeek, weekDay) => {
        const day = oneX.$(weekDay);

        let ariaLabel = dayOfWeek + ', ' + _self.fullMonths[_self.currentMonth] + ' ' + oneX.$(weekDay).text() + ' ' + _self.currentYear;

        if (day.hasClass('qs-current')) {
          ariaLabel += ', ' + 'Today\'s date';
        }

        if (day.hasClass('qs-active')) {
          ariaLabel += ', ' + 'Selected';
        }

        if (day.hasClass('qs-disabled')) {
          ariaLabel += ', ' + 'Unavailable date';
        }

        day.attr('aria-label', ariaLabel);
      };

      _self.refresh = () => {
        const $calendar = oneX.$(_self.calendar);

        $calendar.find('.qs-left').click();
        $calendar.find('.qs-right').click();
        _self.setDayFocus();
      };

      _self.kiboCalendar = new Kibo(oneX.$(_self.calendar)[0]);
      _self.kiboCalendarButton = new Kibo(oneX.$(_self.calendar).prev('.-oneX-btn-control')[0]);
      _self.kiboTabbableComponents = new Kibo(oneX.$(_self.calendar).find('.qs-left, .qs-current')[0]);
      _self.kiboYearOverlayClose = new Kibo(oneX.$(_self.calendar).find('.qs-close')[0]);
      _self.kiboYearOverlayInput = new Kibo(oneX.$(_self.calendar).find('.qs-overlay-year')[0]);
      _self.kiboYearOverlaySubmit = new Kibo(oneX.$(_self.calendar).find('.qs-submit')[0]);
      _self.existingMonth = null;
      _self.existingYear = null;
      _self.prevMonth = null;
      _self.nextMonth = null;
      _self.prevYear = null;
      _self.nextYear = null;
      _self.fullDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      _self.fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      _self.originalSetDate = _self.setDate;
      _self.originalSetMin = _self.setMin;
      _self.originalSetMax = _self.setMax;
      _self.focusedOnMonthYear = false;

      _self.setDate = (newDate, changeCalendar) => {

        _self.originalSetDate(newDate, changeCalendar);
        _self.screenReaderA11y();
        oneX.$(_self.calendar).find('.qs-active').addClass('qs-current').focus();
      };

      _self.setMin = (newDate, changeCalendar) => {

        _self.originalSetMin(newDate, changeCalendar);
        _self.screenReaderA11y();
      };

      _self.setMax = (newDate, changeCalendar) => {

        _self.originalSetMax(newDate, changeCalendar);
        _self.screenReaderA11y();
      };

      return _self;
    },

    calendars: {}
  };

  oneX.Config.queues(_COMPONENT);
})();
