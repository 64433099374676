(function () {
  const _COMPONENT = {
    name: 'PageLoader',
    selectors: ".-oneX-page-loader"
  };

  oneX.PageLoader = {
    init: function () {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors));
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        _self.browserDetection(_element);
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    browserDetection: function (_element) {
      isTrident: false;
      const _self = oneX.PageLoader;
      _self.isTrident = (navigator.userAgent.indexOf('Trident') > -1) ? true : false;

      if (_self.isTrident) {
        _element.find('.-oneX-loader-icon').css('display', 'block');
      }

      else {
        _element.find('.-oneX-loaderdiv').css('display', 'block');
      }
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
