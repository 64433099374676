(function () {
  const _COMPONENT = {
    name: 'Footer',
    selectors: '[data-type^="1x-footer"], .-oneX-footer'
  };

  oneX.Footer = {
    init: function (callback) {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors), callback);
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element, callback) {
      if (_element.length > 0 && _element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const _self = this;

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element, callback);
        }
        else {
          _self.kiboFooter = new Kibo(_element[0]);
          _self.kiboFooter.up('space', _self.subsituteSpaceForClick);
          _element.find('.-oneX-footer-col-head').on('click', _self.toggleSubMenu);
          _element.find('.-oneX-footer-show-more').on('click', _self.toggleColContainer);
          _element.find('.-oneX-give-us-feedback').on('click', _self.openFeedbackLink);
          _element.find('.-oneX-give-us-feedback').on('keypress', _self.openFeedbackLink);
          oneX.Footer.checkForKAMPYLE();
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _self.kiboFooter?.unregisterKeys('up', 'space', _self.subsituteSpaceForClick);
      _element.find('.-oneX-footer-col-head').off('click', _self.toggleSubMenu);
      _element.find('.-oneX-footer-show-more').off('click', _self.toggleColContainer);
      _element.find('.-oneX-give-us-feedback').off('click', _self.openFeedbackLink);
      _element.find('.-oneX-give-us-feedback').off('keypress', _self.openFeedbackLink);


      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    renderHTML: function (_element, callback) {
      const limited = (_element.data("limited") === true) ? "-limited" : "", 
        icons = (_element.data("icons") === true) ? true : false,
        internal = (_element.data("internal") === true) ? "-internal" : "", 
        lastUpdated = (_element.data("lastupdated")) ? _element.data("lastupdated") : "", 
        darkView = (_element.data("darkview")) ? _element.data("darkview") : "", 
        linkstyle = (_element.data("linkstyle")) ? _element.data("linkstyle") : "", 
        footerJSON = (_element.data("json")),
        footerHTML = "/navigation-footer" + limited + internal + ".html",
        footerLocale = (oneX.Util.locale.isSpanish()) ? "/es_US" : "/en_US",
        footerStaticLocale = oneX.Util.environment.getStaticHostName() + footerLocale,
        port = oneX.Util.location.port(),
        languageURL = _element.data("language-url"),
        isLocalTestHarness = ((port === "4200" || port === "3001") && /onex-testharness/ig.test(oneX.Util.location.pathname())) || 
            (port === "8888" && oneX.$('script[src*="test-footer.js"]').attr('src') === "/test-footer.js" ) || 
            ((port === "4200" || port === "4201") && oneX.$('head title').html() === "1x Test Harness Angular"),
        segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src'),
        json_a = {
          "mainLinks" : {
            "navList" : [
              {
                "name" : "H6 - Link 1",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 2",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 3",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 4",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 5",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 6",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 7",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Link 8",
                "url" : "JavaScript:Void(0);"
              }
            ]
          },
          "mainNavLink" : {
            "name" : "Link 9",
            "url" : "JavaScript:Void(0);"
          },
          "columnLinks" : 
          [
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "H6 Product",
              "colLinks" : [
                {
                  "name" : "Lorem ipsum",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Dolor sit amet",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Consectetur adi",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Sed do eiusmod",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            }
          ]
        },
        json_b = {
          "mainLinks" : {
            "navList" : [
              {
                "name" : "H6 - Link 1",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "SFnet Home",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "statefarm.com®",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "ABS",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "HRSS",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "ESS",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Communication Service Manager",
                "url" : "JavaScript:Void(0);"
              },
              {
                "name" : "Contact SFnet",
                "url" : "JavaScript:Void(0);"
              }
            ]
          },
          "mainNavLink" : {
            "name" : "Site Map",
            "url" : "JavaScript:Void(0);"
          },
          "columnLinks" : 
          [
            {
              "colHead" : "Human Resources",
              "colLinks" : [
                {
                  "name" : "Employee/Manager Self Service",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Employee Onboarding",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "MyBlock",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "My State Farm Benefits Re...",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Workplace Policies",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "More...",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "State Farm Policies",
              "colLinks" : [
                {
                  "name" : "Code of Conduct",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Compliance &amp; Ethics Hotline",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Enterprise Policies",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Information Retention Program",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Report Security Incidents",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "Recruiting",
              "colLinks" : [
                {
                  "name" : "Become an Agent",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Diversity &amp; Inclusion",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Employee Referral Program",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Recruiting",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "Additional Resources",
              "colLinks" : [
                {
                  "name" : "ECRM Companywide Customer...",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Enterprise Glossary",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Enterprise Idea Management",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Ovation",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "Money Matters",
              "colLinks" : [
                {
                  "name" : "Accounts Payable/Contract...",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Credit Union",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Expense Processing",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Online Company Store",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "State Farm Bank",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            },
            {
              "colHead" : "About State Farm",
              "colLinks" : [
                {
                  "name" : "Our Company",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "News &amp; Publications",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "State Farm Brand Center",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "Understanding the Issues",
                  "url" : "JavaScript:Void(0);"
                },
                {
                  "name" : "More....",
                  "url" : "JavaScript:Void(0);"
                }
              ]
            }
          ]
        };
      

      let localPath = "http://localhost:3000";

      if (segmentSection !== undefined && segmentSection !== null){
        const colon3000 = segmentSection.indexOf(':3000');
        
        if (colon3000 > -1) {
          localPath = segmentSection.substr(0, colon3000 + 5);
        }
      }
      const footerPath = (isLocalTestHarness) ? localPath + "/html/footer" + footerLocale : footerStaticLocale + "/dxl-1x/" + oneX.Util.location.getVersion() + "/renders/footer";

      _element.load(footerPath + footerHTML, function (response, status) {
        if (status === "success") {

          if (isLocalTestHarness) {
            _element.html(
              _element.html()
                .replace(/{{domain_root}}/g, footerStaticLocale)
                .replace(/{{www_root}}/g, oneX.Util.location.hostname())
                .replace(/dxl-1x\/prod/g, `dxl-1x/${oneX.Util.location.getVersion()}`)
            );

            if (icons) {
              _element.html(
                _element.html()
                  .replace(/https\:\/\/static1-env1.test.st8fm.com\/e[n,s]_US\/dxl-1x\/prod\//g, "http://localhost:3000/")
              );
            }
          }

          if (icons && footerStaticLocale.match(/.test./) !== null) {
            _element.html(
              _element.html()
                .replace(/dxl-1x\/prod/g, `dxl-1x/${oneX.Util.location.getVersion()}`)
            );
          }

          if (languageURL){
            oneX.Footer.setLanguageLink(languageURL);
          }
          if (lastUpdated !== "") {
            const $lastUpdate = oneX.$('.-oneX-footer-last-updated'),
              $lastUpdateData = $lastUpdate.find('span');
        
            $lastUpdateData.html(lastUpdated);
            $lastUpdate.removeClass('-oneX-hidden');
          }
          if (darkView) {
            oneX.$('.-oneX-footer_internal').addClass('-oneX-dark-view')
          }

          switch(linkstyle) {
          case "": {
            // use what comes in on the footer tag from hbs
            if (footerJSON) {
              oneX.$('#oneX-footer-links').remove();
              oneX.$(oneX.Footer.createLinksDOM(footerJSON)).insertBefore('.-oneX-footer-last-updated');
              oneX.$('#oneX-footer-links-a').remove();
              oneX.$('#oneX-footer-links-b').remove();
            }
            else {
              // backwards compatible
              oneX.$('.-oneX-footer-links').detach().insertBefore('.-oneX-footer-last-updated');
            }
            break;
          }
          case "a": {
            oneX.$('#oneX-footer-links').remove();
            oneX.$(oneX.Footer.createLinksDOM(json_a)).insertBefore('.-oneX-footer-last-updated');
            oneX.$('#oneX-footer-links-a').remove();
            oneX.$('#oneX-footer-links-b').remove();
            break;
          }
          case "b": {
            oneX.$('#oneX-footer-links').remove();
            oneX.$(oneX.Footer.createLinksDOM(json_b)).insertBefore('.-oneX-footer-last-updated');
            oneX.$('#oneX-footer-links-a').remove();
            oneX.$('#oneX-footer-links-b').remove();
            break;
          }
          case "none": {
            // git rid of all three
            oneX.$('.-oneX-footer-links').remove();
            oneX.$('#oneX-footer-links-a').remove();
            oneX.$('#oneX-footer-links-b').remove();
            break;
          }
          }

          _element.children(':first').unwrap();

          oneX.Footer.init();
          oneX.Modal.init();

          oneX.Footer.resetSubMenus(); // init them to closed
          if (callback && typeof(callback) === "function"){
            callback(_element);
          }
        } else {
          console.debug("Error with loading the footer " + response + "--" + footerPath + footerHTML);
        }
      });
      
    },

    createLinksDOM: function (footerJSON) {
      let linksDOM = '<div id="oneX-footer-links"class="-oneX-footer-links">';

      // main links
      linksDOM += '<div class="-oneX-footer-main-links">';
      linksDOM += '<ul role="list">';

      oneX.$.each(footerJSON.mainLinks.navList, 
        function (index, obj) {
          linksDOM += '<li role="listitem">';
          linksDOM += '<a href="' + obj.url + '" class="-oneX-link--block">' + obj.name + '</a>';
          linksDOM += '</li>';

        });
      linksDOM += '</ul>';

      //
      
      linksDOM += '<div id="oneX-footer-main-nav">';
      if (footerJSON.mainNavLink.url !== "" && footerJSON.mainNavLink.name !== '') {
        linksDOM += '<a href="' + footerJSON.mainNavLink.url  + '" class="-oneX-link--block">' + footerJSON.mainNavLink.name + '</a>';
        linksDOM += '<span> | </span>';
      }

      linksDOM += '<button class="-oneX-footer-show-more" aria-label="show more" aria-expanded="false"></button>';
      linksDOM += '</div>';
      linksDOM += '</div>';

      // col links
      linksDOM += '<div class="-oneX-footer-col-container -oneX-footer-links-hidden">';
      linksDOM += '<div class="-oneX-footer-cols">';

      oneX.$.each(footerJSON.columnLinks, function (index, obj) {
        linksDOM += '<div class="-oneX-footer-col">';
        linksDOM += '<div class="-oneX-footer-col-head">';
        linksDOM += '<h3>' + obj.colHead + '</h3>';
        linksDOM += '<button aria-expanded="false">' + obj.colHead + '</button>';
        linksDOM += '<div tabindex="-1" class="-oneX-footer-chevron -oneX-icon-container">';
        linksDOM += '<div class="-oneX-icon" data-icon="webui_chevron"> </div>';
        linksDOM += '<div class="-oneX-icon -oneX-footer-chevron-flip -oneX-hidden" data-icon="webui_chevron"> </div>';
        linksDOM += '</div>';
        linksDOM += '</div>';

        linksDOM += '<ul role="list">';

        oneX.$.each(obj.colLinks, function (indexCol, objCol) {

          linksDOM += '<li role="listitem">';
          linksDOM += '<a href="' + objCol.url + '" class="-oneX-link--block">' + objCol.name + '</a>';
          linksDOM += '</li>';
        });

        linksDOM += '</ul>';
        linksDOM += '</div>';
      });

      linksDOM += '</div>';
      linksDOM += '</div>';

      linksDOM += '</div>';

      return linksDOM;
    },

    setLanguageLink: function(url){
      const $footer_languageLink = oneX.$('.-oneX-lang-link').find('a');
      $footer_languageLink.removeClass("-oneX-hidden");
      $footer_languageLink.attr('href', url);
    },

    subsituteSpaceForClick: function (event) {
      event.preventDefault();
      event.stopPropagation();

      if (oneX.$(event.target).attr('role') === 'button') {
        event.target.click();
      }
    },

    toggleColContainer: function () {
      oneX.$('.-oneX-footer-col-container').toggleClass('-oneX-footer-links-hidden');
      const $showButton = oneX.$('.-oneX-footer-show-more');
      if ($showButton.hasClass('active')) {
        $showButton.removeClass('active');
        $showButton.attr('aria-label', 'show more');
        $showButton.attr('aria-expanded', 'false');
      }
      else {
        $showButton.addClass('active');
        $showButton.attr('aria-label', 'show less');
        $showButton.attr('aria-expanded', 'true');
      }
    },

    toggleSubMenu: function (event) {

      // target will be the h3 or the path of the chevron icon
      if (window.matchMedia('(max-width: 767px)').matches) {
        const $colHeadDiv = oneX.$(event.target).closest('.-oneX-footer-col-head'),
          $colContainer = $colHeadDiv.siblings('ul');

        $colContainer.toggleClass('-oneX-footer-menu-hide');
        $colHeadDiv.find('.-oneX-icon').toggleClass('-oneX-hidden');
        $colHeadDiv.find('button').attr('aria-expanded') === "true" ? $colHeadDiv.find('button').attr('aria-expanded','false') : $colHeadDiv.find('button').attr('aria-expanded','true');
      }
    },

    resetSubMenus: function () {
      const $colHead = oneX.$('.-oneX-footer-col-head');

      $colHead.siblings('ul').addClass('-oneX-footer-menu-hide');
      $colHead.find('.-oneX-footer-chevron-flip').addClass('-oneX-hidden');
      $colHead.find('.-oneX-icon:not(.-oneX-footer-chevron-flip)').removeClass('-oneX-hidden');
      $colHead.find('button').attr('aria-expanded','false');
    },

    checkForKAMPYLE: function () {
      if (typeof KAMPYLE_ONSITE_SDK !== 'undefined') {
        oneX.Footer.showFeedbackLink();
      }
      else {
        window.addEventListener('neb_OnsiteLoaded', oneX.Footer.showFeedbackLink);
      }
    },

    showFeedbackLink: function () {
      const $feedbackLink = oneX.$('.-oneX-give-us-feedback');

      $feedbackLink.removeClass('-oneX-hidden');
      $feedbackLink.off('click', oneX.Footer.openFeedbackLink).on('click', oneX.Footer.openFeedbackLink);
      $feedbackLink.off('keypress', oneX.Footer.openFeedbackLink).on('keypress', oneX.Footer.openFeedbackLink);
    },

    openFeedbackLink: function () {
      KAMPYLE_ONSITE_SDK.showForm(1164);
    },

    kiboFooter: null
  };

  oneX.Config.queues(_COMPONENT);
})();
