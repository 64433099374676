(function () {
  const _COMPONENT = {
    name: 'FormSelectors',
    selectors: '.-oneX-checkbox-group, .-oneX-radio-group'
  };

  oneX.FormSelectors = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        if (_element.hasClass('-oneX--box-selector')) {
          _element.on('focusin', 'input', _self.bringForward);
          _element.on('click', _self.clickSelector);
          _element.on('mouseenter', 'input + label', _self.bringForward);
          _element.on('focusout blur', 'input', _self.removeZindex);
          _element.on('mouseleave', 'input + label', _self.removeZindex);
          _element.on('mousedown', 'input + label', _self.bringForward);
          _element.on('mouseup mouseleave', 'input + label', _self.removeZindex);
        }
        _self.makeElementsDisabledReadOnly(_element);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this;
      if (_element.hasClass('-oneX--box-selector')) {
        _element.off('focusin', 'input', _self.bringForward);
        _element.off('click', _self.clickSelector);
        _element.off('mouseenter', 'input + label', _self.bringForward);
        _element.off('focusout blur', 'input', _self.removeZindex);
        _element.off('mouseleave', 'input + label', _self.removeZindex);
        _element.off('mousedown', 'input + label', _self.bringForward);
        _element.off('mouseup mouseleave', 'input + label', _self.removeZindex);
      }

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    makeElementsDisabledReadOnly: function (_element) {
      const $input = oneX.$('input', _element);

      if (_element.is('[disabled]')) {
        $input.attr('disabled', 'disabled');
        oneX.FormSelectors.addRemoveSelected($input.parent().find('input:checked').parent());
      }

      if (_element.is('[readonly]')) {
        $input.attr('readonly', 'readonly');
        oneX.FormSelectors.addRemoveSelected($input.parent().find('input:checked').parent());
      }
    },

    clickSelector: function (event) {
      oneX.FormSelectors.addRemoveSelected(oneX.$(event.currentTarget).find('input:checked').parent());
    },

    addRemoveSelected: function ($selected) {

      $selected.addClass('-oneX--selected');
      $selected.siblings().removeClass('-oneX--selected');

    },

    bringForward: function (currentElement) {
      const $parent = oneX.$(currentElement.currentTarget).parent();

      $parent.addClass('-oneX--bring-forward');

    },

    removeZindex: function (currentElement) {
      const $parent = oneX.$(currentElement.currentTarget).parent();

      if (!$parent.find('input').is(oneX.$( document.activeElement ))) {
        $parent.removeClass('-oneX--bring-forward');
      }

    }

  };

  oneX.Config.queues(_COMPONENT);
})();
