(function () {
  const _COMPONENT = {
    name: 'Tabs',
    selectors: '.-oneX-tabs-container'
  };

  oneX.Tabs = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $tabsItem = oneX.$(_element).find(".-oneX-tabs-item");

        $tabsItem.on('click touchend', _self.clickHandler);
        $tabsItem.on('touchstart touchcancel', _self.setDraggingOff);
        $tabsItem.on('touchmove', _self.setDraggingOn);
        $tabsItem.on('keydown', _self.keypressHandler);
        $tabsItem.hover(_self.redBarIn, _self.redBarOut);

        _self.debouncedAdjustRedBarIn = oneX.Util.debounce.debounce(_self.adjustRedBarIn, 250);

        oneX.$(window).on('orientationchange', _self.adjustRedBarIn);

          if (!oneX.$("body").hasClass("-oneX-tabs-resize-bound")){
            oneX.$(window).on("resize", _self.debouncedAdjustRedBarIn);
            oneX.$("body").addClass("-oneX-tabs-resize-bound");
          }

        _self.addDefaultRedBar(_element);

        _element.data(_COMPONENT.name, _COMPONENT.name);

      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $tabsItem = oneX.$(_element).find(".-oneX-tabs-item");

      $tabsItem.off('click touchend', _self.clickHandler);
      $tabsItem.off('touchstart touchcancel', _self.setDraggingOff);
      $tabsItem.off('touchmove', _self.setDraggingOn);
      $tabsItem.off('keydown', _self.keypressHandler);
      $tabsItem.off('mouseenter', _self.redBarIn)
      $tabsItem.off('mouseleave', _self.redBarOut);
      oneX.$(window).off("orientationchange", _self.adjustRedBarIn);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);

    },

    addDefaultRedBar: function (_element) {
      const $target = _element.find('.-oneX-tabs-button[tabindex="0"]').parent();
      setTimeout(function () {
        oneX.Tabs.redBarIn({
          currentTarget: $target
        });
      }, 250);
    },

    clickHandler: function (event) {
      if (oneX.Tabs.dragging) {
        return;
      }

      if (oneX.Tabs.alreadyClickedTab) {
        return;
      }

      oneX.Tabs.alreadyClickedTab = true;
      setTimeout(function () {oneX.Tabs.alreadyClickedTab = false;}, oneX.Tabs.animationSpeed);

      const $targetLi = oneX.$(event.currentTarget),
        $targetButton = $targetLi.find('.-oneX-tabs-button'),
        targetTab = $targetButton.attr('aria-controls'),
        $container = $targetButton.closest('.-oneX-tabs-container'),
        $targetPanel = $container.find("[id='" + targetTab + "']"),

        targetTotalLeft = $targetLi.outerWidth() + $targetLi.position().left,
        $targetScrollContainer = $targetLi.closest('.-oneX-tabs-scroller'),
        targetScrollContainerWidth = $targetScrollContainer.scrollLeft() + $targetScrollContainer.outerWidth(),
        scrollWidth = $targetScrollContainer.scrollLeft() + targetTotalLeft - targetScrollContainerWidth + 40;

      $container.find('.-oneX-tabs-button').attr({'aria-selected': false, 'tabindex': '-1'});
      $targetButton.attr({'aria-selected': true, 'tabindex': '0'});

      // on narrow screen, scrolls the selected item into view
      //$targetScrollContainer.scrollLeft(scrollWidth);
      $targetScrollContainer.animate( { scrollLeft: scrollWidth }, 800, "swing");

      if ($targetPanel.length === 0) {
        console.error('target panel with the corresponding id', targetTab, 'does not match');
      } else {
        $container.find('.-oneX-tabs-panel').addClass('-oneX-hidden');
        $targetPanel.removeClass('-oneX-hidden');
      }

      oneX.Tabs.redBarIn(event);

    },

    keypressHandler: function (event) {
      if (event.keyCode === 39 || event.keyCode === 37) {
        const $target = oneX.$(event.target),
          $parentElement = $target.closest('.-oneX-tabs-item'),
          $container = $target.closest('.-oneX-tabs-container');

        $container.find('.-oneX-tabs-button').attr({'aria-selected': false,'tabindex': '-1' }).blur();

        let $nextElement = event.keyCode === 39 ? $parentElement.next() : $parentElement.prev();
        if ($nextElement.length === 0 || $nextElement.is(':last-child')) {
          const count = $target.closest('.-oneX-tabs-list').children().length - 1;
          $nextElement = event.keyCode === 39 ? $container.find('.-oneX-tabs-item:first-child') : $container.find('.-oneX-tabs-item:nth-child(' + count + ')');
        }

        const $currentElement = $nextElement.find('.-oneX-tabs-button'),
          targetTab = $currentElement.attr('aria-controls');

        $currentElement.attr({'tabindex': '0','aria-selected': true }).focus();
        $container.find('.-oneX-tabs-panel').addClass('-oneX-hidden');
        $container.find("[id='" + targetTab + "']").removeClass('-oneX-hidden');

        oneX.Tabs.redBarIn({
          currentTarget: $currentElement.closest('.-oneX-tabs-item')
        });
      }
    },
    redBarIn: function (event) { // hover in handler

      // IOS and Android mobile fires hover events as mouseenter or mouseleave even when the user clicked. We need to ignore these extra events.
      if (window.matchMedia('(max-width: 767px)').matches && (event.type === 'mouseenter' || event.type === 'mouseleave')) {
        return;
      }

      const $element = oneX.$(event.currentTarget),
        $redBar = $element.closest('.-oneX-tabs-container').find("ul.-oneX-tabs-list li:last-of-type"),
        leftPos = $element.position().left + (($element.width() - 80) / 2) + 6; // +6 is the adjustment for centering the redbar

      oneX.Tabs.redBarAnimation($redBar, {
        left: leftPos
      });
    },

    redBarOut: function (event) { // hover out handler

      const $container = oneX.$(event.currentTarget).closest('.-oneX-tabs-container'),
        $redBar = $container.find("ul.-oneX-tabs-list li:last-of-type"),
        $element = $container.find('.-oneX-tabs-button[aria-selected = true]').closest('.-oneX-tabs-item'),
        leftPos = $element.position().left + (($element.width() - 80) / 2) + 6; // +6 is the adjustment for centering the redbar

      oneX.Tabs.redBarAnimation($redBar, {
        left: leftPos
      });
    },

    redBarAnimation: function ($redBar, cssProperties) {
      $redBar.stop().animate(
        cssProperties,
        700,
        "swing",
        function () {}
      );
    },

    adjustRedBarIn: function () {

      oneX.$(_COMPONENT.selectors).each(function () {
        const $target = oneX.$(this).find('.-oneX-tabs-button[aria-selected="true"]').closest('.-oneX-tabs-item');
        setTimeout(function () {
          oneX.Tabs.redBarIn({
            currentTarget: $target
          });
        }, 0);
      });
    },

    setDraggingOn: function () {
      oneX.Tabs.dragging = true;
    },

    setDraggingOff: function () {
      oneX.Tabs.dragging = false;
    },

    dragging: false,
    alreadyClickedTab: false,
    animationSpeed: 350,
    debouncedAdjustRedBarIn: null
  };

  oneX.Config.queues(_COMPONENT);
})();
