(function () {
  const _COMPONENT = {
    name: "MultiSelect",
    selectors: ".-oneX-multi-select",
  };

  oneX.MultiSelect = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $btn = oneX.$(_element).find(".-oneX-multi-select-input"),
          $checkbox = oneX.$(_element).find(".-oneX-checkbox-item input"),
          $msLabel = oneX
            .$(_element)
            .find("label[for='" + $btn.attr("id") + "']");

        $btn.on("click touchend", _self.clickHandler);
        $btn.on("touchstart touchcancel", _self.setDraggingOff);
        $btn.on("touchmove", _self.setDraggingOn);
        $btn.on("keydown", _self.btnKeyPress);

        $checkbox.on("keydown", _self.checkboxKeyPress);
        $checkbox.on("click", _self.optionOnClick);

        if ($msLabel.is(".-oneX-dropdown__floatingLabel")) {
          _element.on("change blur", _self.handleFloatingLabel);

          _self.handleFloatingLabel(_element);
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $btn = oneX.$(_element).find(".-oneX-multi-select-input"),
        $checkbox = oneX.$(_element).find(".-oneX-checkbox-item input");

      $btn.off("click touchend", _self.clickHandler);
      $btn.off("touchstart touchcancel", _self.setDraggingOff);
      $btn.off("touchmove", _self.setDraggingOn);
      $btn.off("keydown", _self.btnKeyPress);

      $checkbox.off("keydown", _self.checkboxKeyPress);
      $checkbox.off("click", _self.optionOnClick);

      _element.off("change blur", _self.handleFloatingLabel);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    setDraggingOn: function () {
      oneX.MultiSelect.dragging = true;
    },

    setDraggingOff: function () {
      oneX.MultiSelect.dragging = false;
    },

    clickHandler: function (event) {
      if (oneX.MultiSelect.dragging) {
        // if touchend was part of scrolling the screen
        return;
      }

      if (oneX.MultiSelect.alreadyClickedPanel) {
        return;
      }

      oneX.MultiSelect.alreadyClickedPanel = true;
      setTimeout(function () {
        oneX.MultiSelect.alreadyClickedPanel = false;
      }, 200);

      const $targetButton = oneX.$(event.currentTarget),
        $targetContent = oneX.Util.selectById(
          $targetButton.attr("aria-controls")
        );

      if ($targetButton.attr("aria-expanded") === "true") {
        //close panel
        $targetButton.attr("aria-expanded", false);
        $targetContent.addClass("-oneX-hidden");
      } else {
        //open panel
        if ($targetButton.hasClass("-oneX-multi-select-input")) {
          // close any sibling menu chips
          const $openButton = oneX.$(
            '.-oneX-multi-select-input[aria-expanded="true"]'
          );

          if ($openButton.length > 0) {
            const $openContent = oneX.Util.selectById(
              $openButton.attr("aria-controls")
            );
            $openContent.addClass("-oneX-hidden");
            $openButton.attr("aria-expanded", "false");
          }
          $targetButton.attr("aria-expanded", true);
          $targetContent.removeClass("-oneX-hidden");
        }

        // focus to first item in list
        // $targetContent.find(".-oneX-checkbox")[0].focus();

        // add close event handler
        oneX.MultiSelect.firstChipClick = 1;
        oneX
          .$("body")
          .off("click", oneX.MultiSelect.bodyClickHandler)
          .on("click", oneX.MultiSelect.bodyClickHandler);
      }
    },

    bodyClickHandler: function (event) {
      if (oneX.MultiSelect.firstChipClick === 1) {
        oneX.MultiSelect.firstChipClick = 0;
        return;
      }

      const $openButton = oneX.$(
        '.-oneX-multi-select-input[aria-expanded="true"]'
      );

      if ($openButton.length > 0) {
        if (
          !oneX.$(event.target).siblings("input").hasClass("-oneX-checkbox") &&
          !oneX.$(event.target).hasClass("-oneX-checkbox")
        ) {
          oneX.MultiSelect.closeOpenMenu();
        }
      } else {
        // safety net, turn it off if no more open
        oneX.$("body").off("click", oneX.MultiSelect.bodyClickHandler);
      }
    },

    checkboxKeyPress: function (event) {
      const $currentTarget = oneX.$(event.currentTarget),
        $currentItem = $currentTarget.parent(),
        $chipBtn = $currentTarget
          .closest(".-oneX-chip-menu-content")
          .siblings(".-oneX-multi-select-input"),
        $allItems = [
          ...$currentItem
            .closest(".-oneX-chip-menu-content .-oneX-checkbox-group")
            .find("input"),
        ],
        $firstItem = $allItems[0],
        $lastItem = $allItems[$allItems.length - 1],
        $nextItem = $currentItem.next().find("input"),
        $prevItem = $currentItem.prev().find("input");

      if (event.key === "Tab") {
        if (event.shiftKey) {
          // handle shift-tab
          if ($prevItem.length === 0) {
            event.preventDefault();
            $chipBtn.focus();
          }
        } else {
          // handle tab
          if ($nextItem.length === 0) {
            oneX.MultiSelect.closeOpenMenu();
          }
        }
      }

      // Arrow up pressed
      if (event.key === "ArrowUp") {
        if ($prevItem.length === 0) {
          event.preventDefault();
          $lastItem.focus();
        } else {
          $prevItem.focus();
        }
      }

      // Arrow down pressed
      if (event.key === "ArrowDown") {
        if ($nextItem.length === 0) {
          event.preventDefault();
          $firstItem.focus();
        } else {
          $nextItem.focus();
        }
      }
    },

    btnKeyPress: function (event) {
      const $chipBtn = oneX.$(event.currentTarget),
        $chipContent = oneX.$("#" + $chipBtn.attr("aria-controls")),
        $firstItem = oneX.$($chipContent.find("input")[0]);

      if (event.key === "Tab") {
        if (event.shiftKey) {
          if (
            $chipBtn.attr("aria-expanded") === "true" &&
            !$chipContent.hasClass("-oneX-hidden")
          ) {
            oneX.MultiSelect.closeOpenMenu();
          }
        } else {
          if (
            $chipBtn.attr("aria-expanded") === "true" &&
            !$chipContent.hasClass("-oneX-hidden") &&
            $firstItem.length > 0
          ) {
            event.preventDefault();
            $firstItem.focus();
          }
        }
      }
    },

    closeOpenMenu: function (event) {
      const $openButton = oneX.$(
        '.-oneX-multi-select-input[aria-expanded="true"]'
      );
      if ($openButton.length > 0) {
        const $newEvent = oneX.$.Event("click", {
          currentTarget: $openButton.get(0),
        });
        oneX.MultiSelect.clickHandler($newEvent);
      }
    },

    _setOptionOnClickValueAndLabel: function(value, optionId) {
      oneX
          .$("[aria-controls= " + optionId + " ]")
          .html(
            value === 0 ? "‎" : "Options Selected (" + value + ")"
          );

      oneX
        .$("[aria-controls= " + optionId + " ]")
        .prop(
          "value",
          value === 0 ? "‎" : "Options Selected (" + value + ")"
        );
    },

    optionOnClick: function (event) {
      const currentTarget = oneX.$(event.currentTarget),
        groupName = currentTarget.attr("name"),
        optionId = oneX
          .$(event.currentTarget)
          .closest(".-oneX-chip-menu-content")[0].id,
        selectAllInput = oneX
          .$(event.currentTarget)
          .closest(".-oneX-chip-menu-content")
          .find("input.-oneX-select-all-checkbox").length
          ? oneX
              .$(event.currentTarget)
              .closest(".-oneX-chip-menu-content")
              .find("input.-oneX-select-all-checkbox")
          : oneX
              .$(event.currentTarget)
              .closest(".-oneX-chip-menu-content")
              .find("input.select-all-checkbox");

      let selectedItems = oneX
        .$(`input[name="${groupName}"]:checked`)
        .not("input.select-all-checkbox")
        .not("input.-oneX-select-all-checkbox").length

      // Select all doesn't exist
      if (selectAllInput.length === 0) {
        // Set text to either blank or options selected #
        oneX.MultiSelect._setOptionOnClickValueAndLabel(selectedItems, optionId)
      } else {
        // Select all option is present
        const allItems = oneX
            .$(event.currentTarget)
            .closest(".-oneX-chip-menu-content .-oneX-checkbox-group")
            .find("input:checkbox")
            .not("input.-oneX-select-all-checkbox")
            .not("input.select-all-checkbox"),
          allItemsCount = allItems.length

        // If current event is select all
        if (currentTarget.attr('class').match(/-oneX-select-all-checkbox|select-all-checkbox/) !== null) {
          if (!selectAllInput.prop("checked")) {
            allItems.prop("checked", false);
            selectedItems = 0;
            oneX.MultiSelect._setOptionOnClickValueAndLabel(selectedItems, optionId)
          } else {
            allItems.prop("checked", true);
            selectedItems += allItemsCount;
            oneX.MultiSelect._setOptionOnClickValueAndLabel(allItemsCount, optionId)
          }
        } else {
          // If select all is checked and unchecking option, uncheck select all and option
          if (selectAllInput.prop("checked")) {
            selectAllInput.prop("checked", false);
          }

          // If the item count is the total of items, check select all
          if (selectedItems === allItemsCount) {
            selectAllInput.prop("checked", true);
            selectedItems = allItemsCount;
          }

          oneX.MultiSelect._setOptionOnClickValueAndLabel(selectedItems, optionId)
        }
      }
    },

    handleFloatingLabel: function (event) {
      const $input = event.target ? oneX.$(event.target) : event,
        $label = oneX.$('label[for="' + $input.attr("id") + '"]'),
        $msInput = $label
          .parent()
          .closest(".-oneX-chip-menu-content")
          .siblings(".-oneX-multi-select-input"),
        $msFloatingInputLabel = oneX.$(
          'label[for="' + $msInput.attr("id") + '"]'
        );

      if ($msInput.is(":focus") == false && $msInput.val() !== "‎") {
        $msFloatingInputLabel.addClass("-oneX--floatUp");
      } else {
        $msFloatingInputLabel.removeClass("-oneX--floatUp");
      }
    },

    alreadyClickedPanel: false,
    dragging: false,
    firstChipClick: 0,
  };

  oneX.Config.queues(_COMPONENT);
})();
