(function () {
  const _COMPONENT = {
    name: 'AccountSwitcher',
    selectors: '[data-type^="account-switcher"], .-oneX-account-switcher'
  };

  oneX.AccountSwitcher = {
    init: function (callback) {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors), callback);
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element, callback) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        if (_element.data("type") && _element.is(":empty")) {
          _self.renderHTML(_element, callback);
        }

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents: function (_element) {
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    renderHTML: function (_element, callback) {
      const accounts = _element.data("accounts");

      _element.load(`${oneX.Util.environment.getRenderedHtmlOrigin('component')}/1x-account-switcher.html`, function (response, status) {
        if (status === "success") {

          const $accountList = _element.find('ul');

          accounts.map(account => {
            $accountList.append(`
            <li>
              <a role="option" class="-oneX-link--block--variant ${account.selected ? '-oneX-account-switcher-selected' : ''}" aria-current="${account.selected ? true : false}" href=${account.url}>
                <div class="-oneX-icon-container">
                  <div class="-oneX-icon" data-icon="${account.primary ? 'user_icon' : 'small_business_insurance'}_24">
                  </div>
                </div>
                <div class="-oneX-account-switch-name">
                  ${account.name}
                </div>
              </a>
            </li>
            `)
          });

          _element.children(':first').unwrap();

          oneX.Icons.init();
          
          if (callback && typeof (callback) === "function") {
            callback();
          }

        } else {
          console.debug("Error with loading account-switcher " + response);
        }
      });
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
