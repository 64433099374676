(function() {
  const _COMPONENT = {
    name : 'HelpMenu',
    selectors : '.-oneX-helpMenu , .-oneX-helpMenu-limited'
  };

  oneX.HelpMenu = {
    init : function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });
      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      //let cEvent;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        oneX.$(".-oneX-helpMenuCall").on('click', _self.togglePhoneNumber);
        oneX.$(".-oneX-return-to-helpMenu [data-oneX-chevron]").on('click', _self.togglePhoneNumber);
        oneX.$(".-oneX-header-navmenu-back").on('click', _self.resetPhoneNumber);
        oneX.$(".-oneX-util-cancel").on('click', _self.backToMainMenu);
        oneX.$(".-oneX-header-menu-button").on('click', _self.backToMainMenu);

        let cEvent = new CustomEvent('loadOneXHelp');
        //workaround for CustomEvent constructor not working in IE
        if (typeof (Event) === 'function') {
          cEvent = new CustomEvent("loadOneXHelp");
        } else {
          cEvent = document.createEvent( 'CustomEvent' );
          cEvent.initEvent("loadOneXHelp", true, true);
        }
        if (oneX.HelpMenu.savedPhoneNumber !== null){
          oneX.HelpMenu.setPhoneNumber(oneX.HelpMenu.savedPhoneNumber[0], oneX.HelpMenu.savedPhoneNumber[1]);
        }
        
        oneX.$(document).on('Help-Module-Status', _self.adjustHelpMenu);
        oneX.$(document).on('lpStatus-Chat-Loaded', _self.adjustChatButton);
        document.dispatchEvent(cEvent);
        
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },
    
    unbindEvents : function(_element) {
      const _self = this;

      oneX.$(".-oneX-helpMenuCall").off('click', _self.togglePhoneNumber);
      oneX.$(".-oneX-return-to-helpMenu [data-oneX-chevron]").off('click', _self.togglePhoneNumber);
      oneX.$(".-oneX-util-cancel").off('click', _self.backToMainMenu);
      oneX.$(".-oneX-header-navmenu-back").off('click', _self.resetPhoneNumber);
      oneX.$(".-oneX-header-menu-button").off('click', _self.backToMainMenu);

      oneX.$(document).off('Help-Module-Status', _self.adjustHelpMenu);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    adjustChatButton : function(){
      oneX.Icons.init();
      oneX.$(".LPMoverlay").attr("tabindex", -1);
    },

    adjustHelpMenu : function(event){
      const returnObject = event.detail;
      if (returnObject.active){
        oneX.HelpMenu.removeHiddenUtil();
        oneX.$('#helpCenterLink').removeClass('hidden-help-center');
        if (oneX.$.inArray("faq", returnObject.items) > -1){
          oneX.$('.-oneX-helpMenuFaq').closest('li').removeClass('-oneX-hidden');
        }
        if (oneX.$.inArray("c2c", returnObject.items) > -1){
          oneX.$('.-oneX-helpMenuCallback').closest('li').removeClass('-oneX-hidden');
        }
        //TODO: Add checks for c2c and chat
      }
    },

    togglePhoneNumber : function() {
      // if (event.keyCode === 32) {
      //   event.preventDefault();
      // }
      if (oneX.$(".-oneX-helpMenu-phone-num-container").hasClass("-oneX-hidden")) {
        oneX.$(".-oneX-help-links-container").addClass("-oneX-hidden");      
        oneX.$(".-oneX-helpMenu-phone-num-container").removeClass("-oneX-hidden").focus();
      }
      else {
        oneX.HelpMenu.resetPhoneNumber();
      }
    },

    resetPhoneNumber : function() {
      oneX.$(".-oneX-helpMenu-phone-num-container").addClass("-oneX-hidden");
      oneX.$(".-oneX-helpMenu-container").removeClass("-oneX-hidden");
      oneX.$(".-oneX-help-links-container").removeClass("-oneX-hidden");
      oneX.$(".-oneX-helpMenuCall").focus();
    },

    setPhoneNumber : function(dialDigits, visibleText) { 
      const $helpMenuPhone = oneX.$(".-oneX-helpMenuPhone");
      
      if ($helpMenuPhone.length > 0){
        $helpMenuPhone.find('a').attr('href', 'tel:' + dialDigits).html(visibleText);
        $helpMenuPhone.find('div').html(visibleText); 
        oneX.$('#helpCenterLink').removeClass('hidden-help-center');
        oneX.HelpMenu.removeHiddenUtil();
      } else {
        oneX.HelpMenu.savedPhoneNumber = [dialDigits, visibleText];
      }

    },

    backToMainMenu : function() {
      if (!(oneX.$(".-oneX-helpMenu-phone-num-container").hasClass("-oneX-hidden"))) { 
        oneX.$(".-oneX-helpMenu-phone-num-container").addClass("-oneX-hidden");  
        oneX.$(".-oneX-header-util-nav").removeClass("-oneX-hidden");
        oneX.$(".-oneX-header-util-menu-btn").removeClass("-oneX-hidden");   
        oneX.$(".-oneX-help-links-container").removeClass("-oneX-hidden");   
      }
    },

    removeHiddenUtil : function(langLink = false) {
      if (!langLink) {
        oneX.$('.-oneX-header-menu-button').removeClass('-oneX-hidden-limited-header');
      }
      oneX.$('.-oneX-header').removeClass('-oneX-hidden-limited-header');
      oneX.$('.-oneX-header-util-nav').removeClass('-oneX-hidden-limited-header');
  },

    savedPhoneNumber: null
  };

  oneX.Config.queues(_COMPONENT);
})();
