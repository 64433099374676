(function () {
  const _COMPONENT = {
    name: 'Chips',
    selectors: '.-oneX-chip-menu'
  };

  oneX.Chips = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $btn = oneX.$(_element).find('.-oneX-chip-button'),
          $radioBtn = oneX.$(_element).find('.-oneX-radio-item input'),
          $checkbox = oneX.$(_element).find('.-oneX-checkbox-item input');

        $btn.on('click touchend', _self.clickHandler);
        $btn.on('touchstart touchcancel', _self.setDraggingOff);
        $btn.on('touchmove', _self.setDraggingOn);
        $btn.on('keydown', _self.btnKeyPress);

        $radioBtn.on('keydown', _self.radioKeyPress);
        $checkbox.on('keydown', _self.checkboxKeyPress);

        _element.data(_COMPONENT.name, _COMPONENT.name);

      }
    },

    unbindEvents: function (_element) {
      const _self = this,
        $btn = oneX.$(_element).find('.-oneX-chip-button'),
        $radioBtn = oneX.$(_element).find('.-oneX-radio-item input'),
        $checkbox = oneX.$(_element).find('.-oneX-checkbox-item input');

      $btn.off('click touchend', _self.clickHandler);
      $btn.off('touchstart touchcancel', _self.setDraggingOff);
      $btn.off('touchmove', _self.setDraggingOn);
      $btn.off('keydown', _self.btnKeyPress);

      $radioBtn.off('keydown', _self.radioKeyPress);
      $checkbox.off('keydown', _self.checkboxKeyPress);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);

    },

    setDraggingOn: function() {
      oneX.Chips.dragging = true;
    },

    setDraggingOff: function() {
      oneX.Chips.dragging = false;
    },

    clickHandler: function (event) {

      if (oneX.Chips.dragging) {
        // if touchend was part of scrolling the screen
        return;
      }
    
      if (oneX.Chips.alreadyClickedPanel) {
        return;
      }
    
      oneX.Chips.alreadyClickedPanel = true;
      setTimeout(function () { oneX.Chips.alreadyClickedPanel = false; }, 200);
            
      const $targetButton = oneX.$(event.currentTarget),
        $targetContent = oneX.Util.selectById($targetButton.attr('aria-controls'));

      if($targetButton.attr('aria-expanded') === "true"){
        //close panel
        $targetContent.addClass("-oneX-hidden");
        $targetButton.attr('aria-expanded', false);
      }
      else
      {
        //open panel

        if ($targetButton.hasClass('-oneX-chip-button')) {
          // close any sibling menu chips

          const $openButton = $targetButton.parent().siblings().find('button[aria-expanded="true"]');
          if ($openButton.length > 0) {
            // console.log("closed", $openButton.html());
            const $openContent = oneX.Util.selectById($openButton.attr('aria-controls'));
            $openContent.addClass("-oneX-hidden");
            $openButton.attr('aria-expanded', "false");
    
          }

        }

        $targetButton.attr('aria-expanded', true);
        $targetContent.removeClass("-oneX-hidden");

        // focus to first item in list
        $targetContent.find('.-oneX-radio, .-oneX-checkbox')[0].focus();

        // add close event handler
        oneX.Chips.firstChipClick = 1;
        oneX.$('body').off('click', oneX.Chips.bodyClickHandler).on('click', oneX.Chips.bodyClickHandler);
        // console.log('body event is on');
      }
    },


    bodyClickHandler: function(event) {

      if (oneX.Chips.firstChipClick === 1) {
        oneX.Chips.firstChipClick = 0;
        // console.log("Ignored first click");
        return;
      }

      const $openButton = oneX.$('.-oneX-chip-button[aria-expanded="true"]');

      if ($openButton.length > 0) {
        if (!oneX.$(event.target).siblings('input').hasClass('-oneX-checkbox') && !oneX.$(event.target).hasClass('-oneX-checkbox') && 
        !oneX.$(event.target).siblings('input').hasClass('-oneX-radio') && !oneX.$(event.target).hasClass('-oneX-radio')) {
          oneX.Chips.closeOpenMenu();
        }
      } else {
        // safety net, turn it off if no more open
        oneX.$('body').off('click',oneX.Chips.bodyClickHandler);
        // console.log('safety net: body event turned off');
      }
    },

    radioKeyPress: function(event) {
      if (event.key === 'Tab') {
        const $currentTarget = oneX.$(event.currentTarget),
          $currentItem = $currentTarget.parent(),
          $chipBtn = $currentTarget.closest('.-oneX-chip-menu-content').siblings('.-oneX-chip-button'),
          $nextItem = $currentItem.next().find('input'),
          $prevItem = $currentItem.prev().find('input');

        if (event.shiftKey) {
          // handle shift-tab
          if ($prevItem.length > 0) {
            event.preventDefault();
            $prevItem.focus();
          }
          else {
            event.preventDefault();
            $chipBtn.focus();
          }
        }
        else {
          // handle tab
          if ($nextItem.length > 0) {
            event.preventDefault();
            $nextItem.focus();
          }
          else {
            oneX.Chips.closeOpenMenu();
          }
  
        }
      } //else {console.log('radioKeyPress', event.type, event)}
    },

    checkboxKeyPress: function (event) {
      if (event.key === 'Tab') {
        const $currentTarget = oneX.$(event.currentTarget),
          $currentItem = $currentTarget.parent(),
          $chipBtn = $currentTarget.closest('.-oneX-chip-menu-content').siblings('.-oneX-chip-button'),
          $nextItem = $currentItem.next().find('input'),
          $prevItem = $currentItem.prev().find('input');

        if (event.shiftKey) {
          // handle shift-tab
          if ($prevItem.length === 0) {
            event.preventDefault();
            $chipBtn.focus();
          }
        }
        else {
          // handle tab
          if ($nextItem.length === 0) {
            oneX.Chips.closeOpenMenu();
          }
  
        }
      } // else {console.log('radioKeyPress', event.type, event)}     
    },

    btnKeyPress: function (event) {
      const $chipBtn = oneX.$(event.currentTarget),
        $chipContent = oneX.$('#' + $chipBtn.attr('aria-controls')),
        $firstItem = oneX.$($chipContent.find('input')[0]);

      if (event.key === 'Tab') {
        if (event.shiftKey) {
          if ($chipBtn.attr('aria-expanded') === 'true' && !$chipContent.hasClass('-oneX-hidden')) {
            oneX.Chips.closeOpenMenu();
          }
        }
        else {
          if ($chipBtn.attr('aria-expanded') === 'true' && !$chipContent.hasClass('-oneX-hidden') && $firstItem.length > 0) {
            event.preventDefault();
            $firstItem.focus();
          }
        }
      }
    },

    closeOpenMenu: function(event) {
      // console.log('close open menu', event);
      const $openButton = oneX.$('.-oneX-chip-button[aria-expanded="true"]');

      if ($openButton.length > 0) {
        const $newEvent = oneX.$.Event('click', {currentTarget: $openButton.get(0) });
        oneX.Chips.clickHandler($newEvent);
        // console.log("Closed");
      } // else {console.log("already closed")}
    },

    alreadyClickedPanel: false,
    dragging: false,
    firstChipClick: 0

  };

  oneX.Config.queues(_COMPONENT);
})();
