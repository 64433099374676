(function () {
  const _COMPONENT = {
    name: 'FocusRing',
    selectors: 'body'
  };

  oneX.FocusRing = {
    init: function () {
      const _self = this;
      _self.bindEvents(oneX.$(_COMPONENT.selectors));
      return this;
    },

    addElement: function (_element) {
      this.bindEvents(_element);
    },

    removeElement: function (_element) {
      this.unbindEvents(_element);
    },

    bindEvents: function (_element) {

      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.kiboTabHandler = new Kibo(_element[0]);
        _self.kiboTabHandler.down("tab", _self.handleFirstTab);
        _self.kiboTabHandler.down("shift tab", _self.handleFirstShiftTab);
        _element.data(_COMPONENT.name, _COMPONENT.name);

      }
    },

    unbindEvents: function (_element) {
      const _self = this;

      _self.kiboTabHandler.unregisterKeys("down", "tab", _self.handleFirstTab);
      _self.kiboTabHandler.unregisterKeys("down", "shift tab", _self.handleFirstShiftTab);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    handleFirstTab: function () {
      const _self = oneX.FocusRing;
      oneX.$('body').addClass('-oneX-user-tabbing').removeClass('-oneX-user-back-tabbing');
      _self.kiboTabHandler.unregisterKeys("down", "tab", _self.handleFirstTab);
      _self.kiboTabHandler.unregisterKeys("down", "shift tab", _self.handleFirstShiftTab);
      _self.kiboTabHandler.down("shift tab", _self.handleFirstShiftTab);
      oneX.$(window).on('mousedown', _self.handleMouseDownOnce);
    },

    handleFirstShiftTab: function () {
      const _self = oneX.FocusRing;
      oneX.$('body').addClass('-oneX-user-tabbing').addClass('-oneX-user-back-tabbing');
      _self.kiboTabHandler.unregisterKeys("down", "tab", _self.handleFirstTab);
      _self.kiboTabHandler.unregisterKeys("down", "shift tab", _self.handleFirstShiftTab);
      _self.kiboTabHandler.down("tab", _self.handleFirstTab);
      oneX.$(window).on('mousedown', _self.handleMouseDownOnce);
    },

    handleMouseDownOnce: function () {
      const _self = oneX.FocusRing;
      oneX.$('body').removeClass('-oneX-user-tabbing').removeClass('-oneX-user-back-tabbing');
      _self.kiboTabHandler.down("tab", _self.handleFirstTab);
      _self.kiboTabHandler.down("shift tab", _self.handleFirstShiftTab);
      oneX.$(window).off('mousedown', _self.handleMouseDownOnce);
    },

    kiboTabHandler: null
  };

  oneX.Config.queues(_COMPONENT);
}
)
();


