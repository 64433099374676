(function() {
  const _COMPONENT = {
    name : 'AutoFormat',
    selectors : "[class*='-oneX-textfield'][data-formatter]"
  };

  oneX.AutoFormat = {
    init : function() {
      const _self = this;
      _self.isSpanish = oneX.Util.locale.isSpanish();
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;

      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {

        _self.isAndroid = /android/i.test(navigator.userAgent);

        if (!_element.attr("data-placeholder")) {
          const defaultValues = _self.formatterMap[_element.attr("data-formatter")];

          _self.insertPlaceholderText(_element, defaultValues);
          _self.defineFormatPattern(_element, defaultValues);
          _self.clippedTextHandler(_element, defaultValues);

          _element.data("1x-manualSetValue", true);

          _element.on('input', _self.handleInput).trigger("input");
          _element.on('keydown', _self.handleKeyDown);
          _element.on('focus blur', _self.handleFocusBlur);
          _element.on('focusout', _self.formatCurrency);
          _element.on('change', _self.handleChange);
          _element.on('keyup', _self.handleKeyup);

          _element.data(_COMPONENT.name, _COMPONENT.name);
        }
      }
    },

    unbindEvents : function(_element) {
      const _self = this;

      _element.off('input', _self.handleInput);
      _element.off('keydown', _self.handleKeyDown);
      _element.off('focus blur', _self.handleFocusBlur);
      _element.off('focusout', _self.formatCurrency);
      _element.off('change', _self.handleChange);
      _element.off('keyup', _self.handleKeyup);

      _element.removeData('placeholder').removeAttr('data-placeholder').removeAttr('pattern');

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    insertPlaceholderText : function(input, defaultValues) {
      const placeholderTxt = input.attr('placeholder') || defaultValues.placeholder,
        medium = input.hasClass('-oneX-medium') ? " -oneX-medium " : "",
        small = input.hasClass('-oneX-small') ? " -oneX-small " : "";

      input.attr("data-placeholder", placeholderTxt).removeAttr('placeholder');
      oneX.$("label[for='" + input.attr('id') + "']").after("<span class='-oneX-formatWrapper" + small + medium + "' aria-hidden='true'><i></i> " + placeholderTxt + "</span>");

      if (input.hasClass('-oneX-textfield--conversational-input')) {
        if (input.prop('disabled') && input.prev().hasClass('-oneX-formatWrapper')) {
          input.prev().addClass('-oneX-formatWrapper--disabled')
        }
      }
    },

    defineFormatPattern : function(input, defaultValues) {
      if (input.attr("pattern") == undefined) {
        input.attr("pattern", defaultValues.pattern);
      }

      if (input.attr("data-formatter") === "phone" && input.attr("autocomplete") == undefined) {
        input.attr("autocomplete","tel");
      }

      if (input.attr("data-formatter") === "zip" && input.attr("autocomplete") == undefined) {
        input.attr("autocomplete","postal-code");
      }
    },

    clippedTextHandler : function(input, defaultValues) {
      const _self = this;
      let clippedText;

      if (input.attr("title") == undefined) {
        clippedText = defaultValues.title[(_self.isSpanish ? 1 : 0)];
      }
      else {
        clippedText = input.attr("title");
        input.removeAttr("title");
      }

      if (clippedText !== '') {
        oneX.$("label[for='" + input.attr("id") + "']").append(oneX.$( "<span class='-oneX-clipped'>" + clippedText + "</span>"));
      }
    },

    getCaretPosition : function(_this) {
      return _this.selectionStart;
    },

    setCaretPosition : function(_this, position) {
      _this.selectionStart = position;
      _this.selectionEnd = position;
    },

    handleKeyDown:function(event) {
      oneX.AutoFormat.keySelected =  event.keyCode;
    },

    handleFocusBlur : function(event) {
      const $input = oneX.$(this),
        value = $input.val(),
        placeholder = $input.attr('data-placeholder'),
        matchesPlaceholderChar = (oneX.AutoFormat.placeholderChar.indexOf(placeholder[0]) >= 0);
      if ($input.data('formatter') === 'currency') {
        $input.prev(".-oneX-formatWrapper").html("<i>" + value + "</i>");
        return;
      }
      if ($input.data('formatter') === 'date') {
        $input.val(value.replace(/[\/]/g, ""));  // get rid of '/' in autofill data
      }


      if ($input.data('formatter') === 'time') {
        if ($input.val().match(/^(10|11|12|0[1-9]):[0-5][0-9] [AaPp][Mm]/)) {
          oneX.Util.changeValue($input[0], $input.val().toUpperCase());
          oneX.Util.triggerEvent($input[0], "input");
        }
      }

      if (oneX.$.inArray($input.data('formatter'), ["canadianpostalcode", "vin"]) !== -1) {
        oneX.Util.changeValue($input[0], $input.val().toUpperCase());
        oneX.Util.triggerEvent($input[0], "input");
      }

      if (event.type === 'focus' && value.length === 0 && !matchesPlaceholderChar) {
        $input.val(placeholder[0]).addClass("oneX-addedInitialPlaceholder");
        oneX.AutoFormat.setCaretPosition(this, 1);
        $input.prev(".-oneX-formatWrapper").html("<i>(</i>" + placeholder.substr(1));
      }
      else if ($input.data('formatter') !== 'currency' && event.type == 'blur' && $input.hasClass("oneX-addedInitialPlaceholder")) {
        $input.val(oneX.AutoFormat.adjustForEmptyValue($input))
        $input.removeClass("oneX-addedInitialPlaceholder");
        $input.prev(".-oneX-formatWrapper").html("<i></i>" + placeholder);
      }

      if (event.type == 'blur'){
        oneX.AutoFormat.triggerChange(oneX.$(this));
      }

      if (event.type == 'focus'){
        oneX.AutoFormat.resetVars();
      }
    },

    handleInput : function(event) {
      if (oneX.AutoFormat.formatCompleted){
        oneX.AutoFormat.formatCompleted = false;
      } else {
        setTimeout(oneX.AutoFormat.format, 0, this);
      }
    },

    handleKeyup : function(event){
      if (event.which === 13){
        oneX.AutoFormat.triggerChange(oneX.$(this));
      }
    },

    resetVars : function(){
      oneX.AutoFormat.changeFired = false;
      oneX.AutoFormat.valueChanged = false;
    },

    triggerChange : function(_element){
      if (!oneX.AutoFormat.changeFired && oneX.AutoFormat.valueChanged){
        oneX.Util.triggerEvent(_element[0], "change");
        oneX.AutoFormat.resetVars();
      }
    },

    triggerInput : function(_element){
      oneX.AutoFormat.formatCompleted = true;
      oneX.Util.triggerEvent(_element[0], "input");
    },

    handleChange : function(event){
      if (event.originalEvent === undefined || event.originalEvent.detail === "triggeredEvent") {
        oneX.$(event.target).trigger("input");
      }
      else{
        oneX.AutoFormat.changeFired = true;
      }
    },

    format : function(_this) {
      const $input = oneX.$(_this);
      let value = $input.val(),
        prevValue =  $input.prev(".-oneX-formatWrapper").find("i").html();
      const strippedValue =  value.replace(/[-()$\,:\s]/g, ""),
        phoneStrippedValue =  value.replace(/[-()$+\,:\s]/g, ""),
        currencyStrippedValue =  value.replace(/[$\,]/g, "");
 
      if (oneX.$.inArray($input.data('formatter'), ["ssn","date","zip","phone","keycode","planAccountNumber","number"]) !== -1) {
        if (isNaN(strippedValue) || strippedValue.match(/\./g) || strippedValue.match(/\s/g)) {
          value = prevValue;
          $input.val(prevValue);
        }
      }

      if (oneX.$.inArray($input.data('formatter'), ["canadianpostalcode"]) !== -1) {
        if ((strippedValue.charAt(0) !== "" && !strippedValue.charAt(0).match(/[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]/)) ||
          (isNaN(strippedValue.charAt(1))) ||
          (strippedValue.charAt(2) !== "" && !strippedValue.charAt(2).match(/[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]/)) ||
          (isNaN(strippedValue.charAt(3))) ||
          (strippedValue.charAt(4) !== "" && !strippedValue.charAt(4).match(/[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]/)) ||
          (isNaN(strippedValue.charAt(5)))) {
          value = prevValue;
          $input.val(prevValue);
        }
      }

      if (oneX.$.inArray($input.data('formatter'), ["vin"]) !== -1) {
        const patt = "[A-HJ-NPR-Za-hj-npr-z0-9]{" + strippedValue.length + "}";
        // console.log(strippedValue, RegExp(patt), strippedValue.match(RegExp(patt)));
        if (strippedValue !== "" && !strippedValue.match(RegExp(patt))) {
          value = prevValue;
          $input.val(prevValue);
        }
      }

      if ($input.data('formatter') === 'phone') {

        if (value.charAt(0) == '0'){
          value = prevValue;
          $input.val(prevValue);
        }

        if (phoneStrippedValue.charAt(0) == '1' && phoneStrippedValue.length == 11 && phoneStrippedValue.charAt(10).match(/[0123456789]/) && phoneStrippedValue.charAt(1) != '0') {
          prevValue = phoneStrippedValue.substr(1,3) + "-" + phoneStrippedValue.substr(4,3) + "-" + phoneStrippedValue.substr(7,4);
          value = prevValue;
          $input.val(prevValue);
          $input.prev(".-oneX-formatWrapper").html( "<i>" + prevValue + "</i>");
        }
      }

      if ($input.data('formatter') === 'currency') {
        if (isNaN(currencyStrippedValue) || currencyStrippedValue.match(/[-()\s]/g)) {
          prevValue = value === '.' ? "$." : prevValue;
          prevValue = value === '$.' ? "$." : prevValue;
          value = prevValue;
          $input.val(prevValue);
        }
        else {
          // if user has cleared the field, then put '$' back in with first number typed
          if (value.length > 0 && value.substr(0,1) !== '$') {
            value = '$' + value;
            $input.prev(".-oneX-formatWrapper").html( "<i>" + value + "</i>");
            $input.val(value);
          }
          else {
            const caret = oneX.AutoFormat.getCaretPosition(_this);
            const newValue = value;
            $input.val(newValue);
            $input.prev(".-oneX-formatWrapper").html( "<i>" + newValue + "</i>");
            oneX.AutoFormat.setCaretPosition(_this, caret);
          }
        }
        return;
      }

      let caretPos = oneX.AutoFormat.getCaretPosition(_this);

      oneX.AutoFormat.valueChanged = true;

      const isDeletingChar = (oneX.AutoFormat.isAndroid) ? (value.length == prevValue.length - 1) : (oneX.AutoFormat.keySelected == 8);
      const adjustedCaretPos = caretPos - (value.substring(0, (caretPos)).replace(/[^-():\s]/g, "").length);
      const placeholder = $input.attr('data-placeholder');

      let newValue = "";

      oneX.AutoFormat.keySelected =  "";

      if($input.is("[readonly]")){
        $input.prev(".-oneX-formatWrapper").addClass("-oneX-isFormatterForReadOnlyField");
      } else {
        $input.prev(".-oneX-formatWrapper").removeClass("-oneX-isFormatterForReadOnlyField");
      }

      if (value == prevValue) {
        return;
      }

      $input.removeClass("oneX-addedInitialPlaceholder");

      //updateValue
      for (let i = 0, j = 0; i < placeholder.length; i++) {
        if (strippedValue == "") {
          break;
        }

        if (oneX.AutoFormat.placeholderChar.indexOf(placeholder[i]) >= 0) {
          newValue += strippedValue[j++];

          if (j == adjustedCaretPos) {
            caretPos = i + 1;
          }
        }
        else {
          newValue += placeholder[i];
        }

        if (strippedValue[j] == undefined) {
          if ((oneX.AutoFormat.placeholderChar.indexOf(placeholder[i+1]) < 0) && !isDeletingChar && placeholder[i+1] != undefined) {
            newValue += placeholder[i+1];

            if (caretPos > i) {
              caretPos = caretPos + 1;
            }
          }
          break;
        }
      }

      $input.val(newValue);

      if (placeholder[caretPos]!= oneX.AutoFormat.placeholderChar && !isDeletingChar) {
        caretPos = caretPos + 1;
      }

      if (!$input.data("1x-manualSetValue")) {
        oneX.AutoFormat.setCaretPosition(_this, caretPos);
      }

      $input.data("1x-manualSetValue", false);


      //update mask
      $input.siblings(".-oneX-formatWrapper").html( "<i>" + newValue + "</i>" + placeholder.substr(newValue.length));

      oneX.AutoFormat.triggerInput($input);
    },

    formatCurrency : function(event) {
      const $input = oneX.$(event.target),
        inputValue = $input.val().replace(/[^0-9\.]/g, ""),
        decimals = $input.data('no-decimals') ? 0 : 2;

      if ($input.data('formatter') === 'currency') {

        if (inputValue.length > 0) {
          $input.removeAttr('placeholder');
          $input.val(oneX.AutoFormat.adjustForEmptyValue($input));
          if ($input.val() !== ""){
            $input.val('$' + parseFloat(inputValue).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
          }
        }
        else {
          $input.val("");
        }
      }
    },

    adjustForEmptyValue : function($input){

      const val=$input.val();
      //looks for leading placeholders only in value to determine if the field is really empty.
      //currency and phone formatter have leading placeholders.
      return (($input.data('formatter') === 'currency' && val === "$." )|| ($input.data('formatter') === 'phone' && val === "("))?"":val
    },

    placeholderChar : "_",

    isAndroid : false,

    isSpanish : false,

    keySelected : "",

    changeFired: false,

    valueChanged: false,

    formatterMap : {
      "zip" : {
        placeholder : "_____",
        pattern : "[0-9]{5}",
        title : ["Enter 5 digits", "Ingresa un código postal de 5 dígitos"]
      },
      "ssn" : {
        placeholder : "___-__-____",
        pattern : "[0-9]{3}-[0-9]{2}-[0-9]{4}",
        title : ["Enter 9 digits", "Ingresa un número de seguro social de 9 dígitos"]
      },
      "phone" : {
        placeholder : "___-___-____",
        pattern : "[0-9]{3}-[0-9]{3}-[0-9]{4}",
        title : ["Enter 10 digits", "Ingresa un número de teléfono de 10 dígitos"]
      },
      "date" : {
        placeholder : "__-__-____",
        pattern : "[0-9]{2}-[0-9]{2}-[0-9]{4}",
        title : ["MM-DD-YYYY", "MM-DD-AAAA"]
      },
      "currency" : {
        placeholder : "$",
        pattern : "^[0-9]+(\.|\,)[0-9]{2}$",
        title : ["Enter numbers only", "Ingresa números solamente"]
      },
      
      "planAccountNumber" : {
        placeholder : "____-____-__",
        pattern : "[0-9]{4}-[0-9]{4}-[0-9]{2}",
        title : ["Enter numbers only", "Ingresa números solamente"]
      },
      "keycode" : {
        placeholder : "__________",
        pattern : "[0-9]{10}",
        title : ["Enter 10 digits", "Ingresa 10 dígitos"]
      },
      "custom" : {
        placeholder : "",
        pattern : "",
        title : ["", ""]
      },
      "time" : {
        placeholder : "__:__ __",
        pattern : "(1[012]|0?[1-9]):[0-5][0-9]\\s[AP][M]",
        title :["HH:MM AM/PM", "HH:MM AM/PM"]
      },
      "number" : {
        placeholder : "",
        pattern : "",
        title : ["Enter numbers only", "Ingresa números solamente"]
      },
      "canadianpostalcode" : {
        placeholder : "___ ___",
        pattern : "[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV]\\s\\d[ABCEGHJ-NPRSTV]\\d",
        title : ["Enter Canadian Postal Code", "Ingresa el código postal de Canadá"]
      },
      "vin" : {
        placeholder : "_________________",
        pattern : "[A-HJ-NPR-Z0-9]{17}",
        title : ["Enter VIN", "Ingresa VIN"]
      }
    }
  };

  oneX.Config.queues(_COMPONENT);

})();
