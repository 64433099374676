(function() {
  const _COMPONENT = {
    name: 'Icons',
    selectors: '.-oneX-icon[data-icon]'
  };

  oneX.Icons = {
    init: function () {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function () {
        _self.addElement(oneX.$(this));
      });
      return this;
    },

    addElement: function(_element) {
      if (_element.attr("data-initialized") !== "true") {
        _element.attr("data-initialized", true);
        oneX.Icons.retrieveIcon(_element, oneX.Icons.getSVGLocation(`${_element.attr('data-icon')}.svg`));
      }
    },

    removeElement: function() {
    },

    getSVGLocation: function(svgName) {
      let path = `${oneX.Util.environment.getStaticHostName()}/en_US/dxl-1x/prod/css/images/icons/${svgName}`;
      const segmentSection = oneX.$('script[src*="1x.core.js"]').attr('src');

      if (segmentSection !== undefined && segmentSection !== null) {
        if (segmentSection.indexOf('alpha') > -1) {
          path = `${oneX.Util.environment.getStaticHostName()}/en_US/dxl-1x/alpha/css/images/icons/${svgName}`;
        }

        if (segmentSection.indexOf('beta') > -1) {
          path = `${oneX.Util.environment.getStaticHostName()}/en_US/dxl-1x/beta/css/images/icons/${svgName}`;
        }

        if (segmentSection.indexOf('charlie') > -1) {
          path = `${oneX.Util.environment.getStaticHostName()}/en_US/dxl-1x/charlie/css/images/icons/${svgName}`;
        }

        const colon3000 = segmentSection.indexOf(':3000');
        if (colon3000 > -1) {
          path = `${segmentSection.substr(0, colon3000 + 5)}/css/images/icons/${svgName}`;
        }
      }

      return path;
    },

    retrieveIcon: function($currentIconContainer, iconPath) {
      let ajax = new XMLHttpRequest();

      ajax.open("GET", iconPath, true);
      ajax.onload = function(e) {
        if (ajax.status === 200) {
          let icon = ajax.responseText;
          oneX.Icons.injectSVG($currentIconContainer, icon);
        } else {
          console.error(`Icon: ${iconPath} Status: ${ajax.status} Message: ${ajax.statusText}`);
        }
      };
      ajax.send();
    },

    injectSVG: function($currentIconContainer, svg) {
      let currentHTML = $currentIconContainer.html();
      $currentIconContainer.html(svg + currentHTML);
    }
  };

  oneX.Config.queues(_COMPONENT);
})();
