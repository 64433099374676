/*  TODO: KIBO
 *
 */

(function () {
  const _COMPONENT = {
    name: 'Filter',
    selectors: '.-oneX-filter__container'
  };

  oneX.Filter = {
    init : function() {
      const _self = this;
      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        /*  bind check/uncheck | select/unselect event for checkboxes/radio buttons
         *  Assumptions: 
         *    -A filter will not use both radio buttons and checkboxes at once.
         *    -A filter will not use both no x and with x chips.
         */
        _self.inputBinding(_element);

        // bind Reset All
        const $resetLink = _element.find('[data-filter="reset"]');
        //_self.kiboResetSpacebar = new Kibo(resetLink);
        //_self.kiboResetSpacebar.down("space", _self.resetAll);
        $resetLink.on("click keydown", {filterContainer: _element}, _self.resetAll);

        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this;
      _element.find('input').off("input", _self.handleChip).off("Input", _self.removeChip);
      _element.find('[data-filter="reset"]').off("click keydown", _self.resetAll);
      _element.find('.-oneX-close-X-button').off("click keydown", _self.closeChipKeyPress);
      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },

    inputBinding: function(_element){
      const _self = this;
      const chipContainer = _element.find('.-oneX-filter__section');
      const filterCounter = _element.find('.-oneX-filter__header div');
      chipContainer.data("filterCnt", 0);
      
      // TODO: rename radioGroups to inputGroups and refactor accordingly
      let $inputGroups = _element.find('.-oneX-radio-group, .-oneX-checkbox-group');
      for(let inputGroup of $inputGroups) {
        oneX.$(inputGroup).find('input').on("input", {chipContainer: chipContainer, radioGroup: oneX.$(inputGroup).hasClass('-oneX-radio-group') ? inputGroup : null, filterCounter: filterCounter}, _self.handleChip);
      }
    },
    // TODO: insert the util function (need to pull down from master)
    resetAll : function(event) {
      if(event.which === 32 || event.which === 13 || event.type === "click") {
        event.data.filterContainer.find('.-oneX-radio-group').data("prev", "");
        const $inputs = event.data.filterContainer.find('input');
        for (let input of $inputs) {
          if(oneX.$(input).is(':checked')){
            input.checked = false;
            let eventToFire = new Event("input", {"bubbles":true, "cancelable":false});
            input.dispatchEvent(eventToFire);
          }
        }
      }
    },

    /*  
     *  Event Data: 
     *    chipContainer: class=-oneX-filter__section
     *    radioGroup: class=-oneX-radio-group
     *    filterCounter: div with text representing the number of applied filters
     */
    handleChip : function(event){
      const $targetInput = oneX.$(event.target);
      let filterCnt = event.data.chipContainer.data("filterCnt");
      if($targetInput.is(':checked')) {
        event.data.chipContainer.data("filterCnt", ++filterCnt);
        oneX.Filter.createChip(event.data.chipContainer, $targetInput, event.data.radioGroup);
        if(filterCnt === 1){
          const $horizontalRule = oneX.$("<hr></hr>").addClass("-oneX-hr");
          event.data.chipContainer.after($horizontalRule);
        }
      } else {
        event.data.chipContainer.data("filterCnt", --filterCnt);
        if(filterCnt === 0){
          event.data.chipContainer.next('hr').remove();
        }
      }
      event.data.filterCounter.text(`Filters (${event.data.chipContainer.data("filterCnt")})`);
    },

    createChip : function(chipContainer, $targetInput, radioGroup){
      if(chipContainer.find(`[data-input-id=${$targetInput.attr("id")}]`).length <= 0){
        const _self = this;
        const $chip=oneX.$(`
          <div class="-oneX-chip__container" data-input-id="${$targetInput.attr("id")}">
            <label class="-oneX-chip__label">
              ${$targetInput.val()}
            </label>
            ${chipContainer.data('close-filters') ? `
              <button class="-oneX-close-X-button" aria-label= "close ${$targetInput.val()} chip">
                <span class="-oneX-icon--close"></span>
              </button>
            `:``}
          </div>
        `);
        chipContainer.append($chip);
        $targetInput.on("input", {targetInput: $targetInput, chip: $chip}, _self.removeChip);
        if(chipContainer.data('close-filters')) {
          $chip.find("button").on("click keydown", {chip: $chip, radioGroup: radioGroup, targetInput: $targetInput}, _self.closeChipKeyPress);
        }

        /* The following simply checks if a radio button has been checked and does the following:
         *    If checked, the relevant radioGroup will update it's previous data with the id of the
         *    newly checked radio button input and will trigger the input event on the old input which
         *    will actiave the removeChip function.
         */
        if(radioGroup !== null){
          const $prevId = oneX.$(radioGroup).data("prev");
          if($prevId){
            oneX.$(radioGroup).find($prevId).trigger("input");
          }
          oneX.$(radioGroup).data("prev", `#${$targetInput.attr("id")}`);
        }
      }
    },

    removeChip: function(event) {
      const $closeBtn = event.data.chip.find('button');
      if(!event.data.targetInput.is(':checked')){
        if($closeBtn.length > 0) {
          $closeBtn.off("click keydown");
        }
        event.data.targetInput.off("input", oneX.Filter.removeChip);
        event.data.chip.remove();
      }
    },

    closeChipKeyPress: function(event) {
      if(event.which === 32 || event.type === "click"){
        if(event.data.radioGroup !== null) {
          oneX.$(event.data.radioGroup).data("prev", "");
        }
        oneX.$(event.target).off("keydown click", oneX.Filter.closeChip);
        event.data.chip.remove();
        event.data.targetInput.prop("checked", false);
        const eventToFire = new Event("input", {"bubbles":true, "cancelable":false});
        event.data.targetInput[0].dispatchEvent(eventToFire);
      }
    }

    //kiboResetSpacebar: null
  
  };

  oneX.Config.queues(_COMPONENT);
})();