(function() {
  const _COMPONENT = {
    name : 'StatusBar',
    selectors : '.-oneX-status-bar-container'
  };

  oneX.StatusBar = {
    init : function() {
      const _self = this;

      oneX.$(_COMPONENT.selectors).each(function() {
        _self.bindEvents(oneX.$(this));
      });

      return this;
    },

    addElement : function(_element) {
      
      this.bindEvents(_element);
    },

    removeElement : function(_element) {
      this.unbindEvents(_element);
    },

    bindEvents : function(_element) {
      const _self = this;
      
      if (_element.data(_COMPONENT.name) !== _COMPONENT.name) {
        const $statusBar = _element.not('.-oneX-back'),
          $statusLabel = $statusBar.find('div[class^="-oneX-status-label-"]>a');
                
        $statusBar.find('.-oneX-status-bar-completed').on('oneX-calc-status', _self.calculateCompleted).trigger('oneX-calc-status');
        $statusLabel.on('keydown click', _self.showDetailedInfo);
        _element.find('.-oneX-close-X-button').on('click', _self.closeDetailedInfo);
                        
        _element.data(_COMPONENT.name, _COMPONENT.name);
      }
    },

    unbindEvents : function(_element) {
      const _self = this,
        $statusBar = _element.not('.-oneX-back'),
        $statusLabel = $statusBar.find('div[class^="-oneX-status-label-"]>a');
      
      $statusBar.find('.-oneX-status-bar-completed').off('calc-status', _self.calculateCompleted);
      $statusLabel.off('keydown click', _self.showDetailedInfo);
      _element.find('.-oneX-close-X-button').off('click', _self.closeDetailedInfo);

      _element.removeData(_COMPONENT.name, _COMPONENT.name);
    },
    
    calculateCompleted : function () {
      const allStatusPoints = oneX.$(this).next('.-oneX-status-point-position').find('.-oneX-status-point'),
        currentStatusPoint = oneX.$(this).next('.-oneX-status-point-position').find('.-oneX-current-status-point'),
        width = ((allStatusPoints.index(currentStatusPoint) / (allStatusPoints.length - 1)) * 100) + '%';
      
      oneX.$(this).css('width', width);
    },
    
    showDetailedInfo : function (event) {
      const $statusLink = oneX.$(this),
        $generalStatus = $statusLink.closest('.-oneX-front'),
        $detailedStatus = oneX.$('[data-detail="' + $statusLink.attr('id') + '"]');
      
      if (event.type === 'keydown' && (event.which !== 32 && event.which !==13)) {
        return;
      }
      else {
        event.preventDefault();    
        $generalStatus.addClass('-oneX-back').removeClass('-oneX-front').css('transform', 'rotateX(-180deg)');
        $detailedStatus.addClass('-oneX-front').removeClass('-oneX-back').css('transform', 'rotateX(0deg)');
        
        setTimeout(function () {
          $detailedStatus.find('.-oneX-title').focus();
        }, 150);
      }
    },
    
    closeDetailedInfo : function () {
      const $detailedStatus = oneX.$(this).closest('.-oneX-front'),
        $generalStatus = $detailedStatus.prevAll('[data-detail="general"]');
      
      $generalStatus.addClass('-oneX-front').removeClass('-oneX-back').css('transform', ''); 
      $detailedStatus.addClass('-oneX-back').removeClass('-oneX-front').css('transform', ''); 
      
      setTimeout(function () {
        $generalStatus.find('div[class^="-oneX-status-label-"]>a').focus();
      }, 150); 

    }
  };

  oneX.Config.queues(_COMPONENT);
})();
